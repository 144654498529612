import {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";
//Material Ui
import { Box, Typography } from "@mui/material";
//ContextAPI
import {
  FormHandlerProvider,
  useAuth,
  useStepperContext,
} from "../../../../contextAPI";
//Services
import {
  createPartner,
  getFields,
  getPartnerById,
  getUserMe,
  startupTrackPage,
  updatePartner,
  uploadStartupFile,
} from "../../../../services";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//Helpers
import { SnackbarHolder, sortFieldsArray } from "../../../../helpers";
//components
import { Button, DropzoneAvatar, Form, Input } from "../../../../components";
import DisplayFormInputs from "../../../SharedPages/DisplayFormInputs";
//Styles
import { Container, Labeltext } from "./formCompany.styles";
//Types
import { ParamsProps } from "./formCompany.types";
import {LanguageContext} from "../../../../LanguageContext";

const exeptionFields = [
  "id",
  "status",
  "logo",
  "user_created",
  "user_updated",
  "date_updated",
  "date_created",
];

const FormCompany = () => {
  const auth = useAuth();
  const location = useLocation();
  const params: ParamsProps = useParams();
  const stepper = useStepperContext();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const { language } = useContext(LanguageContext);

  //states
  const [paramsFields, setParamsFields] = useState<string>("");
  const methods = useForm();
  const dataWatch = useWatch({ control: methods.control });

  //queries & mutations
  const partnerFields = useQuery(
    ["fields-partner", auth.token, params.id],
    () => {
      if (!auth.token) return;
      return getFields("partner", auth.token);
    },
    {
      onSuccess(fields) {
        let str = "";
        let arrayOfFiles = fields?.data?.data.filter(
          (field: any) => field.meta.interface === "file"
        );
        arrayOfFiles.forEach((element: any) => {
          if (!exeptionFields.includes(element.field)) {
            str = str + `,${element.field}.*`;
          }
        });
        setParamsFields(str);
      },
    }
  );

  const getUser = useQuery(["user", auth.token], () => {
    if (!auth.token) return;
    return getUserMe({ token: auth.token });
  });
  const dataPartner = useQuery(
    ["partner", params.id],
    () => {
      if (!params.id || !auth.token) {
        return;
      }
      if (paramsFields === "") return;
      return getPartnerById({
        id: params.id,
        moreFields: paramsFields,
        token: auth.token,
      });
    },
    {
      enabled: partnerFields.isFetched,
      onSuccess: (partner) => {
        if (!partner) return;
        handleDataToDisplay(partner.data.data);
      },
    }
  );
  const trackPage = useMutation(startupTrackPage);
  const companyCreation = useMutation(createPartner);
  const fileUploading = useMutation(uploadStartupFile);
  const companyUpdate = useMutation(updatePartner);

  //memos
  const user = useMemo(() => getUser?.data?.data?.data, [getUser]);
  const fields = useMemo(
    () => partnerFields?.data?.data?.data.sort(sortFieldsArray),
    [partnerFields]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deviceHeight = useMemo(() => _.clone(height), []);

  //useEffects
  useEffect(() => {
    dataPartner.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFields]);
  useEffect(() => {
    methods.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataWatch]);

  //functions
  const onFailSubmit = (data: any) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (key !== "undefined")
        SnackbarHolder.alert("error", language === "fr" ?"Des champs obligatoires non remplis":"Mandatory fields not filled in");
    });
  };
  const onSubmit = async (data: any) => {
    if (!auth.token) return;
    const dataToSend = await handleDataToSend(data);
    if (!params.id) {
      try {
        const response = await companyCreation.mutateAsync({
          data: dataToSend,
          token: auth.token,
        });
        if (response.status === 200) {
          try {
            const saveResponse = await trackPage.mutateAsync({
              data: {
                last_page: `/partner/validation/${response.data.data.id}`,
              },
              token: auth.token,
            });
            if (saveResponse.status === 204) {
              SnackbarHolder.alert("success", language === "fr" ? "Creation avec succès":"Creation successfully");
              stepper.handleComplete();
              navigate(`/partner/validation/${response.data.data.id}`, {
                replace: true,
              });
            }
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          SnackbarHolder.alert("error", response.data.errors[0].message);
          response.data.errors.forEach((error: any) => {
            methods.setError(
              error.extensions.field,
              {
                type: "custom",
                message: error.message,
              },
              { shouldFocus: true }
            );
          });
        }
      } catch (error: any) {
        SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
      }
    } else if (params.id) {
      try {
        const response = await companyUpdate.mutateAsync({
          id: params.id,
          data: dataToSend,
          token: auth.token,
        });
        if (response.status === 200) {
          try {
            const saveResponse = await trackPage.mutateAsync({
              data: {
                last_page: `/partner/validation/${response.data.data.id}`,
              },
              token: auth.token,
            });
            if (saveResponse.status === 204) {
              SnackbarHolder.alert("success", language === "fr" ? "Données mis à jour avec succés":"Data updated successfully");
              stepper.handleComplete();
              navigate(`/partner/validation/${response.data.data.id}`, {
                replace: true,
              });
            }
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          SnackbarHolder.alert("error", response.data.errors[0].message);
          response.data.errors.forEach((error: any) => {
            methods.setError(
              error.extensions.field,
              {
                type: "custom",
                message: error.message,
              },
              { shouldFocus: true }
            );
          });
        }
      } catch (error: any) {
        SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
      }
    }
  };

  const handleDraft = async () => {
    const dataToSend = await handleDataToSend(dataWatch);
    if (!auth.token) return;
    if (!params.id) {
      try {
        const response = await companyCreation.mutateAsync({
          data: dataToSend,
          token: auth.token,
        });
        if (response.status === 200 || response.status === 403) {
          try {
            const saveResponse = await trackPage.mutateAsync({
              data: {
                last_page: `/partner/edit-company/${response.data.data.id}`,
              },
              token: auth.token,
            });
            SnackbarHolder.alert("success", language === "fr" ? "Creation avec succès":"Creation successfully");
            if (saveResponse.status === 204) {
              window.history.replaceState(
                null,
                "",
                `/partner/edit-company/${response.data.data.id}`
              );
            }
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          SnackbarHolder.alert("error", response.data.errors[0].message);
          response.data.errors.forEach((error: any) => {
            methods.setError(
              error.extensions.field,
              {
                type: "custom",
                message: error.message,
              },
              { shouldFocus: true }
            );
          });
        }
      } catch (error: any) {
        SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
      }
    } else if (params.id) {
      try {
        const response = await companyUpdate.mutateAsync({
          id: params.id,
          data: dataToSend,
          token: auth.token,
        });
        if (response.status === 200) {
          try {
            await trackPage.mutateAsync({
              data: {
                last_page: `/partner/edit-company/${params.id}`,
              },
              token: auth.token,
            });
            SnackbarHolder.alert("success", language === "fr" ? "Données mis à jour avec succés":"Data updated successfully");
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          SnackbarHolder.alert("error", response.data.errors[0].message);
          response.data.errors.forEach((error: any) => {
            methods.setError(
              error.extensions.field,
              {
                type: "custom",
                message: error.message,
              },
              { shouldFocus: true }
            );
          });
        }
      } catch (error: any) {
        SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
      }
    }
  };

  const handleDataToDisplay = async (data: any) => {
    let initData = data;

    //display numbers
    for (const [key, value] of Object.entries(data)) {
      if (typeof value === "number") {
        initData[key] = value.toString();
      }
    }
    //display multiselect
    let arraySelectMultiple = partnerFields?.data?.data.data.filter(
      (field: any) =>
        field.meta.interface === "select-multiple-checkbox" ||
        field.meta.interface === "select-multiple-dropdown"
    );
    arraySelectMultiple.forEach((element: any) => {
      const arrayString = data[element.field];
      if (arrayString != null) {
        let arrayObject: any[] = [];
        arrayString.forEach((el: any) => {
          arrayObject.push({ text: el, value: el });
        });
        data[element.field] = arrayObject;
      }
    });

    //display repeater data
    let arrayRepeater = partnerFields?.data?.data.data.filter(
      (field: any) => field.meta.interface === "list-m2m"
    );
    for (const element of arrayRepeater) {
      const toUpdate = data[element.field];
      if (toUpdate.length === 1) {
        initData[element.field] = [];
      } else {
        const attribute = `${element.field}_id`;
        toUpdate.forEach((el: any) => {
          let arrayrep: any[] = [];
          let finalObj: any = {};
          if (el[attribute]) {
            for (var [key, value] of Object.entries(el[attribute])) {
              if (!exeptionFields.includes(key)) {
                finalObj[key] = value;
              }
            }
          }
          arrayrep.push(finalObj);
          initData[element.field] = arrayrep;
        });
      }
    }

    //diplay multi file
    let arrayOfMultiFiles = partnerFields?.data?.data.data.filter(
      (field: any) => field.meta.interface === "files"
    );
    arrayOfMultiFiles.forEach((element: any) => {
      let arrayFiles: any[] = [];
      if (data[element.field] != null) {
        data[element.field].forEach((el: any) => {
          arrayFiles.push({
            startup_id: el.startup_id,
            directus_files_id: el.directus_files_id.id,
            name: el.directus_files_id.title,
          });
        });
      }
      initData[element.field] = arrayFiles;
    });

    //display files
    let arrayOfFiles = partnerFields?.data?.data.data.filter(
      (field: any) => field.meta.interface === "file"
    );
    arrayOfFiles.forEach((element: any) => {
      let objectFile = {};
      if (data[element.field] != null) {
        if (!exeptionFields.includes(element.field)) {
          objectFile = {
            id: data[element.field].id,
            name: data[element.field].title,
            type: data[element.field].type,
          };
          initData[element.field] = objectFile;
        }
      }
    });

    //display booleans
    let arrayOfBoolean = partnerFields?.data?.data.data.filter(
      (field: any) => field.meta.interface === "boolean"
    );
    arrayOfBoolean.forEach((element: any) => {
      initData[element.field] = data[element.field] ? "true" : "false";
    });
    methods.reset(initData);
  };

  const handleDataToSend = async (data: any) => {
    if (!fields) return;
    let dataToSend = data;
    let arraySelectMultiple = fields.filter(
      (field: any) =>
        field.meta.interface === "select-multiple-checkbox" ||
        field.meta.interface === "select-multiple-dropdown"
    );
    let arrayOfFiles = fields.filter(
      (field: any) =>
        field.meta.interface === "file" || field.meta.interface === "file-image"
    );
    let arrayOfBoolean = fields.filter(
      (field: any) => field.meta.interface === "boolean"
    );

    for (const file of arrayOfFiles) {
      if (data[file.field] != null) {
        if (data[file.field].id) {
          dataToSend[file.field] = data[file.field];
        } else if (typeof data[file.field] === "object") {
          const res = await handleUploadFiles(data[file.field]);
          if (res?.status === 200) {
            dataToSend[file.field] = res.data.data.id;
          }
        }
      }
    }

    arraySelectMultiple.forEach((element: any) => {
      let arrayOfString: any[] = [];
      if (data[element.field] != null) {
        data[element.field].forEach((el: any) => {
          if (typeof el === "string") arrayOfString.push(el);
          else {
            arrayOfString.push(el.text);
          }
        });
      }
      dataToSend[element.field] = arrayOfString;
    });

    for (const booleanVar of arrayOfBoolean) {
      if (data[booleanVar.field] != null) {
        dataToSend[booleanVar.field] =
          data[booleanVar.field] === "true" ? true : false;
      }
    }

    let arrayOfNumbers = fields.filter(
      (field: any) =>
        field.meta.interface === "input" &&
        ["integer", "decimal", "bigInteger", "float"].includes(field.type)
    );

    arrayOfNumbers.forEach((element: any) => {
      if (data[element.field] === "") delete dataToSend[element.field];
    });

    delete dataToSend["status"];
    delete dataToSend["id"];
    delete dataToSend["user_created"];
    delete dataToSend["user_updated"];
    delete dataToSend["date_created"];
    delete dataToSend["date_updated"];

    return dataToSend;
  };

  const handleHideFields = (conditions: any, field: any) => {
    if (conditions == null) return true;

    if (conditions[0] != null) {
      if (dataWatch[conditions[0].name] != null) {
        if (
          dataWatch[conditions[0].name] !==
          conditions[0]?.rule._and[0][conditions[0].name]._eq
        ) {
          delete dataWatch[field];
          return false;
        } else if (
          dataWatch[conditions[0].name] ===
          conditions[0]?.rule._and[0][conditions[0].name]._eq
        ) {
          return true;
        }
      }
    }
  };
  const handleUploadFiles = async (file: any) => {
    if (!auth.token) return;
    try {
      return await fileUploading.mutateAsync({ file: file, token: auth.token });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  //renders
  if (auth.token && !params.id && getUser.isFetched && user.last_page) {
    const test = user.last_page.split('/')
    test?.forEach((str: string) => {
      if (["partner", "form-company", "edit-company", "validation"].includes(str)) {
        return (
          <Navigate to={user.last_page} state={{ from: location }} replace />
        );
      }
    });
  }

  if (auth.token && user.last_page && getUser.isFetched && !params.id) {
    const pathName = user.last_page.split("/");

    if (pathName.includes("validation") || pathName.includes("edit-company")) {
      return (
        <Navigate to={user.last_page} state={{ from: location }} replace />
      );
    }
  }

  const renderGroupItems = (partnerField: any) => {
    const group = partnerField.field;
    let arrayOfGroupInputs = fields.filter(
      (field: any) => field.meta.group === group
    );
    return (
      <>
        {partnerField.type === "alias" && (
          <>
            <Typography
              fontWeight={500}
              fontSize={16}
              color="#616161"
              fontFamily="Mont"
              pb={2}
            >
              {partnerField.field}
            </Typography>
            {arrayOfGroupInputs.map(
              (partnerField: any, index: number) =>
                !exeptionFields.includes(partnerField.field) && (
                  <Fragment key={index}>
                    {handleHideFields(
                      partnerField.meta.conditions,
                      partnerField.field
                    ) &&
                      partnerField.meta.group === group && (
                        <DisplayFormInputs field={partnerField} />
                      )}
                  </Fragment>
                )
            )}
          </>
        )}
      </>
    );
  };

  const renderOtherTag = (otherField: any) => {

    let label = otherField.meta.note
    if (language === "fr"){
      label= otherField.meta.note
    }else if (otherField.meta.note && otherField.meta?.translations){
      otherField.meta?.translations.map((trans:any)=>{
        if (trans['language']==='en-US'){
          label= trans.translation
        }
      })

    }

    let arraySelectMultiple = fields.filter(
      (field: any) =>
        field.meta.interface === "select-multiple-checkbox" ||
        field.meta.interface === "select-multiple-dropdown"
    );
    // eslint-disable-next-line array-callback-return
    return arraySelectMultiple.map((element: any) => {
      let arrayOfString: any[] = [];
      if (dataWatch[element.field] != null) {
        dataWatch[element.field].forEach((el: any) => {
          if (typeof el === "string") arrayOfString.push(el);
          else {
            arrayOfString.push(el.text);
          }
        });
      }
      if (arrayOfString.includes("Autre")) {
        return (
          <Input
            minWidth={360}
            {...methods.register(otherField.field, {
              maxLength: 25,
              minLength: 2,
              required:
                otherField.meta.required || otherField.meta.conditions != null
                  ? (language === "fr" ?"Ce champ est requis.":"This field is required.")
                  : false,
            })}
            label={
              <Box display="flex">
                <Labeltext color="rgba(43, 45, 50, 0.64)">
                  {label}
                </Labeltext>
                {otherField.meta.required && (
                  <Labeltext color="#EF4B12">*</Labeltext>
                )}
              </Box>
            }
          />
        );
      }
    });
  };

  return (
    <FormHandlerProvider
      loading={
        partnerFields.isLoading || getUser.isLoading || dataPartner.isLoading
      }
    >
      <FormProvider {...methods}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Container
              minHeight={width < 490 ? deviceHeight - 400 : height - 420}
              maxHeight={width < 490 ? deviceHeight - 400 : height - 420}
            >
              <Typography
                fontWeight={700}
                fontSize={16}
                color="#616161"
                fontFamily="Mont"
                pb={2}
              >
                {language === "fr" ? "Informations complémentaires" : "Additional information"}
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={1}
              >
                <DropzoneAvatar
                  name="logo"
                  width={width < 490 ? 70 : 100}
                  height={width < 490 ? 70 : 100}
                />
                <Box display="flex" flexDirection="column">
                  <Typography fontWeight={600} fontSize={14} color="#616161">
                    {language === "fr" ?"Ajoutez le logotype":"Add logo"}
                  </Typography>
                  <Typography fontWeight={400} fontSize={12} color="#616161">
                    {language === "fr" ?"Format recommandé PNG/JPEG 256*256":"Recommended format PNG/JPEG 256*256"}
                  </Typography>
                </Box>
              </Box>
              {fields &&
                fields.map(
                  (partnerField: any, index: number) =>
                    !exeptionFields.includes(partnerField.field) && (
                      <Fragment key={index}>
                        {handleHideFields(
                          partnerField.meta.conditions,
                          partnerField.field
                        ) &&
                          partnerField.field !== "industries_other" &&
                          partnerField.meta.group === null && (
                            <DisplayFormInputs field={partnerField} />
                          )}
                        {partnerField.type === "alias" &&
                          partnerField.field !== "industries_other" &&
                          handleHideFields(
                            partnerField.meta.conditions,
                            partnerField.field
                          ) &&
                          renderGroupItems(partnerField)}
                        {partnerField.field === "industries_other" &&
                          renderOtherTag(partnerField)}
                      </Fragment>
                    )
                )}
            </Container>
          </Form>
          <Box display="flex" justifyContent="flex-end" mt={2} width="90%">
            <Box display="flex" gap={1}>
              <Button
                variant="contained"
                boxshadow="0px 5px 5px rgba(172, 172, 172, 0.2)"
                width={width < 490 ? 110 : 190}
                loading={
                  companyUpdate.isLoading ||
                  fileUploading.isLoading ||
                  companyCreation.isLoading ||
                  trackPage.isLoading
                }
                onClick={handleDraft}
                sx={{ backgroundColor: "#E0E0E7", color: "#000000" }}
              >
                {language === "fr" ?"Sauvegarder":"Save"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
                width={width < 490 ? 110 : 190}
                loading={
                  companyUpdate.isLoading ||
                  fileUploading.isLoading ||
                  companyCreation.isLoading ||
                  trackPage.isLoading
                }
                onClick={methods.handleSubmit(onSubmit, onFailSubmit)}
              >
                {language === "fr" ?"Suivant":"Next"}
              </Button>
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </FormHandlerProvider>
  );
};

export default FormCompany;
