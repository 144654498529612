import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../../assets";
//Components
import { FooterGeneral, HeaderForm, Image } from "../../../components";
//Helpers
import { getPageNameFromPathName } from "../../../helpers";
import { useWindowDimensions } from "../../../hooks";
//Styles
import { Container, Content, InnerBox } from "./pageNotFound.styles";
//consts
const pathNames = ["sign-in", "forget-password", "reset-password"];

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = getPageNameFromPathName(location);
  const { height, width } = useWindowDimensions();

  //memos
  const buttonText = useMemo(
    () => (!pathNames.includes(pathName[0]) ? "Connexion" : "S’inscrire"),
    [pathName]
  );
  const text = useMemo(
    () => (!pathNames.includes(pathName[0]) ? "Déja membre" : "Nouveau Membre"),
    [pathName]
  );
  const fontSize404 = useMemo(() => (width < 490 ? 30 : 80), [width]);
  const fontSizeText = useMemo(() => (width < 490 ? 20 : 30), [width]);
  const imageWidth = useMemo(() => (width < 490 ? 140 : 270), [width]);
  const imageHeight = useMemo(() => (width < 490 ? 125 : 240), [width]);
  //functions
  const handleSignIn = () => {
    if (!pathNames.includes(pathName[0])) {
      navigate("/sign-in", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };
  return (
    <Container>
      <HeaderForm
        buttonText={buttonText}
        text={text}
        buttonAction={handleSignIn}
      />
      <InnerBox>
        <Content minHeight={height - 240}>
          <Box pl={6}>
            <Image
              src={Images.NotFound}
              height={imageHeight}
              width={imageWidth}
            />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" pr={3}>
            <Typography
              fontFamily="Mont"
              fontSize={fontSize404}
              fontWeight={600}
              color="primary"
            >
              404
            </Typography>
            <Typography
              fontFamily="Mont"
              fontSize={fontSizeText}
              color="#999999"
            >
              Oops !
            </Typography>
            <Typography
              fontFamily="Mont"
              fontSize={fontSizeText}
              color="#999999"
            >
              Page introuvable
            </Typography>
          </Box>
        </Content>
      </InnerBox>
      <FooterGeneral />
    </Container>
  );
};
export default NotFound;
