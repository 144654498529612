import { Directus } from "@directus/sdk";

const directus = new Directus(`${process.env.REACT_APP_DIRECTUS_URL}`, {
  auth: {
    staticToken: sessionStorage.getItem("token") || undefined,
  },
});

const createStartupDirectus = async (params: {
  collection: string;
  data: any;
}) => {
  const create = await directus.items(params.collection).createOne(params.data);
  return create;
};
const loginDirectus = async (params: { data: any }) => {
  const login = await directus.auth.login(params.data);
  return login;
};
export { createStartupDirectus, loginDirectus };
