import axios from "axios";

const createUser = async (data: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DIRECTUS_URL}/users`,
      data
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};
const getUserMe = async (params: { token: string }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_DIRECTUS_URL}/users/me`,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};
const updateUserMe = async (params: { data: any; token: string }) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_DIRECTUS_URL}/users/me`,
      params.data,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

const signIn = async (data: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DIRECTUS_URL}/auth/login`,
      data
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

const signOut = async (data: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DIRECTUS_URL}/auth/logout`,
      data
    );
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};

const authRefreshToken = async (data: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DIRECTUS_URL}/auth/refresh`,
      data
    );
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};

const forgetPassword = async (data: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DIRECTUS_URL}/auth/password/request?reset_url=https://application.fuze.digital-africa.co/reset-password`,
      data
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

const resetPassword = async (data: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DIRECTUS_URL}/auth/password/reset`,
      data
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};
const getNotifications = async (params: { token: string }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/notification?meta=total_count&sort=-date_created`,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export {
  createUser,
  getUserMe,
  updateUserMe,
  signIn,
  signOut,
  authRefreshToken,
  forgetPassword,
  resetPassword,
  getNotifications,
};
