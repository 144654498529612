import {useContext, useMemo} from "react";
import { useNavigate } from "react-router-dom";
//Material Ui
import { Box, Typography } from "@mui/material";
//Asstes
import { Svg } from "../../../../assets";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//Components
import { CardChoice } from "../../../../components";
//Styles
import { Container, ContainerCondition } from "./choiceSelect.styles";
import {LanguageContext} from "../../../../LanguageContext";

const ChoiceSelect = () => {
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const { language } = useContext(LanguageContext);

  //functons
  const handleClickCard = () => {
    navigate("/confirm-choice", { replace: true });
  };
  //momes
  const boxDirection = useMemo(() => (width < 490 ? "column" : "row"), [width]);
  //renders
  const renderItem = () => {
    return (
      <CardChoice
        description={language==="fr"?"Je teste mon éligibilité et je dépose ma candidature":"I test my eligibility and submit my application"}

        icon={<Svg.ToForm />}
        onCLick={() => handleClickCard()}
      />
    );
  };
  return (
    <Container
      maxHeight={height - 550}
      minHeight={height - 290}
      overflow="auto"
    >
      <Typography
        fontSize={width < 700 ? 16 : 18}
        fontFamily="Mont"
        fontWeight={500}
        color="#999999"
      >
        {language==="fr"?"Plusieurs critères sont pris en compte pour évaluer l'éligibilité des candidats.":"Several criteria are taken into account to assess the eligibility of candidates."}

      </Typography>
      <ContainerCondition minHeight="auto">
       {/* <Box display="flex" gap={1}>
          <Box>
            <Svg.StartupAge />
          </Box>
          <Typography
            fontSize={height < 700 ? 12 : 14}
            fontWeight={500}
            color="#616161"
            fontFamily="Mont"
          >
            {language==="fr" ? "La Société a moins de 18 mois d'existence ou moins de 36 mois si elle génère des revenus ou si elle a levé des fonds." :"" +
                "The Company is less than 18 months old or less than 36 months old if it generates revenue or has raised funds."}

          </Typography>
        </Box>*/}
        <Box display="flex" gap={1}>
          <Box>
            <Svg.StartupLocation />
          </Box>
          <Typography
            fontSize={height < 700 ? 12 : 14}
            fontWeight={500}
            color="#616161"
            fontFamily="Mont"
          >
            {language==="fr" ? "Sociétés fondées ou co-fondées par au moins un ressortissant d'un pays africain.": "Companies founded or co-founded by at least one citizen of an African country."}

          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Box>
            <Svg.StartupTech />
          </Box>
          <Typography
            fontSize={height < 700 ? 12 : 14}
            fontWeight={500}
            color="#616161"
            fontFamily="Mont"
          >
            {language==="fr" ? "La présence d’une composante Tech dans le produit/modèle économique de la startup.": "The presence of a Tech component in the startup's product/business model."}
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Box>
            <Svg.StartupLanguage />
          </Box>
          <Typography
            fontSize={height < 700 ? 12 : 14}
            fontWeight={500}
            color="#616161"
            fontFamily="Mont"
          >
            {language==="fr" ? "La startup a des opérations (coût ou profit) dans un pays d’Afrique.": "The startup has operations (cost or profit) in an African country."}

          </Typography>
        </Box>
      </ContainerCondition>
      <Box display="flex" flexDirection={boxDirection} gap={4}>
        {renderItem()}
      </Box>
    </Container>
  );
};

export default ChoiceSelect;
