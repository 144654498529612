//Material Ui
import { Box, Dialog, DialogContent } from "@mui/material";
//Components
import { Button, Image } from "../../Commons";
import { ButtonText } from "./modalConfirmation.styles";
//Types
import { ModalConfirmationProps } from "./modalConfirmation.types";

const ModalConfirmation = (props: ModalConfirmationProps) => {
  const {
    isVisible,
    image,
    leftButtonText,
    rightButtonText,
    children,
    onClickClose,
    leftButtonAction,
    rightButtonAction,
  } = props;
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isVisible || false}
      onClose={onClickClose}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt="24px"
          mb="24px"
        >
          <Box
            width="90%"
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <Image src={image} width={100} />
            {children}
            <Box
              display="flex"
              gap={2}
              justifyContent="center"
              alignItems="center"
            >
              {leftButtonText && (
                <Button variant="text" onClick={leftButtonAction}>
                  <ButtonText>{leftButtonText}</ButtonText>
                </Button>
              )}
              {rightButtonText && (
                <Button
                  width={140}
                  variant="contained"
                  color="primary"
                  boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
                  onClick={rightButtonAction}
                >
                  {rightButtonText}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export { ModalConfirmation };
