import axios from "axios";

const createFounder = async (params: { data: any; token: string }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/founder`,
      params.data,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export { createFounder };
