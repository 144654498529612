//Material Ui
import { Typography, Box } from "@mui/material";
//Assets
import { Svg } from "../../../assets";
import { useWindowDimensions } from "../../../hooks";
//Styles
import { BottomBtn, Container } from "./cardChoice.styles";
//types
import { ChoiceCardType } from "./cardChoice.types";

const CardChoice = (props: ChoiceCardType) => {
  const { icon, description, onCLick } = props;
  const { width } = useWindowDimensions();
  return (
    <Container onClick={onCLick}>
      <Box minWidth={width < 1060 ? 50 : 80}>{icon}</Box>
      <Typography
        align={width < 1060 ? "center" : "left"}
        color="#616161"
        fontSize={14}
        fontWeight={400}
        maxWidth={200}
        fontFamily="Mont"
      >
        {description}
      </Typography>
      <BottomBtn>
        <Svg.ArrowRightBotton />
      </BottomBtn>
    </Container>
  );
};

export { CardChoice };
