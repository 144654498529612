import { SnackbarOrigin } from "@mui/material";

type AlertType = "info" | "warning" | "error" | "success";

export type Snackbar = {
  alert: (
    type: AlertType,
    message: string,
    anchorOrigin?: SnackbarOrigin
  ) => void;
};

export class SnackbarHolder {
  static snackbar: Snackbar;

  static setSnackbar(snackbar: Snackbar | null) {
    if (!snackbar) {
      return;
    }
    this.snackbar = snackbar;
  }

  static alert(
    type: AlertType,
    message: string,
    anchorOrigin?: SnackbarOrigin
  ) {
    this.snackbar.alert(type, message, anchorOrigin);
  }
}
