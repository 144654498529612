import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    primary: {
      main: "#EF4B12",
      light: "#ef8a65",
      dark: "#a54c2c",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#F9BC4A",
      light: "#fac96e",
      dark: "#ae8333",
      contrastText: "#70193D",
    },
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "separate",
          borderSpacing: "0 8px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
          backgroundColor: "#fff",
          padding: "0 16px",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        outlined: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "th:first-of-type": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          },
          "th:last-child": {
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {},
    },
  },
  typography: {
    fontFamily: [
      "DM Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Mont"',
    ].join(","),
    fontSize: 16,
  },
});
