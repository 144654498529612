function StartupAge() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      fill="none"
      viewBox="0 0 31 30"
    >
      <path
        fill="#EC6D3F"
        d="M8.41 23.438h-3.2c-2.31 0-3.64-1.325-3.64-3.625V5.188c0-2.3 1.33-3.625 3.64-3.625h5.397c2.31 0 3.64 1.325 3.64 3.625V7.5a.946.946 0 01-.941.938.946.946 0 01-.942-.938V5.188c0-1.275-.477-1.75-1.757-1.75H5.209c-1.28 0-1.757.475-1.757 1.75v14.625c0 1.274.477 1.75 1.757 1.75H8.41c.515 0 .942.425.942.937a.946.946 0 01-.942.938z"
      ></path>
      <path
        fill="#EC6D3F"
        d="M18.779 28.438h-7.331c-2.523 0-3.98-1.45-3.98-3.963v-13.95c0-2.513 1.457-3.963 3.98-3.963h7.33c2.524 0 3.967 1.45 3.967 3.963v13.95c0 2.512-1.444 3.962-3.966 3.962zm-7.331-20c-1.506 0-2.096.587-2.096 2.087v13.95c0 1.5.59 2.087 2.096 2.087h7.33c1.494 0 2.084-.587 2.084-2.087v-13.95c0-1.5-.59-2.088-2.083-2.088h-7.331z"
      ></path>
      <path
        fill="#EC6D3F"
        d="M24.916 23.438h-3.113a.946.946 0 01-.941-.938c0-.512.427-.938.941-.938h3.113c1.28 0 1.758-.475 1.758-1.75V5.188c0-1.275-.477-1.75-1.758-1.75H19.52c-1.28 0-1.758.475-1.758 1.75V7.5a.946.946 0 01-.941.938.946.946 0 01-.941-.938V5.188c0-2.3 1.33-3.625 3.64-3.625h5.397c2.31 0 3.64 1.325 3.64 3.625v14.625c0 2.3-1.33 3.625-3.64 3.625z"
      ></path>
      <path
        fill="#EC6D3F"
        d="M17.573 14.688h-5.02a.946.946 0 01-.942-.938c0-.512.427-.938.941-.938h5.021c.515 0 .942.426.942.938a.946.946 0 01-.942.938zM17.573 18.438h-5.02a.946.946 0 01-.942-.938c0-.512.427-.938.941-.938h5.021c.515 0 .942.425.942.938a.946.946 0 01-.942.938zM15.063 28.438a.946.946 0 01-.942-.938v-3.75c0-.512.427-.938.942-.938.514 0 .941.425.941.938v3.75a.946.946 0 01-.941.938z"
      ></path>
    </svg>
  );
}

export default StartupAge;
