import React from "react";
import Snack from "@mui/material/Snackbar";
import { SnackbarOrigin } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
//Types
import { AlertType, SnackbarProps, SnackbarState } from "./snackbar.types";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Snackbar extends React.Component<SnackbarProps, SnackbarState> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
      message: "",
      type: "success",
      anchorOrigin: null,
    };
  }

  hide = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false, anchorOrigin: null });
  };

  alert = async (
    type: AlertType,
    message: string,
    anchorOrigin?: SnackbarOrigin
  ) => {
    this.setState({
      open: true,
      type,
      message,
      anchorOrigin: anchorOrigin || null,
    });
  };

  render() {
    return (
      <Snack
        open={this.state.open}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        autoHideDuration={4000}
        onClose={this.hide}
      >
        <Alert
          onClose={this.hide}
          severity={this.state.type}
          sx={{ width: "100%" }}
        >
          {this.state.message}
        </Alert>
      </Snack>
    );
  }
}

export { Snackbar };
