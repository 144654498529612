function Remove() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        stroke="#616161"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.5 16c4.125 0 7.5-3.375 7.5-7.5S12.625 1 8.5 1 1 4.375 1 8.5 4.375 16 8.5 16zM6.378 10.623l4.245-4.245M10.623 10.623L6.378 6.378"
      ></path>
    </svg>
  );
}

export default Remove;
