import { FC } from "react";
//Material
import Box from "@mui/material/Box";
import { CustomButton } from "./button.styles";
//Types
import { ButtonProps } from "./button.types";

const Button: FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;
  //render

  return (
    <CustomButton
      {...rest}
      disabled={props.loading}
      startIcon={props.startIcon || <Box />}
    >
      {children}
    </CustomButton>
  );
};

export { Button };
