import { Box, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
//Components
import { Button, DropzoneFile, Form } from "../../../../../components";
//Styles
import { SectionTitle } from "./dueDiligience.styles";

const DueDiligence = () => {
  const methods = useForm({
    defaultValues: {
      orgranigrammeJuridique: null,
      statusDesSocietes: null,
      certificatDeSociete: null,
      listeDesMembresDesOrganesDeDirection: null,
      comptesActionnaires: null,
      tableCapitalisation: null,
      comptesConsolides: null,
      compteBancaire: null,
      dettesBancaires: null,
      detailsProvisions: null,
      tableauRecapitulatif: null,
      elementsbudget: null,
      dernierBusinessPlanDisponible: null,
      listesDescriptifs: null,
      correspondancesConclusionsRelatives: null,
      clients: null,
      partenairesFournisseurs: null,
      autresContrats: null,
      BauxAvenantsAnnexes: null,
      accesPlateforme: null,
      feuilleRouteProduit: null,
      photoGalerieProduit: null,
      EnregistrementRrenouvellementMarquesBrevets: null,
      ListeAccordsLicence: null,
      FichesPaie: null,
      CNIfondateurs: null,
      PreuveResidence: null,
      OrganigrammeDirectionEmployesActuelsContractantsActuelsProfessionnelsExternalises:
        null,
      ContratsConditionsAssurance: null,
      ConditionsAssuranceDommagesPercus: null,
      ListeNnomsDomainesSitesInternet: null,
      DescriptionSystemesInformationInternes: null,
      AccordsLicenseLogiciels: null,
      PlaquettesCommerciales: null,
      DonneesMarche: null,
    },
  });

  const onSubmit = async (data: any) => {};
  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography
            fontFamily="Mont"
            fontSize={20}
            fontWeight={600}
            color="#EF4B12"
          >
            Due diligiance Documents
          </Typography>
          <SectionTitle>Documents corporate</SectionTitle>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            maxWidth={700}
            gap={2}
          >
            <DropzoneFile
              height={150}
              width={200}
              message="Organigramme juridique"
              {...methods.register("orgranigrammeJuridique", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message="Status des sociétes"
              {...methods.register("statusDesSocietes", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Extrait "K bis" à jour de toutes les sociétés / certificat de société`}
              {...methods.register("certificatDeSociete", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Liste des membres des organes de direction`}
              {...methods.register("listeDesMembresDesOrganesDeDirection", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Comptes d'actionnaires`}
              {...methods.register("comptesActionnaires", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Table de capitalisation `}
              {...methods.register("tableCapitalisation", {
                required: false,
              })}
            />
          </Box>
          <SectionTitle>Etats financiers</SectionTitle>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            maxWidth={700}
            gap={2}
          >
            <DropzoneFile
              height={150}
              width={200}
              message={`Comptes consolidés 2021,2022`}
              {...methods.register("comptesConsolides", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Compte Bancaire`}
              {...methods.register("compteBancaire", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Dettes bancaires - états et contrats avec les organismes bancaires `}
              {...methods.register("dettesBancaires", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Détails des provisions à la dernière date disponible `}
              {...methods.register("detailsProvisions", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Tableau récapitulatif des créances douteuses 2021-2022`}
              {...methods.register("tableauRecapitulatif", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Eléments du budget`}
              {...methods.register("elementsbudget", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Dernier Business Plan disponible `}
              {...methods.register("dernierBusinessPlanDisponible", {
                required: false,
              })}
            />
          </Box>
          <SectionTitle>Contentieux significatifs</SectionTitle>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            maxWidth={700}
            gap={2}
          >
            <DropzoneFile
              height={150}
              width={200}
              message={`Liste et descriptifs des litiges en cours ou dans le passé  (commerciaux, prud'hommaux, civil) `}
              {...methods.register("listesDescriptifs", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Correspondances et conclusions relatives aux litiges`}
              {...methods.register("correspondancesConclusionsRelatives", {
                required: false,
              })}
            />
          </Box>
          <SectionTitle>Relations commerciales</SectionTitle>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            maxWidth={700}
            gap={2}
          >
            <DropzoneFile
              height={150}
              width={200}
              message={`Clients`}
              {...methods.register("clients", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Partenaires et Fournisseurs `}
              {...methods.register("partenairesFournisseurs", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Autres contrats `}
              {...methods.register("autresContrats", {
                required: false,
              })}
            />
          </Box>
          <SectionTitle>Biens immobiliers et mobiliers</SectionTitle>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            maxWidth={700}
            gap={2}
          >
            <DropzoneFile
              height={150}
              width={200}
              message={`Baux, avenants et annexes `}
              {...methods.register("BauxAvenantsAnnexes", {
                required: false,
              })}
            />
          </Box>
          <SectionTitle>Produit</SectionTitle>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            maxWidth={700}
            gap={2}
          >
            <DropzoneFile
              height={150}
              width={200}
              message={`Accès à la plateforme`}
              {...methods.register("accesPlateforme", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Feuille de route du produit`}
              {...methods.register("feuilleRouteProduit", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Photo galerie de produit`}
              {...methods.register("photoGalerieProduit", {
                required: false,
              })}
            />
          </Box>
          <SectionTitle>Droits de propriete intellectuelle</SectionTitle>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            maxWidth={700}
            gap={2}
          >
            <DropzoneFile
              height={150}
              width={200}
              message={`Enregistrement et renouvellement des marques et brevets `}
              {...methods.register(
                "EnregistrementRrenouvellementMarquesBrevets",
                {
                  required: false,
                }
              )}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Liste des accords de licence`}
              {...methods.register("ListeAccordsLicence", {
                required: false,
              })}
            />
          </Box>
          <SectionTitle>Ressources humaines</SectionTitle>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            maxWidth={700}
            gap={2}
          >
            <DropzoneFile
              height={150}
              width={200}
              message={`Fiches de paie `}
              {...methods.register("FichesPaie", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`CNI fondateurs`}
              {...methods.register("CNIfondateurs", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Preuve de résidence`}
              {...methods.register("PreuveResidence", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Organigramme - Direction, employés actuels, contractants actuels, professionnels externalisés`}
              {...methods.register(
                "OrganigrammeDirectionEmployesActuelsContractantsActuelsProfessionnelsExternalises",
                {
                  required: false,
                }
              )}
            />
          </Box>
          <SectionTitle>Assurances</SectionTitle>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            maxWidth={700}
            gap={2}
          >
            <DropzoneFile
              height={150}
              width={200}
              message={`Contrats et conditions d'assurance `}
              {...methods.register("ContratsConditionsAssurance", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Conditions d'assurance et dommages perçus`}
              {...methods.register("ConditionsAssuranceDommagesPercus", {
                required: false,
              })}
            />
          </Box>
          <SectionTitle>IT</SectionTitle>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            maxWidth={700}
            gap={2}
          >
            <DropzoneFile
              height={150}
              width={200}
              message={`Liste des noms de domaines et sites Internet `}
              {...methods.register("ListeNnomsDomainesSitesInternet", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Description des systèmes d'information internes`}
              {...methods.register("DescriptionSystemesInformationInternes", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Accords de license de logiciels`}
              {...methods.register("AccordsLicenseLogiciels", {
                required: false,
              })}
            />
          </Box>
          <SectionTitle>Autres informations sur la societe</SectionTitle>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            maxWidth={700}
            gap={2}
          >
            <DropzoneFile
              height={150}
              width={200}
              message={`Plaquettes commerciales `}
              {...methods.register("PlaquettesCommerciales", {
                required: false,
              })}
            />
            <DropzoneFile
              height={150}
              width={200}
              message={`Données de marché `}
              {...methods.register("DonneesMarche", {
                required: false,
              })}
            />
          </Box>
          <Box minWidth="100%" display="flex" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              color="primary"
              boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
              width={190}
              onClick={methods.handleSubmit(onSubmit)}
            >
              Validation
            </Button>
          </Box>
        </Box>
      </Form>
    </FormProvider>
  );
};

export default DueDiligence;
