import {useContext, useMemo, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
//Material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { lighten } from "@mui/material";
//ContextAPI
import { useAuth } from "../../../../contextAPI";
//Hookes
import { useWindowDimensions } from "../../../../hooks";
//Services
import { getStartupById, getUserMe } from "../../../../services";
//Components
import { Button, ModalConfirmation } from "../../../../components";
import DueDiligence from "./DueDiligence";
//Styles
import { Container, ContainerCondition } from "./startupNoDraft.styles";
//Types
import { ParamsProps } from "./startupNoDraft.types";
import {LanguageContext} from "../../../../LanguageContext";

//const
const permissionsArray = [
  "draft",
  "En attente de l'approbation du partenaire",
  "En attente de l'examen par l'analyste",
  "Notation du partenaire en attente",
];

const NoDraft = () => {
  const auth = useAuth();
  const params: ParamsProps = useParams();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const { language } = useContext(LanguageContext);

  //states
  const [open, setOpen] = useState<boolean>(false);

  //queries and mutations
  const dataStartup = useQuery(
    ["startup-edit", params.id],
    () => {
      if (!params.id || !auth.token) return;
      return getStartupById({
        id: params.id,
        token: auth.token,
      });
    },
    {
      enabled: auth.token ? true : false,
    }
  );
  const getUser = useQuery(["user", auth.token, auth.token], () => {
    if (!auth.token) return;
    return getUserMe({ token: auth.token });
  });

  //memos
  const startup = useMemo(() => dataStartup?.data?.data?.data, [dataStartup]);
  const user = useMemo(() => getUser?.data?.data?.data, [getUser]);

  //functons
  const handleCheckApplay = () => {
    if (getUser.isFetched && user.last_page) {
      navigate(user.last_page, { replace: true });
    } else {
      navigate(`/validation/${params.id}`, { replace: true });
    }
  };
  const handleDueDiligence = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //renders
  if (dataStartup.isLoading || !dataStartup.isFetched || !startup) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Container
      maxHeight={height - 550}
      minHeight={height - 290}
      overflow="auto"
    >
      <Typography
        fontSize={width < 700 ? 16 : 18}
        fontFamily="Mont"
        fontWeight={500}
        color="#999999"
      >
        {language==="fr"? "L'état d'avancement de votre candidature": "The progress of your application"}

      </Typography>
      <ContainerCondition minHeight="auto">
        <Box display="flex" gap={1}>
          <Box p={1} minWidth={100}>
            <Typography
              fontSize={height < 700 ? 12 : 14}
              fontWeight={500}
              color="#616161"
              fontFamily="Mont"
            >
              {language==="fr"? "Statut:": "Status:"}
            </Typography>
          </Box>
          <Box p={1} bgcolor={lighten("#EC6D3F", 0.8)} borderRadius="10px">
            <Typography
              fontSize={height < 700 ? 12 : 14}
              fontWeight={400}
              color="#EC6D3F"
              fontFamily="Mont"
            >
              {startup.status}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" gap={1}>
          <Box p={1} minWidth={100}>
            <Typography
              fontSize={height < 700 ? 12 : 14}
              fontWeight={500}
              color="#616161"
              fontFamily="Mont"
            >
              Type:
            </Typography>
          </Box>
          <Box p={1}>
            <Typography
              fontSize={height < 700 ? 12 : 14}
              fontWeight={400}
              color="#999999"
              fontFamily="Mont"
            >
              Startup
            </Typography>
          </Box>
        </Box>
        <Box display="flex" gap={1}>
          <Box p={1} minWidth={100}>
            <Typography
              fontSize={height < 700 ? 12 : 14}
              fontWeight={500}
              color="#616161"
              fontFamily="Mont"
            >
              {language==="fr"? "Programme:": "Program:"}

            </Typography>
          </Box>
          <Box p={1}>
            <Typography
              fontSize={height < 700 ? 12 : 14}
              fontWeight={400}
              color="#999999"
              fontFamily="Mont"
            >
              Fuzé
            </Typography>
          </Box>
        </Box>
      </ContainerCondition>
      {permissionsArray.includes(startup.status) && (
        <Button
          variant="contained"
          color="primary"
          boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
          width={190}
          onClick={handleCheckApplay}
        >
          {language==="fr"? "Voir ma candidature": "See my application"}

        </Button>
      )}

      {/* {permissionsArray.includes(startup.status) && (
        <Button
          variant="contained"
          color="primary"
          boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
          width={190}
          onClick={handleDueDiligence}
        >
          Due Diligence
        </Button>
      )} */}

      <ModalConfirmation
        isVisible={open}
        children={<DueDiligence />}
        onClickClose={handleClose}
      />
    </Container>
  );
};

export default NoDraft;
