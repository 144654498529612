import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import theme from "../../../../theme";

export const Container = styled(Box)<BoxProps>`
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: ${theme.spacing(2)};
  overflow: auto;
  padding: ${theme.spacing(0.1, 2)};
  @media screen and (max-width: 1024px) {
    width: 100%;
    min-width: 200px;
  }
`;

export const ButtonText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: "#616161",
  marginTop: "-3px",
  cursor: "pointer",
}));

export const Labeltext = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: "Mont",
  fontSize: 14,
  fontWeight: 400,
}));
