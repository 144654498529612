import {useState, useMemo, Fragment, useEffect, useContext} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {FormProvider, SubmitHandler, useForm, useWatch} from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import Validator from "validator";
//Material Ui
import { Box, IconButton, Link, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
//ContextAPI
import {
  FormHandlerProvider,
  useAuth,
  useStepperContext,
} from "../../../../contextAPI";
//Assets
import { Images, Svg } from "../../../../assets";
//Services
import {
  checkFlowsEligibility,
  getFields,
  getStartupById,
  getRequestedFiles,
  getUserMe, updateStartup,
  updateUserMe, uploadStartupFile, getStartups, updateStartupDocuments, updateAndValidateStartupDocuments
} from "../../../../services";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//components
import {
  AutoComplete,
  Button,
  DatePicker, DropzoneFile, DropzoneFiles,
  Form,
  Image,
  Input,
  ModalConfirmation,
} from "../../../../components";
//Helpers
import { country, SnackbarHolder, sortFieldsArray } from "../../../../helpers";
//Styles
import {
  ButtonText,
  Container,
  ContainerLeft,
  ContainerHalf,
  ContainerRight,
  Information,
  SectionContainer,
  SectionSubTitle,
  SectionTitle,
  DocumentBox,
  DocumentText,
  Labeltext,
} from "./validationStartup.styles";
//Types
import { ParamsProps, ValidationStartupState } from "./validationStartup.types";
import DisplayFormInputs from "../../../SharedPages/DisplayFormInputs";
import _ from "lodash";
import {LanguageContext} from "../../../../LanguageContext";

const exeptionFields = [
  "id",
  "status",
  "logo",
  "user_created",
  "user_updated",
  "date_updated",
  "date_created",
  "list-m2m",
  "due_diligence",
  "accompaniment",
  "other_partner_name",
  "contact_ref",
  "other_partner_email",
  "partner",
  "name",
  "country",
  "website",
  "file",
  "select-dropdown-m2o",
  "age",
];
const ValidationStartup = () => {
  const auth = useAuth();
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const stepper = useStepperContext();
  const params: ParamsProps = useParams();
  const { language } = useContext(LanguageContext);

  //states
  const [open, setOpen] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isEligible, setIsEligible] = useState<boolean>(false);
  const [paramsFields, setParamsFields] = useState<string>("");
  const [startupID, setStartupID] = useState<string | null>(null);

  const methods = useForm<ValidationStartupState>({
    defaultValues: {
      firstName: "",
      lastName: "",
      nationality: null,
      country: null,
      birthDay: "",
      urlLinkedIn: "",
      requestedDocs: [],
    },
  });

  const { register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  //queries & mutations
  const startupFields = useQuery(
      ["fields", params.id, auth.token],
      () => {
        if (!auth.token) return;
        return getFields("startup", auth.token);
      },
      {
        onSuccess(fields) {
          let str = "";
          let arrayRepeater = fields?.data?.data.filter(
              (field: any) => field.meta.interface === "list-m2m"
          );
          arrayRepeater.forEach((element: any) => {
            str =
                str + `,${element.field}.*,${element.field}.${element.field}_id.*`;
          });

          let arrayOfMultiFiles = fields?.data?.data.filter(
              (field: any) => field.meta.interface === "files"
          );

          arrayOfMultiFiles.forEach((element: any) => {
            str =
                str + `,${element.field}.*,${element.field}.directus_files_id.*`;
          });
          let arrayOfFiles = fields?.data?.data.filter(
              (field: any) => field.meta.interface === "file"
          );
          arrayOfFiles.forEach((element: any) => {
            if (!exeptionFields.includes(element.field)) {
              str = str + `,${element.field}.*`;
            }
          });
          setParamsFields(str);
        },
      }
  );


  const dataStartup = useQuery(
      ["startup-edit", params.id, paramsFields],
      () => {
        if (!params.id || !auth.token) return;
        if (paramsFields === "") return;
        return getStartupById({
          id: params.id,
          moreFields: paramsFields,
          token: auth.token,
        });
      },
      {
        enabled: startupFields.isFetched,
      }
  );

  const requestedFiles = useQuery(
      ["requested_files", auth.token],
      () => {
        if (!auth.token) return;
        return getRequestedFiles({
          token: auth.token,
        });
      },
  );


  const getUser = useQuery(
      ["user", auth.token],
      () => {
        if (!auth.token) return;
        return getUserMe({ token: auth.token });
      },
      {
        onSuccess(data) {
          methods.reset({
            firstName: data?.data?.data.first_name,
            lastName: data?.data?.data.last_name,
            nationality: {
              name: data?.data?.data.nationality,
              code: data?.data?.data.nationality,
            },
            country: {
              name: data?.data?.data.country,
              code: data?.data?.data.country,
            },
            birthDay: data?.data?.data.birthday,
            urlLinkedIn: data?.data?.data.linkedin,
          });
        },
      }
  );

  const updateUser = useMutation(updateUserMe);
  const checkEligibility = useMutation(checkFlowsEligibility);

  //memos
  const fields = useMemo(
      () => startupFields?.data?.data?.data.sort(sortFieldsArray),
      [startupFields]
  );


  const startup = useMemo(() => dataStartup?.data?.data?.data, [dataStartup]);
  const requestedFilesData = useMemo(() => requestedFiles?.data?.data?.data, [requestedFiles]);

  const requestedDocsInfo = useMemo(() => {
    if (startup && startup.requested_docs && startup.requested_docs.length > 0 && requestedFilesData) {
      return startup.requested_docs.map((doc:any) => {
        const file = requestedFilesData.find((file:any) => file.id === doc.requested_files_id);

        return file ? file : null;
      }).filter((doc:any) => doc !== null);
    }
    return [];
  }, [startup, requestedFilesData]);

  const thereIsRefusedDocument=requestedDocsInfo?.filter((requestedDoc:any)=>requestedDoc.status=='refused')


  const user = useMemo(() => getUser?.data?.data?.data, [getUser]);
  const boxdirections = useMemo(
      () => (width < 490 ? "column" : "row"),
      [width]
  );
  const boxjustify = useMemo(
      () => (width < 490 ? "center" : "flex-start"),
      [width]
  );

  //useeffects
  useEffect(() => {
    dataStartup.refetch();
    if (params.id) {
      setStartupID(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFields]);

  //funstions
  const handleClose = () => {
    setOpen(false);
  };
  const handleVisibility = () => {
    setIsEditable(!isEditable);
  };

  const onFailSubmit = (data: any) => {
    SnackbarHolder.alert("error", language === "fr" ? "Des champs obligatoires non remplis":"Mandatory fields not filled in");
  };

  const fileUploading = useMutation(uploadStartupFile);

  const fileStartUpUploading = useMutation(updateStartupDocuments);

  const handleUploadDocumentFiles = async (file: any,id:any) => {
    if (!auth.token) return;
    try {
      return await fileStartUpUploading.mutateAsync({ data: {document:file,status:'pending'}, id:id, token: auth.token });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const handleUploadFiles = async (file: any) => {
    if (!auth.token) return;
    try {
      return await fileUploading.mutateAsync({ file: file, token: auth.token });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const onSubmit: SubmitHandler<ValidationStartupState> = async (data) => {
    if (!auth.token) return;
    const dataToSend = {
      first_name: data.firstName,
      last_name: data.lastName,
      country: data.country?.name,
      nationality: data.nationality?.name,
      birthday: data.birthDay,
      linkedin: data.urlLinkedIn,
    };
    try {
      const res = await updateUser.mutateAsync({
        data: dataToSend,
        token: auth.token,
      });
      if (res?.status === 200) {
        getUser.refetch();
        SnackbarHolder.alert("success", language === "fr" ? "Données mis à jour avec succés":"Data updated successfully");
      }
    } catch (error: any) {
      SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
    }
    handleVisibility();
  };
  const validate: any = async () => {
    if (!auth.token) return;
    try {
      const res = await checkEligibility.mutateAsync({
        data: { id: params.id },
        token: auth.token,
      });
      if (res.status === 200) {
        if (res.data === "") return;
        if (res.data.data.data.status === "Non éligible") {
          setIsEligible(false);
        } else {
          setIsEligible(true);
        }
        setOpen(true);
      }
    } catch (error: any) {
      SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
    }
  };

  const handleDialogAction = () => {
    if (isEligible) {
      /**redirect to middle Office */
      navigate(`/no-draft/${params.id}`, { replace: true });
    } else if (!isEligible) {
      /**redirect to landingPage */
      auth.signOut(`${process.env.REACT_APP_LANDING_PAGE}`);
    }
  };
  // const handleICIClick = () => {
  //   /**redirect to FAQ */
  //   auth.signOut(`${process.env.REACT_APP_FAQ}`);
  // };

  const handleBack = () => {
    navigate(`/add-support/${params.id}`, { replace: true });
    stepper.handleBack();
  };
  const handleEdit = (direction: string) => {
    navigate(`/${direction}/${params.id}`, {
      replace: true,
    });
  };

  //renders
  const modalItemAccpeted = () => {
    return (
        <>
          <Typography
              fontFamily="Mont"
              fontSize={20}
              fontWeight={600}
              color="#000"
              textAlign="center"
          >
            {language === "fr" ? "Merci !":"THANK YOU !"}

          </Typography>
          <Typography
              fontFamily="Mont"
              fontSize={16}
              fontWeight={400}
              textAlign="center"
              color="#999999"
          >
            {language === "fr" ? "Votre startup est éligible.":"Your startup is eligible."}

          </Typography>
          <Typography
              fontFamily="Mont"
              fontSize={16}
              fontWeight={400}
              textAlign="center"
              color="#999999"
          >
            {language === "fr" ? "Votre candidature a été partagée avec Digital Africa pour examen et\n" +
                "            évaluation conformément aux conditions générales d'utilisation. Votre\n" +
                "            structure d’accompagnement sera notifiée et elle sera invitée à nous\n" +
                "            soumettre son avis. A bientôt.":"Your application has been shared with Digital Africa for review and evaluation in accordance with the terms of use. Your support structure will be notified and will be invited to submit its opinion to us. See you soon."}

          </Typography>
        </>
    );
  };
  const modalItemRejected = () => {
    return (
        <>
          <Typography
              fontFamily="Mont"
              fontSize={20}
              fontWeight={600}
              color="#000"
              textAlign="center"
          >
            {language === "fr" ? "Nous vous remercions de l'intérêt que vous portez à l'initiative.\n" +
                "            Plusieurs critères sont pris en compte pour évaluer l’éligibilité de\n" +
                "            votre entreprise notamment la composante numérique, la nationalité des\n" +
                "            cofondateurs et la durée d’existence de la startup.":"Thank you for your interest in the initiative. Several criteria are taken into account to assess the eligibility of your company, including the digital component, the nationality of the co-founders and the duration of the startup's existence."}

          </Typography>

          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
                fontFamily="Mont"
                fontSize={20}
                fontWeight={600}
                color="#000"
                textAlign="center"
            >
              {language === "fr" ? "Malheureusement, votre startup n’est pas éligible.":"Unfortunately, your startup is not eligible."}

            </Typography>
            <Typography
                fontFamily="Mont"
                fontSize={16}
                fontWeight={600}
                color="#EF4B12"
                textAlign="center"
                // sx={{ cursor: "pointer" }}
                // onClick={handleICIClick}
            >
              {language === "fr" ? "Nous vous invitons à découvrir les autres programmes et actions Digital Africa qui pourraient correspondre à votre projet.":"We invite you to discover other Digital Africa programs and actions that could correspond to your project."}

            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={1}>
            <Link
                target="_blank"
                href="https://www.linkedin.com/company/digitalafrica/"
            >
              <Svg.LinkedIn />
            </Link>
            <Link
                target="_blank"
                href="https://www.facebook.com/Digitalafrica.co/"
            >
              <Svg.Facebook />
            </Link>
            <Link target="_blank" href="https://twitter.com/digitalafrica__">
              <Svg.Twitter />
            </Link>
          </Box>
        </>
    );
  };
  const renderPersonalInformation = () => {
    if (isEditable) {
      return (
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
              >
                <SectionTitle>
                  {language === "fr" ? "Informations personnel":"Personal informations"}</SectionTitle>
                <IconButton
                    onClick={methods.handleSubmit(onSubmit, onFailSubmit)}
                >
                  <Svg.ConfirmInput />
                </IconButton>
              </Box>
              <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  maxHeight={width > 490 ? (height - 550) / 2 : "auto"}
                  minHeight={width > 490 ? (height - 550) / 2 : "auto"}
                  pt={1}
                  overflow="auto"
              >
                <Input
                    name="lastName"
                    minWidth={200}
                    label={
                      <Box display="flex">
                        <Labeltext color="rgba(43, 45, 50, 0.64)">{language === "fr" ?"Nom":"Last name"}</Labeltext>
                        <Labeltext color="#EF4B12">*</Labeltext>
                      </Box>
                    }
                    rules={{
                      required: language === "fr" ?"Ce champ est requis.":"This field is required.",
                    }}
                />
                <Input
                    name="firstName"
                    minWidth={200}
                    label={
                      <Box display="flex">
                        <Labeltext color="rgba(43, 45, 50, 0.64)">{language === "fr" ?"Prénom":"First name"}</Labeltext>
                        <Labeltext color="#EF4B12">*</Labeltext>
                      </Box>
                    }
                    rules={{
                      required: language === "fr" ?"Ce champ est requis.":"This field is required.",
                    }}
                />

                <AutoComplete
                    name="nationality"
                    label={
                      <Box display="flex">
                        <Labeltext color="rgba(43, 45, 50, 0.64)">
                          {language==="fr" ? "Nationalité du fondateur":"Founder's nationality"}
                        </Labeltext>
                        <Labeltext color="#EF4B12">*</Labeltext>
                      </Box>
                    }
                    placeholder={language==="fr" ? "Nationalité du fondateur":"Founder's nationality"}
                    data={country}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option, value) => option.name === value.name}
                    rules={{
                      required: language === "fr" ?"Ce champ est requis.":"This field is required.",
                    }}
                />
                <AutoComplete
                    name="country"
                    label={
                      <Box display="flex">
                        <Labeltext color="rgba(43, 45, 50, 0.64)">
                          {language==="fr" ? "Pays de résidence":"Country of residence"}
                        </Labeltext>
                        <Labeltext color="#EF4B12">*</Labeltext>
                      </Box>
                    }
                    placeholder= {language==="fr" ? "Pays de résidence":"Country of residence"}
                    data={country}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option, value) => option.name === value.name}
                    rules={{
                      required: language === "fr" ?"Ce champ est requis.":"This field is required.",
                    }}
                />
                <DatePicker
                    name="birthDay"
                    label={
                      <Box display="flex">
                        <Labeltext color="rgba(43, 45, 50, 0.64)">
                          {language==="fr" ? "Date de naissance":"Date of birth"}

                        </Labeltext>
                        <Labeltext color="#EF4B12">*</Labeltext>
                      </Box>
                    }
                    rules={{
                      required: language === "fr" ?"Ce champ est requis.":"This field is required.",
                      validate: (value: string) =>
                          (Validator.isDate(value) && new Date(value) < new Date()) ||
                          (language === "fr" ?"Date invalide":"Invalid date"),
                    }}
                />
                <Input
                    name="urlLinkedIn"
                    minWidth={200}
                    label={
                      <Box display="flex">
                        <Labeltext color="rgba(43, 45, 50, 0.64)">
                          URL Linkedin
                        </Labeltext>
                      </Box>
                    }
                />
              </Box>
            </Form>
          </FormProvider>
      );
    } else if (!isEditable && !getUser.isLoading) {
      return (
          <>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
            >
              <SectionTitle>{language === "fr" ? "Informations personnel":"Personal informations"}</SectionTitle>
              {startup && startup.status==='draft' ? (
                  <IconButton onClick={handleVisibility}>
                    <Svg.EditIcon />
                  </IconButton>
              ):null}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                maxHeight={width > 490 ? (height - 550) / 2 : "auto"}
                minHeight={width > 490 ? (height - 550) / 2 : "auto"}
                pt={1}
                overflow="auto"
            >
              <Box display="flex" flexDirection="row" gap={1}>
                <SectionSubTitle>{language === "fr" ?"Prénom":"First name"}:</SectionSubTitle>
                <Information>{user.last_name}</Information>
              </Box>
              <Box display="flex" flexDirection="row" gap={1}>
                <SectionSubTitle>{language === "fr" ?"Prénom":"First name"}:</SectionSubTitle>
                <Information>{user.first_name}</Information>
              </Box>
              <Box display="flex" flexDirection="row" gap={1}>
                <SectionSubTitle>{language==="fr" ? "Nationalité du fondateur":"Founder's nationality"}:</SectionSubTitle>
                <Information>{user.nationality}</Information>
              </Box>
              <Box display="flex" flexDirection="row" gap={1}>
                <SectionSubTitle>{language==="fr" ? "Pays de résidence":"Country of residence"}:</SectionSubTitle>
                <Information>{user.country}</Information>
              </Box>
              <Box display="flex" flexDirection="row" gap={1}>
                <SectionSubTitle>{language==="fr" ? "Date de naissance":"Date of birth"}:</SectionSubTitle>
                <Information>{user.birthday}</Information>
              </Box>
              {user.linkedin && (
                  <Box display="flex" flexDirection="row" gap={1}>
                    <SectionSubTitle>LinkedIn:</SectionSubTitle>
                    <Information>
                      <Link target="_blank" href={user.linkedin}>
                        {user.linkedin}
                      </Link>
                    </Information>
                  </Box>
              )}
            </Box>
          </>
      );
    }
  };
  const renderMultifiles = () => {
    if (fields.length < 0) return;
    let arrayOfMultiFiles = fields.filter(
        (field: any) => field.meta.interface === "files"
    );

    if (!startup) return;
    // eslint-disable-next-line array-callback-return
    return arrayOfMultiFiles.map((array: any) => {
      if (startup[array.field] !== undefined) {
        return startup[array.field].map((file: any, index: number) => {
          return (
              <DocumentBox
                  key={index}
                  target="_blank"
                  href={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${file.directus_files_id.id}?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`}
              >
                {file.directus_files_id.type === "application/pdf" ? (
                    <Svg.PDFIcon />
                ) : (
                    <Svg.ExelIcon />
                )}

                <DocumentText>{file.directus_files_id.title}</DocumentText>
              </DocumentBox>
          );
        });
      }
    });
  };


  const getStatusText = (status:any) => {
    switch (status) {
      case 'pending':
        return language==="fr" ? "En attente":"Pending";
      case 'refused':
        return language==="fr" ? "Refusé":"Refused";
      case 'accepted':
        return language==="fr" ? "Accepté":"Accepted" ;
      default:
        return '';
    }
  };

  const getStatusClass = (status:any) => {
    switch (status) {
      case 'pending':
        return 'pending-class';
      case 'refused':
        return 'refused-class';
      case 'accepted':
        return 'accepted-class';
      default:
        return '';
    }
  };

  const renderMultiDocsfiles = () => {
    if (requestedDocsInfo.length < 0) return;
    let arrayOfMultiFiles = requestedDocsInfo.filter(
        (field: any) => field.document!==null
    );
    if (!startup) return;
    // eslint-disable-next-line array-callback-return
    return arrayOfMultiFiles.map((array: any, index: number) => {

      return (
          <DocumentBox
              key={index}
              target="_blank"
              href={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${array.document}?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`}
          >
           <span className={getStatusClass(array.status)}>
      {getStatusText(array.status)}
    </span>
            <Svg.PDFIcon />
            <DocumentText>{array.name}</DocumentText>
          </DocumentBox>
      );
    });
  };

  const startupUpdate = useMutation(updateStartup);

  const updateValidateStartupDocuments = useMutation(updateAndValidateStartupDocuments);


  const dataWatch = useWatch({ control: methods.control });
  const [startupAge, setStartupAge] = useState<number>(0);


  const handleDraftDocs = async (confirm_requested_documents:boolean) => {
    if (dataWatch && dataWatch.requestedDocs){

      const requestedDocsArray = Object.entries(dataWatch.requestedDocs).map(([id, value]) => ({
        id,
        value,
      }));
      const newErrorMessages = requestedDocsArray
          .filter(doc => requestedDocsInfo.find((info:any) => info.id === doc.id).required_doc && !doc.value)
          .map(doc => `Document ${doc.id} is required.`);

      if (newErrorMessages.length > 0) {
        setErrorMessages(newErrorMessages);
      } else {
        setErrorMessages([]);
      }
      let arrayOfIdFiles: any[] = [];
      if (confirm_requested_documents && newErrorMessages.length >0){
          SnackbarHolder.alert("error", language === "fr" ?"L'un des fichiers est requis.":"One of the files is required" );

      }else{
        for (const document of requestedDocsArray) {
          if (document.value){
            const res = await handleUploadFiles(document.value);
            if (res?.status === 200) {
              const response = await handleUploadDocumentFiles(res.data.data.id,document.id)
              let dataToSend={}
              if (confirm_requested_documents){
                dataToSend={
                  confirm_requested_documents_by_analyst:confirm_requested_documents
                }
                if (auth.token && startupID) {
                  try {
                    const responseStarrUp = await startupUpdate.mutateAsync({
                      data: dataToSend,
                      id: startupID,
                      token: auth.token,
                    });
                    if (responseStarrUp.status === 200) {
                      try {
                        SnackbarHolder.alert(
                            "success",
                            language === "fr" ? "Données sauvegardées avec succès":"Data saved successfully"
                        );
                        setOpen(true);

                      } catch (error: any) {
                        throw new Error(error);
                      }
                    } else {
                      SnackbarHolder.alert("error", responseStarrUp.data.errors[0].message);
                      responseStarrUp.data.errors.forEach((error: any) => {
                        methods.setError(
                            error.extensions.field,
                            {
                              type: "custom",
                              message: error.message,
                            },
                            { shouldFocus: true }
                        );
                      });
                    }
                  } catch (error: any) {
                    SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
                  }
                }

              }else{
                if (response.status === 200) {
                  try {
                    SnackbarHolder.alert(
                        "success",
                        language === "fr" ? "Données sauvegardées avec succès":"Data saved successfully"
                    );
                    globalThis.location.reload();
                    //setOpen(true);

                  } catch (error: any) {
                    throw new Error(error);
                  }
                } else {
                  SnackbarHolder.alert("error", response.data.errors[0].message);
                  response.data.errors.forEach((error: any) => {
                    methods.setError(
                        error.extensions.field,
                        {
                          type: "custom",
                          message: error.message,
                        },
                        { shouldFocus: true }
                    );
                  });
                }
              }

            }
          }


        }
      }

    }
  }

  const handleDraft = async (confirm_requested_documents:boolean) => {
    let arrayOfIdImages: any[] = [];
    if (dataWatch.requested_documents_by_analyst){
      for (const document of dataWatch.requested_documents_by_analyst) {
        const response = await handleUploadFiles(document);
        if (response.status === 200) {
          try {
            SnackbarHolder.alert(
                "success",
                language === "fr" ? "Données sauvegardées avec succès":"Data saved successfully"
            );
            setOpen(true);

          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          SnackbarHolder.alert("error", response.data.errors[0].message);
          response.data.errors.forEach((error: any) => {
            methods.setError(
                error.extensions.field,
                {
                  type: "custom",
                  message: error.message,
                },
                { shouldFocus: true }
            );
          });
        }
      }
    }
    let dataToSend={}
    if (arrayOfIdImages && arrayOfIdImages.length>0){
      dataToSend={
        requested_documents_by_analyst:arrayOfIdImages,
        confirm_requested_documents_by_analyst:confirm_requested_documents
      }
    }else{
      dataToSend={
        confirm_requested_documents_by_analyst:confirm_requested_documents
      }
    }

    if (auth.token && startupID) {
      try {
        const response = await startupUpdate.mutateAsync({
          data: dataToSend,
          id: startupID,
          token: auth.token,
        });
        if (response.status === 200) {
          try {
            SnackbarHolder.alert(
                "success",
                language === "fr" ? "Données sauvegardées avec succès":"Data saved successfully"
            );
            setOpen(true);

          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          SnackbarHolder.alert("error", response.data.errors[0].message);
          response.data.errors.forEach((error: any) => {
            methods.setError(
                error.extensions.field,
                {
                  type: "custom",
                  message: error.message,
                },
                { shouldFocus: true }
            );
          });
        }
      } catch (error: any) {
        SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
      }
    }
  };


  const functionTest = async () => {
    if (auth.token && startupID) {
      const dataToSend={
        requested_documents_by_analyst:dataWatch.requested_documents_by_analyst
      }
      try {
        const response = await startupUpdate.mutateAsync({
          data: dataToSend,
          id: startupID,
          token: auth.token,
        });
        if (response.status === 200) {
          try {

          }catch (error: any) {
            throw new Error(error);
          }
        }
      }catch (error: any) {
        SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
      }

    }
  }
  const renderFile = () => {
    if (fields.length < 0) return;
    const arrayofFiles = fields.filter(
        (field: any) => field.meta.interface === "file"
    );
    if (!startup) return;
    // eslint-disable-next-line array-callback-return
    return arrayofFiles.map((file: any, index: number) => {
      if (startup[file.field] != null) {
        return (
            <DocumentBox
                key={index}
                target="_blank"
                href={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${
                    startup[file.field].id
                }?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`}
            >
              {startup[file.field].type === "application/pdf" ? (
                  <Svg.PDFIcon />
              ) : (
                  <Svg.ExelIcon />
              )}
              <DocumentText>{startup[file.field].title}</DocumentText>
            </DocumentBox>
        );
      }
    });
  };

  if (
      startupFields.isLoading ||
      getUser.isLoading ||
      dataStartup.isLoading ||
      !getUser.isFetched ||
      !dataStartup.isFetched
  ) {
    return <CircularProgress />;
  }

  return (
      <FormHandlerProvider
          loading={
              startupFields.isLoading || getUser.isLoading || dataStartup.isLoading
          }
      >
        <Container
            minHeight={width < 490 ? height - 380 : height - 420}
            maxHeight={width < 490 ? height - 380 : height - 420}
        >
          <SectionContainer
              minHeight={width < 490 ? height - 380 : height - 420}
              maxHeight={width < 490 ? height - 380 : height - 420}
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <ContainerLeft
                  maxHeight={width > 490 ? (height - 460) / 2 : "auto"}
                  minHeight={width > 490 ? (height - 460) / 2 : "auto"}
              >
                {renderPersonalInformation()}
              </ContainerLeft>
              <ContainerLeft
                  maxHeight={width > 490 ? (height - 460) / 2 : "auto"}
                  minHeight={width > 490 ? (height - 460) / 2 : "auto"}
              >
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                  <SectionTitle>{language==="fr" ? "Accompagnement":"Support"}</SectionTitle>
                  {startup && startup.status==='draft' ? (
                      <IconButton onClick={() => handleEdit("add-support")}>
                        <Svg.EditIcon />
                      </IconButton>
                  ):null}

                </Box>
                {!dataStartup.isLoading && startup && (
                    <>
                      {startup.accompaniment && (
                          <Box display="flex" flexDirection="row" gap={1}>
                            <SectionSubTitle>{language==="fr" ? "Contact référent:":"Contact person:"}</SectionSubTitle>
                            <Information>
                              {dataStartup.isLoading ? "" : startup.contact_ref}
                            </Information>
                          </Box>
                      )}
                      <Box display="flex" flexDirection="row" gap={1}>
                        <SectionSubTitle>
                          {language==="fr" ? "Avez-vous intégré un programme d’accompagnement:":"Have you taken part in a support program:"}

                        </SectionSubTitle>
                        <Information>
                          {dataStartup.isLoading
                              ? ""
                              : startup.accompaniment
                                  ? language==="fr" ?"oui":"Yes"
                                  : language==="fr" ?"non":"No"}
                        </Information>
                      </Box>
                    </>
                )}
              </ContainerLeft>
            </Box>
            <ContainerRight
                maxHeight={width > 1060 ? height - 450 : "auto"}
                minHeight={width > 1060 ? height - 450 : "auto"}
            >
              <ContainerHalf>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignContent="center"
                >
                  <SectionTitle>{language==="fr" ? "Description de la Startup":"Startup description"}</SectionTitle>
                  {startup && startup.status==='draft' ? (
                      <><IconButton onClick={() => handleEdit("edit-startup")}>
                        <Svg.EditIcon />
                      </IconButton></>
                  ):null}

                </Box>
                <Box></Box>
                {!dataStartup.isLoading && startup && (
                    <Box
                        display="flex"
                        flexDirection={boxdirections}
                        alignItems={boxjustify}
                        gap={1}
                    >
                      <Image
                          src={
                            startup.logo
                                ? `${process.env.REACT_APP_DIRECTUS_URL}/assets/${startup.logo}?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`
                                : Images.DefaultLogo
                          }
                          height={80}
                          width={80}
                          borderRadius={50}
                      />
                      <Box display="flex" flexDirection="column">
                        <SectionSubTitle>
                          {dataStartup.isLoading ? "" : startup.name}
                        </SectionSubTitle>
                        <Box display="flex" flexDirection="row" gap={1}>
                          <SectionSubTitle>
                            {language==="fr" ? "Pays d’origine:":"Country of origin:"}
                            </SectionSubTitle>
                          <Information>
                            {dataStartup.isLoading ? "" : startup.country}
                          </Information>
                        </Box>
                        {startup.website && (
                            <Box display="flex" flexDirection="row" gap={1}>
                              <SectionSubTitle>URL:</SectionSubTitle>
                              <Information>
                                <Link
                                    target="_blank"
                                    href={dataStartup.isLoading ? "#" : startup.website}
                                >
                                  {dataStartup.isLoading ? "" : startup.website}
                                </Link>
                              </Information>
                            </Box>
                        )}
                      </Box>
                    </Box>
                )}
                {!startupFields.isLoading &&
                    !dataStartup.isLoading &&
                    startup &&
                    fields.map(
                        (startupField: any, index: number) =>

                            !exeptionFields.includes(startupField.field) && (

                                <Fragment key={index}>
                                  {startupField.meta.interface === "input" &&
                                      startup[startupField.field] !== "" &&
                                      startup[startupField.field] !== 0 &&
                                      startup[startupField.field] != null && (
                                          <Box
                                              display="flex"
                                              flexDirection="column"
                                              gap={1}
                                              flexWrap="wrap"
                                          >
                                            <SectionSubTitle>
                                              {language === "fr"? startupField.meta.note : startupField.meta.note && startupField.meta?.translations ? startupField.meta?.translations[0].translation:null}
                                            </SectionSubTitle>
                                            <Information>
                                              {startup[startupField.field]}
                                            </Information>
                                          </Box>
                                      )}
                                  {(startupField.meta.interface ===
                                          "select-multiple-checkbox" ||
                                          startupField.meta.interface ===
                                          "select-multiple-dropdown") &&
                                      startup[startupField.field].length > 0 && (
                                          <Box display="flex" flexDirection="column" gap={1}>
                                            <SectionSubTitle>
                                              {language === "fr"? startupField.meta.note : startupField.meta.note && startupField.meta?.translations ? startupField.meta?.translations[0].translation:null}
                                            </SectionSubTitle>
                                            <Information>
                                              {new Intl.ListFormat("en-GB", {
                                                style: "narrow",
                                                type: "unit",
                                              }).format(startup[startupField.field])}
                                            </Information>
                                          </Box>
                                      )}

                                  {startupField.meta.interface === "input-multiline" &&
                                      startup[startupField.field] && (
                                          <Box display="flex" flexDirection="column" gap={1}>
                                            <SectionSubTitle>
                                              {language === "fr"? startupField.meta.note : startupField.meta.note && startupField.meta?.translations ? startupField.meta?.translations[0].translation:null}
                                            </SectionSubTitle>
                                            <Information>
                                              {startup[startupField.field]}
                                            </Information>
                                          </Box>
                                      )}
                                  {startupField.meta.interface === "datetime" &&
                                      startup[startupField.field] && (
                                          <Box
                                              display="flex"
                                              flexDirection="column"
                                              gap={1}
                                              flexWrap="wrap"
                                          >
                                            <SectionSubTitle>
                                              {language === "fr"? startupField.meta.note : startupField.meta.note && startupField.meta?.translations ? startupField.meta?.translations[0].translation:null}
                                            </SectionSubTitle>
                                            <Information>
                                              {startup[startupField.field]}
                                            </Information>
                                          </Box>
                                      )}
                                  {startupField.meta.interface === "select-dropdown" &&
                                      startup[startupField.field] && (
                                          <Box display="flex" flexDirection="column" gap={1}>
                                            <SectionSubTitle>
                                              {language === "fr"? startupField.meta.note : startupField.meta.note && startupField.meta?.translations ? startupField.meta?.translations[0].translation:null}
                                            </SectionSubTitle>
                                            <Information>
                                              {startup[startupField.field]}
                                            </Information>
                                          </Box>
                                      )}
                                  {startupField.meta.interface === "boolean" &&
                                      startup[startupField.field] && (
                                          <Box display="flex" flexDirection="column" gap={1}>
                                            <SectionSubTitle>
                                              {language === "fr"? startupField.meta.note : startupField.meta.note && startupField.meta?.translations ? startupField.meta?.translations[0].translation:null}
                                            </SectionSubTitle>
                                            <Information>
                                              {startup[startupField.field] ? (language === "fr" ?"Oui":"Yes") : (language === "fr" ?"Non":"No")}
                                            </Information>
                                          </Box>
                                      )}
                                </Fragment>
                            )
                    )}
                <Box>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <SectionSubTitle>{language==="fr" ? "Documents fournis:":"Documents provided:"}</SectionSubTitle>
                    <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        gap={1}
                    >
                      {!startupFields.isLoading &&
                          !dataStartup.isLoading &&
                          renderMultifiles()}
                      {!startupFields.isLoading &&
                          !dataStartup.isLoading &&
                          startup?.give_permission_sending_documents_to_analyst &&
                          requestedDocsInfo.length>0 &&
                          renderMultiDocsfiles() }
                    </Box>
                  </Box>
                </Box>


              </ContainerHalf>
            <ContainerHalf>
              {startup && startup.give_permission_sending_documents_to_analyst || (startup && startup.confirm_requested_documents_by_analyst && thereIsRefusedDocument?.length>0)? (
                  <Box>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <SectionSubTitle> {language==="fr" ? "Documents Supplémentaires:":"Additional Documents:"}</SectionSubTitle>
                      <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)}>

                          {requestedDocsInfo.map((docInfo:any,index:any) => (
                              (docInfo.status === 'pending' || docInfo.status === 'refused') && (
                                  <div key={docInfo.id} style={{ marginBottom: '15px' }}>
                                    <SectionSubTitle style={{ marginBottom: '5px' }}>
                                      {docInfo.name} :
                                    </SectionSubTitle>
                                    <DropzoneFile
                                        height={50}
                                        acceptTypes={".mp4,.jpg,.png"}
                                        message={docInfo.required_doc ? `${docInfo.name} *` : docInfo.name}
                                        {...register(`requestedDocs.${docInfo.id}`, {
                                          required: docInfo.required_doc ? (language === "fr" ?"Ce champ est requis.":"This field is required.") : false,
                                        })}
                                    />
                                    {/*                          {errors.requestedDocs?.[docInfo.id] && (*/}
                                    {/*                              <span style={{ color: 'red' }}>*/}
                                    {/*  {errors.requestedDocs[docInfo.id]?.message}*/}
                                    {/*</span>*/}
                                    {/*                          )}*/}
                                  </div>
                              )
                          ))}


                          <Button
                              variant="contained"
                              boxshadow="0px 5px 5px rgba(172, 172, 172, 0.2)"
                              width={width < 490 ? 110 : 190}
                              onClick={() => handleDraftDocs(false)}
                              sx={{ backgroundColor: "#E0E0E7", color: "#000000" }}
                          >
                            {language === "fr" ?"Sauvegarder":"Save"}
                          </Button>

                          <Button
                              variant="contained"
                              color="primary"
                              boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
                              width={width < 490 ? 130 : 200}
                              sx={{ marginLeft: "10px"}}
                              onClick={() => handleDraftDocs(true)}
                          >
                            {language==="fr" ? "Sauvegarder & valider":"Save & Submit"}
                          </Button>

                        </Form>
                      </FormProvider>
                    </Box>
                  </Box>
              ) : null}
            </ContainerHalf>
            </ContainerRight>

          </SectionContainer>

          <Box
              display="flex"
              justifyContent="space-between"
              mt="12px"
              width="90%"
          >
            {startup && startup?.status==='draft' ? (
                <><Button
                    variant="text"
                    startIcon={<Svg.ArrowLeft />}
                    onClick={handleBack}
                >
                  {width < 490 ? null : <ButtonText>{language === "fr" ?"Précédent":"Previous"}</ButtonText>}
                </Button></>
            ):(<>{width < 490 ? null : <ButtonText></ButtonText>}</>)}


            <Button
                variant="contained"
                color="primary"
                boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
                width={width < 490 ? 110 : 190}
                onClick={validate}
            >
              {language==="fr" ? "Valider":"Submit"}

            </Button>
          </Box>
        </Container>
        <ModalConfirmation
            isVisible={open}
            image={Images.Accepted}
            rightButtonText="Merci"
            rightButtonAction={handleDialogAction}
            children={isEligible ? modalItemAccpeted() : modalItemRejected()}
            onClickClose={handleClose}
        />
      </FormHandlerProvider>
  );
};

export default ValidationStartup;
