function Confirm() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <circle cx="50" cy="50" r="50" fill="#F9BC4A" opacity="0.1"></circle>
      <path
        stroke="#F9BC4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M41 52.75a5.75 5.75 0 100-11.5 5.75 5.75 0 000 11.5z"
      ></path>
      <path
        stroke="#F9BC4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M21.833 48.687v-3.374a3.652 3.652 0 013.642-3.641c3.47 0 4.887-2.454 3.143-5.463a3.64 3.64 0 011.342-4.964l3.316-1.898c1.514-.9 3.469-.364 4.37 1.15l.21.365c1.726 3.009 4.562 3.009 6.306 0l.211-.364c.901-1.515 2.856-2.051 4.37-1.15l3.316 1.897a3.64 3.64 0 011.342 4.964c-1.744 3.01-.326 5.463 3.143 5.463a3.652 3.652 0 013.642 3.641v3.374a3.652 3.652 0 01-3.642 3.641c-3.469 0-4.887 2.454-3.143 5.463a3.635 3.635 0 01-1.342 4.964l-3.316 1.898c-1.514.9-3.469.364-4.37-1.15l-.21-.365c-1.725-3.009-4.562-3.009-6.306 0l-.211.364c-.901 1.515-2.856 2.051-4.37 1.15l-3.316-1.897a3.64 3.64 0 01-1.342-4.964c1.745-3.01.326-5.463-3.143-5.463a3.652 3.652 0 01-3.642-3.641zM70 68a3 3 0 100-6 3 3 0 000 6z"
      ></path>
      <path
        stroke="#F9BC4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M60 65.88v-1.76c0-1.04.85-1.9 1.9-1.9 1.81 0 2.55-1.28 1.64-2.85-.52-.9-.21-2.07.7-2.59l1.73-.99c.79-.47 1.81-.19 2.28.6l.11.19c.9 1.57 2.38 1.57 3.29 0l.11-.19c.47-.79 1.49-1.07 2.28-.6l1.73.99c.91.52 1.22 1.69.7 2.59-.91 1.57-.17 2.85 1.64 2.85 1.04 0 1.9.85 1.9 1.9v1.76c0 1.04-.85 1.9-1.9 1.9-1.81 0-2.55 1.28-1.64 2.85.52.91.21 2.07-.7 2.59l-1.73.99c-.79.47-1.81.19-2.28-.6l-.11-.19c-.9-1.57-2.38-1.57-3.29 0l-.11.19c-.47.79-1.49 1.07-2.28.6l-1.73-.99a1.899 1.899 0 01-.7-2.59c.91-1.57.17-2.85-1.64-2.85-1.05 0-1.9-.86-1.9-1.9z"
      ></path>
    </svg>
  );
}

export default Confirm;
