import { FC } from "react";
//Types
import { ImagesProps } from "./image.types";

const Image: FC<ImagesProps> = (props) => {
  const { borderRadius, objectFit, backgroundColor, ...rest } = props;

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      {...rest}
      style={{ borderRadius, objectFit: objectFit || "cover", backgroundColor }}
    />
  );
};

export { Image };
