import {useContext, useEffect, useMemo} from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";
//Material ui
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
//hooks
import { useWindowDimensions } from "../../hooks";
//helpers
import {
  getLastRouteName,
  getPageNameFromPathName,
  Partner,
  SnackbarHolder,
  Startup,
} from "../../helpers";
//ContextAPI
import { RequireAuth, useAuth } from "../../contextAPI";
//services
import {
  getNotifications,
  getStartups,
  getUserMe,
  signOut,
} from "../../services";
//Components
import { FooterGeneral } from "../../components/Footers";
import { HeaderForm } from "../../components/Headers";
//Styles
import { Container } from "./mainLayout.styles";
import {LanguageContext} from "../../LanguageContext";

//consts
const permissionsArray = [
  "draft",
  "En attente de l'approbation du partenaire",
  "En attente de l'examen par l'analyste",
  "Notation du partenaire en attente",
];

const MainLayout = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = getLastRouteName(location);
  const pageName = getPageNameFromPathName(location);
  const { height, width } = useWindowDimensions();
  const { language } = useContext(LanguageContext);

  //queries & mutations
  const getUser = useQuery(
    ["user", auth.token],
    () => {
      if (!auth.token) return;
      return getUserMe({ token: auth.token });
    },
    {
      enabled: auth.token ? true : false,
    }
  );
  const login = useMutation(signOut);
  const dataStartups = useQuery(
    ["startups", auth.token],
    () => {
      if (!auth.token) return;
      return getStartups({
        token: auth.token,
      });
    },
    {
      enabled: auth.token ? true : false,
    }
  );
  const dataNotifications = useQuery(
    ["notifications", auth.token],
    () => {
      if (!auth.token) return;
      return getNotifications({
        token: auth.token,
      });
    },
    {
      enabled: auth.token ? true : false,
    }
  );

  //useEffects
  useEffect(() => {
    if (
      sessionStorage.getItem("role") === Partner &&
      pageName[0] !== "partner" &&
      pageName.length > 0
    ) {
      navigate("/partner/form-company", { replace: true });
    } else if (
      sessionStorage.getItem("role") === Startup &&
      ![
        "form-startup",
        "edit-startup",
        "add-support",
        "validation",
        "no-draft",
      ].includes(pathName!)
    ) {
      navigate("/form-startup", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName]);

  //memos
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deviceHeight = useMemo(() => _.clone(height), []);
  const myStartup = useMemo(
    () => dataStartups?.data?.data?.data[0],
    [dataStartups]
  );
  const notifications = useMemo(
    () => dataNotifications?.data?.data,
    [dataNotifications]
  );

  const user = useMemo(() => getUser?.data?.data?.data, [getUser]);

  //functions
  const handleLogout = async () => {
    try {
      const logRes = await login.mutateAsync({
        refresh_token: sessionStorage.getItem("refreshToken"),
      });
      if (logRes?.status === 204) {
        auth.signOut();
        navigate("/", { replace: true });
      }
    } catch (error: any) {
      SnackbarHolder.alert("error", error);
    }
  };

  //renders
  if (
    auth.token &&
    getUser.isFetched &&
    dataStartups.isFetched &&
    user.role === Startup &&
    dataStartups &&
    myStartup &&
    !permissionsArray.includes(myStartup.status) &&
    pathName !== "no-draft"
  ) {
    if (user.last_page) {
      return (
        <Navigate to={`${user.last_page}`} state={{ from: location }} replace />
      );
    }
    return (
      <Navigate
        to={`/no-draft/${myStartup.id}`}
        state={{ from: location }}
        replace
      />
    );
  }
  if (
    dataNotifications.isLoading ||
    !dataNotifications.isFetched ||
    !notifications
  ) {
    if (!getUser.isLoading && !auth.token) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <RequireAuth>
      <Container minHeight={width < 1024 ? deviceHeight : "100vh"}>
        <HeaderForm
          buttonText={language === "fr" ?"Déconnexion.":"Logout"}
          buttonAction={handleLogout}
          notificationsCount={notifications.meta.total_count}
          data={notifications.data}
        />
        <Outlet />
        <FooterGeneral />
      </Container>
    </RequireAuth>
  );
};

export default MainLayout;
