import { useFormContext } from "react-hook-form";
import Validator from "validator";
//Material Ui
import { Box } from "@mui/material";
//components
import {
  AutoComplete,
  AutoCompleteMultiple,
  DatePicker,
  DropzoneFile,
  DropzoneFiles,
  Input,
  RadioGroup,
  Select,
} from "../../../components";
//styles
import { Labeltext } from "./displayFormInputs.styles";
import {lazy, Suspense, useContext} from "react";
import {LanguageContext} from "../../../LanguageContext";

type DisplayFormInputsProps = {
  field: any;
};

const Reapter = lazy(() => import("./reapter"));

const DisplayFormInputs = (props: DisplayFormInputsProps) => {
  const { field } = props;
  const methods = useFormContext();
  const { language } = useContext(LanguageContext);
  let label = field.meta.note
  if (language === "fr"){
        label= field.meta.note
  }else if (field.meta.note && field.meta?.translations){
    field.meta?.translations.map((trans:any)=>{
      if (trans['language']==='en-US'){
        label= trans.translation
      }
    })
  }
  return (
    <>
      {field.meta.interface === "input" && (
        <Input
          minWidth={360}
          autoComplete="off"
          {...methods.register(field.field, {
            maxLength: {
              value: field.field === "name" ? 50 : 100,
              message: `Max ${
                field.field === "name" ? "50" : "100"
              } ${language === "fr" ?"caractères.":"characters"}`,
            },
            minLength: {
              value: ["integer", "decimal", "bigInteger", "float"].includes(
                field.type
              )
                ? 1
                : 2,
              message: `Min ${
                ["integer", "decimal", "bigInteger", "float"].includes(
                  field.type
                )
                  ? "1"
                  : "2"
              } ${language === "fr" ?"caractères.":"characters"}`,
            },
            required:
              field.meta.required || field.meta.conditions != null
                ? (language === "fr" ?"Ce champ est requis.":"This field is required.")
                : false,
            validate: (value: string) => {
              if (value === "") return;
              else if (
                ["integer", "decimal", "bigInteger", "float"].includes(
                  field.type
                ) &&
                value
              ) {
                return (
                  (Validator.isNumeric(value) && parseInt(value) > -1) ||
                  "Veuillez entrer un numbre valide."
                );
              } else if (
                (field.field === "website" ||
                  field.field === "youtube_link_of_your_pitch") &&
                value
              ) {
                return Validator.isURL(value) || "Lien invalide.";
              }
              return;
            },
          })}
          label={
            <Box display="flex">
              <Labeltext color="rgba(43, 45, 50, 0.64)">
                {label}
              </Labeltext>
              {field.meta.required && <Labeltext color="#EF4B12">*</Labeltext>}
            </Box>
          }
        />
      )}
      {field.meta.interface === "file" && (
        <DropzoneFile
          message={`${label}${field.meta.required ? "*" : ""}`}
          {...methods.register(field.field, {
            required: field.meta.required ? (language === "fr" ?"Ce champ est requis.":"This field is required.") : false,
          })}
        />
      )}
      {field.meta.interface === "files" && (
        <DropzoneFiles
          message={label}
          {...methods.register(field.field, {
            // required: field.meta.required ? "Ce champ est requis" : false,
          })}
        />
      )}

      {(field.meta.interface === "select-multiple-checkbox" ||
        field.meta.interface === "select-multiple-dropdown") && (
        <AutoCompleteMultiple
          id={field.field}
          label={
            <Box display="flex">
              <Labeltext color="rgba(43, 45, 50, 0.64)">
                {label}
              </Labeltext>
              {field.meta.required && <Labeltext color="#EF4B12">*</Labeltext>}
            </Box>
          }
          data={field.meta.options.choices ? field.meta.options.choices : []}
          getOptionLabel={(option: any) => option.text}
          getOptionValue={(option: any, value) =>
            option?.value === value?.value
          }
          {...methods.register(field.field, {
            required: field.meta.required ? (language === "fr" ?"Ce champ est requis.":"This field is required.") : false,
          })}
        />
      )}
      {field.meta.interface === "select-dropdown-m2o" && (
        <AutoComplete
          id={field.field}
          label={
            <Box display="flex">
              <Labeltext color="rgba(43, 45, 50, 0.64)">
                {label}
              </Labeltext>
              {field.meta.required && <Labeltext color="#EF4B12">*</Labeltext>}
            </Box>
          }
          data={field.meta.options.choices ? field.meta.options.choices : []}
          getOptionLabel={(item: any) => item.text}
          getOptionValue={(item: any) => item.value}
          {...methods.register(field.field, {
            required: field.meta.required ? (language === "fr" ?"Ce champ est requis.":"This field is required.") : false,
          })}
        />
      )}

      {field.meta.interface === "datetime" && (
        <DatePicker
          disableFuture={true}
          initValue={null}
          label={
            <Box display="flex">
              <Labeltext color="rgba(43, 45, 50, 0.64)">
                {label}
              </Labeltext>
              <Labeltext color="#EF4B12">*</Labeltext>
            </Box>
          }
          {...methods.register(field.field, {
            required: field.meta.required ? (language === "fr" ?"Ce champ est requis.":"This field is required.") : false,
            validate: (value: string) =>
              (Validator.isDate(value) && new Date(value) < new Date()) ||
                (language === "fr" ?"Date invalide":"Invalid date"),
          })}
        />
      )}
      {field.meta.interface === "list-m2m" && (
        // fallback component is rendered until our main component is loaded
        <Suspense fallback={<></>}>
          <Reapter repeater={field.field} />
        </Suspense>
      )}
      {field.meta.interface === "input-multiline" && (
        <Input
          minWidth={360}
          autoComplete="off"
          label={
            <Box display="flex">
              <Labeltext color="rgba(43, 45, 50, 0.64)">
                {label}
              </Labeltext>
              {field.meta.required && <Labeltext color="#EF4B12">*</Labeltext>}
            </Box>
          }
          multiline
          rows={3}
          {...methods.register(field.field, {
            required: field.meta.required ? (language === "fr" ?"Ce champ est requis.":"This field is required.") : false,
          })}
        />
      )}
      {field.meta.interface === "select-dropdown" && (
        <Select
          label={
            <Box display="flex">
              <Labeltext color="rgba(43, 45, 50, 0.64)">
                {label}
              </Labeltext>
              {field.meta.required && <Labeltext color="#EF4B12">*</Labeltext>}
            </Box>
          }
          data={field.meta.options.choices}
          getOptionLabel={(item: any) => item.text}
          getOptionValue={(item: any) => item.value}
          {...methods.register(field.field, {
            required: field.meta.required ? (language === "fr" ?"Ce champ est requis.":"This field is required.") : false,
          })}
        />
      )}
      {field.meta.interface === "boolean" && (
        <RadioGroup
          label={
            <Box display="flex">
              <Labeltext color="rgba(43, 45, 50, 0.64)">
                {label}
              </Labeltext>
              {field.meta.required && <Labeltext color="#EF4B12">*</Labeltext>}
            </Box>
          }
          data={[
            { label:language==="fr" ?"Oui":"Yes", value: true },
            { label: language==="fr" ?"Non":"No", value: false },
          ]}
          getOptionLabel={(item) => item.label}
          getOptionValue={(item) => item.value}
          {...methods.register(field.field, {
            required: field.meta.required ? (language === "fr" ?"Ce champ est requis.":"This field is required.") : false,
          })}
        />
      )}
    </>
  );
};

export default DisplayFormInputs;
