import {
  styled,
  Dialog,
  DialogProps,
  Typography,
  TypographyProps,
} from "@mui/material";

export const DialogContainer = styled(Dialog)<DialogProps>`
  display: "flex";
  justify-content: "center";
  align-items: "center";
  .Muipaper-root {
    min-height: "600px";
    min-width: "600px";
    border-radius: "20px";
    padding: "8px";
  }
`;

export const ButtonText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: "#616161",
  marginTop: "-3px",
  cursor: "pointer",
}));
