function StartupLanguage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      fill="none"
      viewBox="0 0 31 30"
    >
      <path
        fill="#EC6D3F"
        d="M21.339 26.563H8.787c-4.582 0-7.218-2.625-7.218-7.188v-8.75c0-4.563 2.636-7.188 7.218-7.188h12.552c4.582 0 7.218 2.626 7.218 7.188v8.75c0 4.563-2.636 7.188-7.218 7.188zM8.787 5.313c-3.59 0-5.335 1.737-5.335 5.312v8.75c0 3.575 1.745 5.313 5.335 5.313h12.552c3.59 0 5.335-1.738 5.335-5.313v-8.75c0-3.575-1.745-5.313-5.335-5.313H8.787z"
      ></path>
      <path
        fill="#EC6D3F"
        d="M15.063 19.688c-2.599 0-4.707-2.1-4.707-4.688 0-2.588 2.108-4.688 4.707-4.688 2.598 0 4.707 2.1 4.707 4.688 0 2.587-2.109 4.688-4.707 4.688zm0-7.5A2.823 2.823 0 0012.238 15a2.823 2.823 0 002.825 2.813A2.823 2.823 0 0017.887 15a2.823 2.823 0 00-2.824-2.813zM5.649 13.438a.946.946 0 01-.942-.938v-1.875c0-2.238 1.833-4.063 4.08-4.063h1.882c.515 0 .942.425.942.938a.946.946 0 01-.942.938H8.787a2.203 2.203 0 00-2.197 2.187V12.5a.946.946 0 01-.941.938zM24.477 13.438a.946.946 0 01-.941-.938v-1.875c0-1.2-.992-2.188-2.197-2.188h-1.883a.946.946 0 01-.941-.937c0-.513.426-.938.941-.938h1.883c2.247 0 4.08 1.825 4.08 4.063V12.5a.946.946 0 01-.942.938zM10.67 23.438H8.787c-2.247 0-4.08-1.825-4.08-4.063V17.5c0-.512.427-.938.942-.938.514 0 .941.425.941.938v1.875c0 1.2.992 2.188 2.197 2.188h1.882c.515 0 .942.425.942.937a.946.946 0 01-.942.938zM21.339 23.438h-1.883a.946.946 0 01-.941-.938c0-.512.426-.938.941-.938h1.883a2.203 2.203 0 002.197-2.187V17.5c0-.512.426-.938.941-.938.515 0 .941.425.941.938v1.875c0 2.238-1.832 4.063-4.08 4.063z"
      ></path>
    </svg>
  );
}

export default StartupLanguage;
