import { createContext, FC, useContext } from "react";
//Api
//Types
import {
  FormHandlerContextProps,
  FormHandlerProviderProps,
} from "./formHandlerContext.types";

let FormHandlerContext = createContext<FormHandlerContextProps>(null!);

const FormHandlerProvider: FC<FormHandlerProviderProps> = (props) => {
  const { children, loading } = props;
  //state

  return (
    <FormHandlerContext.Provider value={{ loading: loading || false }}>
      {children}
    </FormHandlerContext.Provider>
  );
};

const useFormHandlerContext = () => {
  return useContext(FormHandlerContext);
};

export { FormHandlerProvider, useFormHandlerContext };
