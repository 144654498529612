import {useContext, useEffect, useMemo, useState} from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
//Material Ui
import { Box } from "@mui/material";
//Helpers
import { getPageNameFromPathName } from "../../../helpers";
//Hooks
import { useWindowDimensions } from "../../../hooks";
//ContextAPI
import { useStepperContext } from "../../../contextAPI";
//Components
import { StepperList, TabsContainer } from "../../../components";
import {LanguageContext} from "../../../LanguageContext";

const Startup = () => {
  const stepper = useStepperContext();
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = getPageNameFromPathName(location);
  const { width } = useWindowDimensions();
  const { language } = useContext(LanguageContext);


  const listTabs = useMemo(
    () =>
      width < 490
        ? [
            { type: "startup", label: language === "fr" ? "Startup" : "Startup" },
            {
              type: "partner",
              label: language === "fr" ? "Structure d’accompagnement" : "Structure d’accompagnement",
            },
          ]
        : [
            { type: "startup", label: language === "fr" ? "Vous êtes une startup" : "You are a startup",},
            {
              type: "partner",
              label: language === "fr" ? "Vous êtes une structure d’accompagnement" : "You are a startup support organization" ,
            },
          ],
    [width,language]
  );
  const steps = useMemo(
    () =>
      width < 1024
        ? [
            language === "fr" ?"Profil":"Profile",
            language === "fr" ?"Startup":"Startup",
            language === "fr" ?"Accom.":"Supp.history",
            language === "fr" ?"Vérifi.":"App.check",
            ]
        : [
            language === "fr" ?"Profil Répondant":"Respondent profile",
            language === "fr" ?"Startup":"Startup",
            language === "fr" ?"Accompagnement":"Support history",
            language === "fr" ?"Vérification de candidature":"Application check",
          ],
    [width]
  );

  //states
  const [currenctTab, setCurrenctTab] = useState<string>("startup");

  //useEffects
  useEffect(() => {
    stepper.handleUpdateSteps(steps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    conformPathWithStep();
    confirmPathWithTabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  //functions
  const handleTabClick = (type: string) => {
    if (type === "startup") {
      navigate("/", { replace: true });
    } else if (type === "partner") {
      navigate("/partner/confirm-choice-partner", { replace: true });
    }
  };
  const conformPathWithStep = () => {
    if (pathName[0] === "user-form") {
      stepper.handleStep(0);
      stepper.handleUpdateComplete({ 0: false });
    } else if (
      pathName[0] === "form-startup" ||
      pathName[0] === "edit-startup"
    ) {
      stepper.handleUpdateComplete({ 0: true });
      stepper.handleStep(1);
    } else if (
      pathName[0] === "add-support" ||
      pathName[0] === "edit-support"
    ) {
      stepper.handleUpdateComplete({ 0: true, 1: true });
      stepper.handleStep(2);
    } else if (pathName[0] === "validation") {
      stepper.handleUpdateComplete({ 0: true, 1: true, 2: true });
      stepper.handleStep(3);
    }
  };
  const confirmPathWithTabs = () => {
    if (pathName.length === 0) {
      setCurrenctTab("startup");
    }
  };

  //renders
  return (
    <TabsContainer
      tabList={listTabs}
      currenct={currenctTab}
      tabClick={(type: string) => handleTabClick(type)}
    >
      {pathName.length < 1 ||
      pathName[0] === "confirm-choice" ||
      pathName[0] === "no-draft" ? (
        <Outlet />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          gap={2}
          mt={width < 490 ? 2 : 4}
        >
          <StepperList
            steps={steps}
            activeStep={stepper.activeStep}
            completed={stepper.completed}
            handleStep={(step: number) => stepper.handleStep(step)}
          />
          <Outlet />
        </Box>
      )}
    </TabsContainer>
  );
};

export default Startup;
