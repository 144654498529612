import {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
//Material Ui
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//components
import { ModalConfirmation } from "../../../../components";
import CGU from "../../../SharedPages/CGU";
//Styles
import {
  BottomText,
  ColoredText,
  Container,
  NormalText,
} from "./confirmChoise.styles";
import { useAuth } from "../../../../contextAPI";
import {LanguageContext} from "../../../../LanguageContext";

const ConfirmChoice = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  //stats
  const [open, setOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  //functions
  const handleCheck = () => {
    setIsChecked(true);
    setOpen(true);
  };
  const handleClose = () => {
    setIsChecked(false);
    setOpen(false);
  };
  const validate = () => {
    auth.handleCGUAcceptation(true);
    navigate("/user-form", { replace: true });
  };
  //renders
  const modalItem = () => {
    return <CGU />;
  };
  const { language } = useContext(LanguageContext);

  return (
    <>
      <Container
        maxHeight={width < 490 ? height - 290 : height - 420}
        minHeight={width < 490 ? height - 290 : height - 420}
        overflow="auto"
      >
        <NormalText
          mt={1}
          textAlign="center"
          sx={{ fontWeight: 700, color: "#EF4B12", fontSize: 24 }}
        >
          {language==="fr" ? "Bienvenue!": "Welcome !"}
        </NormalText>
        <NormalText mt={1} textAlign="center">
          {language==="fr" ? "Vous êtes sur le point de postuler à Fuzé by Digital Africa, un\n" +
              "          dispositif d’investissement, réparti en petits tickets, pour soutenir\n" +
              "          les startups aux stades de l'idéation et du MVP. Testez votre\n" +
              "          éligibilité dès maintenant en remplissant ce formulaire !": "You're about to apply for Fuzé by Digital Africa, an investment scheme, divided into small tickets, to support startups at the ideation and MVP stages. Test your eligibility now by filling in this form!"}

        </NormalText>
        <NormalText textAlign="center">
          {language==="fr" ? "Gardez à portée de main les documents officiels présentant les statuts\n" +
              "          de votre entreprise ainsi que tout document donnant une description de\n" +
              "          vos activités.": "Keep your company's articles of association and any other documents describing your activities close at hand."}


        </NormalText>
        <NormalText textAlign="center">
          {language==="fr" ? "Soyez précis et exhaustif dans vos réponses, afin de permettre une\n" +
              "          analyse précise de vos accomplissements et vos besoins.": "Please be precise and exhaustive in your answers, to enable a precise analysis of your achievements and needs."}


        </NormalText>
        <Box display="flex" alignItems="center">
          <FormGroup>
            <FormControlLabel
              disabled={open}
              control={
                <Checkbox
                  sx={{ color: "#EC6D3F" }}
                  color="primary"
                  checked={isChecked}
                  onChange={handleCheck}
                />
              }
              label={
                <ColoredText onClick={handleCheck}>
                  {language==="fr" ? "J'accepte les Conditions Générales d'Utilisation": "I accept the Terms of Use"}


                </ColoredText>
              }
            />
          </FormGroup>
        </Box>
        <ModalConfirmation
          isVisible={open}
          rightButtonText={language==="fr" ? "J'accepte": "I accept"}
          rightButtonAction={validate}
          children={modalItem()}
          onClickClose={handleClose}
        />
        <BottomText>{language==="fr" ? "Temps estimé de remplissage: 30 min": "Estimated filling time: 30 min"}</BottomText>
      </Container>
    </>
  );
};

export default ConfirmChoice;
