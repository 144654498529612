import axios from "axios";

const getPartners = async (collection: string, token: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/${collection}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};
const createPartner = async (params: { data: any; token: string }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/partner`,
      params.data,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};
const getPartnerById = async (params: {
  id: string;
  moreFields?: string;
  token: string;
}) => {
  const paramsFields = params.moreFields ? `?fields=*${params.moreFields}` : "";

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/partner/${params.id}${paramsFields}`,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return res;
  } catch (error: any) {
    throw new Error(error);
  }
};

const updatePartner = async (params: {
  id: string;
  data: any;
  token: string;
}) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/partner/${params.id}`,
      params.data,
      {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export { getPartners, createPartner, getPartnerById, updatePartner };
