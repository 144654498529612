import {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
//Material Ui
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//components
import { ModalConfirmation } from "../../../../components";
import CGU from "../../../SharedPages/CGU";
//Styles
import {
  BottomText,
  ColoredText,
  Container,
  NormalText,
} from "./confirmChoise.styles";
import { useAuth } from "../../../../contextAPI";
import {LanguageContext} from "../../../../LanguageContext";

const ConfirmChoice = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  //stats
  const [open, setOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  //functions
  const handleCheck = () => {
    setIsChecked(true);
    setOpen(true);
  };
  const handleClose = () => {
    setIsChecked(false);
    setOpen(false);
  };
  const validate = () => {
    auth.handleCGUAcceptation(true);
    navigate("/partner/add-partner", { replace: true });
  };
  //renders
  const modalItem = () => {
    return <CGU />;
  };
  const { language } = useContext(LanguageContext);

  return (
    <>
      <Container
        maxHeight={width < 490 ? height - 290 : height - 420}
        minHeight={width < 490 ? height - 290 : height - 420}
        overflow="auto"
      >
        <NormalText
          mt={1}
          textAlign="center"
          sx={{ fontWeight: 700, color: "#EF4B12", fontSize: 24 }}
        >
          {language==="fr" ? "Bienvenue!": "Welcome !"}
        </NormalText>
        <NormalText mt={1} textAlign="center">
          {language==="fr" ? "Vous êtes sur le point de postuler pour devenir un partenaire Fuzé, le\n" +
              "          dispositif d’investissement lancé par Digital Africa pour soutenir les\n" +
              "          startups aux stades de l'idéation et du MVP en Afrique.": "You are about to apply to become a Fuzé partner, the investment mechanism launched by Digital Africa to support startups at the ideation and MVP stages in Africa."}

        </NormalText>
        <Box display="flex" alignItems="center">
          <FormGroup>
            <FormControlLabel
              disabled={open}
              control={
                <Checkbox
                  sx={{ color: "#EC6D3F" }}
                  color="primary"
                  checked={isChecked}
                  onChange={handleCheck}
                />
              }
              label={
                <ColoredText onClick={handleCheck}>
                  {language==="fr" ? "J'accepte les Conditions Générales d'Utilisation": "I accept the Terms of Use"}
                </ColoredText>
              }
            />
          </FormGroup>
        </Box>
        <ModalConfirmation
          isVisible={open}
          rightButtonText={language==="fr" ? "J'accepte": "I accept"}
          rightButtonAction={validate}
          children={modalItem()}
          onClickClose={handleClose}
        />
        <BottomText>{language==="fr" ? "Temps estimé de remplissage: 30 min": "Estimated filling time: 30 min"}</BottomText>
      </Container>
    </>
  );
};

export default ConfirmChoice;
