function StartupLocation() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      fill="none"
      viewBox="0 0 31 30"
    >
      <path
        fill="#EF4B12"
        d="M15.063 17.712c-2.674 0-4.858-2.162-4.858-4.837S12.39 8.05 15.063 8.05c2.673 0 4.858 2.162 4.858 4.837s-2.184 4.825-4.858 4.825zm0-7.787c-1.632 0-2.975 1.325-2.975 2.962a2.967 2.967 0 002.975 2.963 2.967 2.967 0 002.975-2.963c0-1.637-1.343-2.962-2.975-2.962z"
      ></path>
      <path
        fill="#EF4B12"
        d="M15.063 28.45c-1.858 0-3.728-.7-5.184-2.087-3.703-3.55-7.795-9.213-6.251-15.95C5.02 4.3 10.38 1.563 15.063 1.563h.012c4.682 0 10.042 2.737 11.436 8.862 1.53 6.738-2.561 12.387-6.264 15.938a7.511 7.511 0 01-5.184 2.087zm0-25.012c-3.653 0-8.347 1.937-9.59 7.387C4.117 16.712 7.833 21.788 11.197 25a5.572 5.572 0 007.745 0c3.35-3.212 7.066-8.288 5.736-14.175-1.255-5.45-5.963-7.387-9.615-7.387z"
      ></path>
    </svg>
  );
}

export default StartupLocation;
