function StartupTech() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      fill="none"
      viewBox="0 0 31 30"
    >
      <path
        fill="#EF4B12"
        d="M18.828 28.438h-7.53c-6.817 0-9.729-2.9-9.729-9.688v-7.5c0-6.787 2.912-9.688 9.728-9.688h7.531c6.816 0 9.729 2.9 9.729 9.688v7.5c0 6.788-2.913 9.688-9.729 9.688zm-7.53-25c-5.787 0-7.846 2.05-7.846 7.812v7.5c0 5.762 2.059 7.813 7.845 7.813h7.531c5.787 0 7.846-2.05 7.846-7.813v-7.5c0-5.763-2.059-7.813-7.846-7.813h-7.53z"
      ></path>
      <path
        fill="#EF4B12"
        d="M12.05 19.725a.933.933 0 01-.665-.275l-3.126-3.113a1.873 1.873 0 010-2.662l3.126-3.113a.95.95 0 011.33 0 .94.94 0 010 1.325L9.59 15l3.126 3.125a.94.94 0 010 1.325.988.988 0 01-.666.275zM18.075 19.725a.933.933 0 01-.665-.275.94.94 0 010-1.325L20.536 15l-3.126-3.125a.94.94 0 010-1.325.95.95 0 011.33 0l3.126 3.112c.74.738.74 1.925 0 2.663l-3.125 3.113a.925.925 0 01-.666.287z"
      ></path>
    </svg>
  );
}

export default StartupTech;
