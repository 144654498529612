import {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import _ from "lodash";
//Material Ui
import { Box, Typography } from "@mui/material";
//ContextAPI
import {
  FormHandlerProvider,
  useAuth,
  useStepperContext,
} from "../../../../contextAPI";
//Hooks
import { useWindowDimensions } from "../../../../hooks";
//Helpers
import {
  getMonthDifference,
  Partner,
  SnackbarHolder,
  sortFieldsArray,
} from "../../../../helpers";
//Services
import {
  createFounder,
  createStartup,
  getFields,
  getStartupById,
  getUserMe,
  startupTrackPage,
  updateStartup,
  uploadStartupFile,
} from "../../../../services";
//components
import { Button, DropzoneAvatar, Form, Input } from "../../../../components";
import DisplayFormInputs from "../../../SharedPages/DisplayFormInputs";
//Styles
import { Container, Labeltext } from "./formStartup.styles";
//Types
import { ParamsProps } from "./formStartup.types";

import Loader from "../../../../components/Loader/loader";
import {LanguageContext} from "../../../../LanguageContext";

const exeptionFields = [
  "id",
  "status",
  "logo",
  "user_created",
  "user_updated",
  "date_updated",
  "date_created",
  "accompaniment",
  "other_partner_name",
  "contact_ref",
  "other_partner_email",
  "partner",
  "age",
];

type FormStartupProps = {
  firstAcces?: boolean;
}

const FormStartup = ({ firstAcces }: FormStartupProps) => {
  const auth = useAuth();
  const location = useLocation();
  const stepper = useStepperContext();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const params: ParamsProps = useParams();
  //states
  const [paramsFields, setParamsFields] = useState<string>("");
  const [startupAge, setStartupAge] = useState<number>(0);
  const [startupID, setStartupID] = useState<string | null>(null);
  const methods = useForm();

  const dataWatch = useWatch({ control: methods.control });

  //queries & mutations
  const startupFields = useQuery(
    ["fields", params.id, auth.token],
    () => {
      if (!auth.token) return;
      return getFields("startup", auth.token);
    },
    {
      enabled: auth.token ? true : false,
      onSuccess(fields) {
        if (!params.id) {
          let arrayOfMultiFiles = fields?.data?.data.filter(
            (field: any) => field.meta.interface === "files"
          );
          arrayOfMultiFiles.forEach((element: any) => {
            methods.setValue(`${element.field}`, []);
          });
          return;
        }
        let arrayRepeater = fields?.data?.data.filter(
          (field: any) => field.meta.interface === "list-m2m"
        );
        let str = "";
        arrayRepeater.forEach((element: any) => {
          str =
            str + `,${element.field}.*,${element.field}.${element.field}_id.*`;
        });

        let arrayOfMultiFiles = fields?.data?.data.filter(
          (field: any) => field.meta.interface === "files"
        );

        arrayOfMultiFiles.forEach((element: any) => {
          str =
            str + `,${element.field}.*,${element.field}.directus_files_id.*`;
        });
        let arrayOfFiles = fields?.data?.data.filter(
          (field: any) => field.meta.interface === "file"
        );
        arrayOfFiles.forEach((element: any) => {
          if (!exeptionFields.includes(element.field)) {
            str = str + `,${element.field}.*`;
          }
        });
        setParamsFields(str);
      },
    }
  );
  const getUser = useQuery(
    ["user", auth.token, auth.token],
    () => {
      if (!auth.token) return;
      return getUserMe({ token: auth.token });
    },
    {
      enabled: startupFields.isFetched && startupFields.isSuccess,
      onSuccess(data) {
        let arrayRepeater = startupFields?.data?.data.data.filter(
          (field: any) =>
            field.meta.interface === "list-m2m" && field.field === "founder"
        );

        const coFounderInit = [
          {
            gender: data?.data.data.gender,
            nationality: data?.data.data.nationality,
            first_name: data?.data.data.first_name,
            last_name: data?.data.data.last_name,
          },
        ];
        let dataInit = dataWatch;
        arrayRepeater.forEach((element: any) => {
          dataInit[element.field] = coFounderInit;
        });
        methods.reset(dataInit);
      },
    }
  );

  const dataStartup = useQuery(
    ["startup-edit", params.id, paramsFields],
    () => {
      if (!params.id || !auth.token) {
        return;
      }

      if (paramsFields === "") return;
      return getStartupById({
        id: params.id,
        moreFields: paramsFields,
        token: auth.token,
      });
    },
    {
      staleTime: Infinity,
      enabled:
        startupFields.isFetched &&
        startupFields.isSuccess &&
        getUser.isFetched &&
        getUser.isSuccess,
    }
  );
  const startupCreation = useMutation(createStartup);
  const founderCreation = useMutation(createFounder);
  const fileUploading = useMutation(uploadStartupFile);
  const startupUpdate = useMutation(updateStartup);
  const trackPage = useMutation(startupTrackPage);
  const { language } = useContext(LanguageContext);

  //memos
  const user = useMemo(() => getUser?.data?.data?.data, [getUser]);
  const startup = useMemo(() => dataStartup?.data?.data?.data, [dataStartup]);
  const fields = useMemo(
    () => startupFields?.data?.data?.data.sort(sortFieldsArray),
    [startupFields]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deviceHeight = useMemo(() => _.clone(height), []);

  //useEffects   
  useEffect(() => {
    if (startup) {
      handleDataToDisplay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startup]);

  useEffect(() => {
    dataStartup.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFields]);
  useEffect(() => {
    methods.clearErrors();
    handleStartupAge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataWatch]);
  useEffect(() => {
    if (user && user.role === Partner) {
      navigate("/partner/form-company", { replace: true });
    }
    if (params.id) {
      setStartupID(params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //functions
  const handleStartupAge = () => {
    const dateCreation = methods.getValues("creation_date");
    const age = getMonthDifference(new Date(dateCreation), new Date());
    if (isNaN(age)) return;
    setStartupAge(age);
  };

  const onFailSubmit = (data: any) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (key !== "undefined")
        SnackbarHolder.alert("error",
      language === "fr" ? "Des champs obligatoires non remplis":"Mandatory fields not filled in");
    });
  };

  const onSubmit = async (data: any) => {

    if (data.company_incorporated=='true' && !data.kbis){
      SnackbarHolder.alert("error", "kbis obligatoires");
    }else{
      const dataToSend = await handleDataToSend(data);
      if (auth.token && startupID) {
        try {
          const response = await startupUpdate.mutateAsync({
            data: dataToSend,
            id: startupID,
            token: auth.token,
          });
          if (response.status === 200) {
            try {
              const toUpdate = await dataToSendWithFounderAndMultiFiles(
                  response.data.data.id,
                  dataToSend
              );
              try {
                const res = await startupUpdate.mutateAsync({
                  data: toUpdate,
                  id: startupID,
                  token: auth.token,
                });
                if (res?.status === 200 || res?.status === 403) {
                  try {
                    const saveResponse = await trackPage.mutateAsync({
                      data: {
                        last_page: `/add-support/${startupID}`,
                      },
                      token: auth.token,
                    });
                    if (saveResponse.status === 204) {
                      SnackbarHolder.alert(
                          "success",
                          language === "fr" ? "Données mis à jour avec succés":"Data updated successfully"

                      );
                      stepper.handleComplete();
                      navigate(`/add-support/${startupID}`, {
                        replace: true,
                      });
                    }
                  } catch (error: any) {
                    throw new Error(error);
                  }
                } else {
                  SnackbarHolder.alert("error", response.data.errors[0].message);
                }
              } catch (error: any) {
                throw new Error(error);
              }
            } catch (error: any) {
              throw new Error(error);
            }
          } else {
            SnackbarHolder.alert("error", response.data.errors[0].message);
            response.data.errors.forEach((error: any) => {
              methods.setError(
                  error.extensions.field,
                  {
                    type: "custom",
                    message: error.message,
                  },
                  { shouldFocus: true }
              );
            });
          }
        } catch (error: any) {
          SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
        }
      } else if (auth.token && !startupID) {
        try {
          const response = await startupCreation.mutateAsync({
            data: dataToSend,
            token: auth.token,
          });
          if (response.status === 200) {
            setStartupID(response.data.data.id);
            window.history.replaceState(
                null,
                "",
                `/edit-startup/${response.data.data.id}`
            );
            try {
              const toUpdate = await dataToSendWithFounderAndMultiFiles(
                  response.data.data.id,
                  dataToSend
              );
              try {
                const res = await startupUpdate.mutateAsync({
                  data: toUpdate,
                  id: response.data.data.id,
                  token: auth.token,
                });
                if (res?.status === 200) {
                  try {
                    const saveResponse = await trackPage.mutateAsync({
                      data: {
                        last_page: `/add-support/${response.data.data.id}`,
                      },
                      token: auth.token,
                    });
                    if (saveResponse.status === 204) {
                      SnackbarHolder.alert("success", language === "fr" ? "Données sauvegardées avec succès":"Data saved successfully");
                      stepper.handleComplete();
                      navigate(`/add-support/${response.data.data.id}`, {
                        replace: true,
                      });
                    }
                  } catch (error: any) {
                    throw new Error(error);
                  }
                } else {
                  SnackbarHolder.alert("error", response.data.errors[0].message);
                }
              } catch (error: any) {
                throw new Error(error);
              }
            } catch (error: any) {
              throw new Error(error);
            }
          } else {
            SnackbarHolder.alert("error", response.data.errors[0].message);
            response.data.errors.forEach((error: any) => {
              methods.setError(
                  error.extensions.field,
                  {
                    type: "custom",
                    message: error.message,
                  },
                  { shouldFocus: true }
              );
            });
          }
        } catch (error: any) {
          SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
        }
      }
    }

  };
  const handleDraft = async () => {
    const dataToSend = await handleDataToSend(dataWatch);
    if (auth.token && startupID) {
      try {
        const response = await startupUpdate.mutateAsync({
          data: dataToSend,
          id: startupID,
          token: auth.token,
        });
        if (response.status === 200) {
          try {
            const toUpdate = await dataToSendWithFounderAndMultiFiles(
              response.data.data.id,
              dataToSend
            );
          if (toUpdate.requested_documents_by_analyst && toUpdate.requested_documents_by_analyst.length===0){
            delete toUpdate['requested_documents_by_analyst']
          }
          if (toUpdate.other_files && toUpdate.other_files.length===0){
            delete toUpdate['other_files']
          }
            try {
              const res = await startupUpdate.mutateAsync({
                data: toUpdate,
                id: startupID,
                token: auth.token,
              });

              if (res?.status === 200) {
                try {
                  await trackPage.mutateAsync({
                    data: {
                      last_page: `/edit-startup/${startupID}`,
                    },
                    token: auth.token,
                  });
                  SnackbarHolder.alert(
                    "success",
                      language === "fr" ? "Données sauvegardées avec succès":"Data saved successfully"
                  );
                } catch (error: any) {
                  throw new Error(error);
                }
              } else {
                SnackbarHolder.alert("error", response.data.errors[0].message);
              }
            } catch (error: any) {
              throw new Error(error);
            }
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          SnackbarHolder.alert("error", response.data.errors[0].message);
          response.data.errors.forEach((error: any) => {
            methods.setError(
              error.extensions.field,
              {
                type: "custom",
                message: error.message,
              },
              { shouldFocus: true }
            );
          });
        }
      } catch (error: any) {
        SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
      }
    } else if (auth.token && !startupID) {
      try {
        const response = await startupCreation.mutateAsync({
          data: dataToSend,
          token: auth.token,
        });
        if (response.status === 200) {
          setStartupID(response.data.data.id);
          window.history.replaceState(
            null,
            "",
            `/edit-startup/${response.data.data.id}`
          );
          try {
            const toUpdate = await dataToSendWithFounderAndMultiFiles(
              response.data.data.id,
              dataToSend
            );
            try {
              const res = await startupUpdate.mutateAsync({
                data: toUpdate,
                id: response.data.data.id,
                token: auth.token,
              });
              if (res?.status === 200) {
                try {
                  await trackPage.mutateAsync({
                    data: {
                      last_page: `/edit-startup/${response.data.data.id}`,
                    },
                    token: auth.token,
                  });
                  SnackbarHolder.alert(
                    "success",
                      language === "fr" ? "Données sauvegardées avec succès":"Data saved successfully"
                  );
                } catch (error: any) {
                  throw new Error(error);
                }
              } else {
                SnackbarHolder.alert("error", response.data.errors[0].message);
              }
            } catch (error: any) {
              throw new Error(error);
            }
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          SnackbarHolder.alert("error", response.data.errors[0].message);
          response.data.errors.forEach((error: any) => {
            methods.setError(
              error.extensions.field,
              {
                type: "custom",
                message: error.message,
              },
              { shouldFocus: true }
            );
          });
        }
      } catch (error: any) {
        SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred" );
      }
    }
  };
  const handleDataToDisplay = () => {
    let initData = _.cloneDeep(dataStartup?.data?.data?.data);

    //display numbers
    if (startup) {
      for (const [key, value] of Object.entries(
        dataStartup?.data?.data?.data
      )) {
        if (typeof value === "number") {
          initData[key] = value.toString();
        }
      }
    }
    //display multiselect
    let arraySelectMultiple = fields.filter(
      (field: any) =>
        field.meta.interface === "select-multiple-checkbox" ||
        field.meta.interface === "select-multiple-dropdown"
    );
    if (startup) {
      arraySelectMultiple.forEach((element: any) => {
        const arrayString = dataStartup?.data?.data?.data[element.field];
        let arrayObject: any[] = [];
        arrayString.forEach((el: any) => {
          arrayObject.push({ text: el, value: el });
        });
        initData[element.field] = arrayObject;
      });
    }
    //display repeater data
    let arrayRepeater = startupFields?.data?.data.data.filter(
      (field: any) => field.meta.interface === "list-m2m"
    );
    if (startup) {
      for (const element of arrayRepeater) {
        const toUpdate = dataStartup?.data?.data?.data[element.field];
        const attribute = `${element.field}_id`;
        let arrayRep: any[] = [];
        toUpdate.forEach((el: any) => {
          let finalObj: any = {};
          if (el[attribute]) {
            for (var [key, value] of Object.entries(el[attribute])) {
              if (!exeptionFields.includes(key)) {
                finalObj[key] = value;
              }
            }
          }
          arrayRep.push(finalObj);
          initData[element.field] = arrayRep;
        });
      }
    }

    //diplay multi file
    let arrayOfMultiFiles = fields.filter(
      (field: any) => field.meta.interface === "files"
    );
    arrayOfMultiFiles.forEach((element: any) => {
      if (startup) {
        let arrayFiles: any[] = [];
        if (startup[element.field]) {
          dataStartup?.data?.data?.data[element.field].forEach((el: any) => {
            arrayFiles.push({
              startup_id: el.startup_id,
              directus_files_id: el.directus_files_id.id,
              name: el.directus_files_id.title,
            });
          });
        }
        initData[element.field] = arrayFiles;
      }
    });

    //display booleans
    let arrayOfBoolean = startupFields?.data?.data.data.filter(
      (field: any) => field.meta.interface === "boolean"
    );
    arrayOfBoolean.forEach((element: any) => {
      initData[element.field] = startup[element.field] ? "true" : "false";
    });

    //display files
    let arrayOfFiles = startupFields?.data?.data.data.filter(
      (field: any) => field.meta.interface === "file"
    );
    if (arrayOfFiles.length > 0) {
      arrayOfFiles.forEach((element: any) => {
        let objectFile = {};
        if (startup) {
          if (startupFields?.data?.data.data[element.field] != null) {
            if (!exeptionFields.includes(element.field)) {
              objectFile = {
                id: startup[element.field].id,
                name: startup[element.field].title,
                type: startup[element.field].type,
              };
            }
            initData[element.field] = objectFile;
          }
        }
      });
    }

    methods.reset(initData);
  };

  const dataToSendWithFounderAndMultiFiles = async (id: string, data: any) => {
    //multifiles to send
    let arrayOfMultiFiles = fields.filter(
      (field: any) => field.meta.interface === "files"
    );

    for (const field of arrayOfMultiFiles) {
      let arrayOfIdImages: any[] = [];
      const filesArraytoSend = dataWatch[field.field];
      if (dataWatch[field.field]) {
        for (const file of filesArraytoSend) {
          if (file.startup_id) {
            const objectToSave = {
              startup_id: file.startup_id,
              directus_files_id: file.directus_files_id,
            };
            arrayOfIdImages.push(objectToSave);
          } else {
            try {
              const res = await handleUploadFiles(file);
              if (res?.status === 200) {
                arrayOfIdImages.push({
                  directus_files_id: res.data.data.id,
                  startup_id: id,
                });
              }
            } catch (error) { }
          }
        }
        data[field.field] = arrayOfIdImages;
      }
    }
    //repeater -- founder
    let arrayRepeater = fields.filter(
      (field: any) =>
        field.meta.interface === "list-m2m" && field.field === "founder"
    );
    for (const rep of arrayRepeater) {
      try {
        let arrayElement: any[] = [];
        if (dataWatch[rep.field] != null) {
          for (const element of dataWatch[rep.field]) {
            const res = await handleCreaterFounder(element);
            if (res?.status === 200) {
              arrayElement.push({
                [`${rep.field}_id`]: res.data.data.id,
                startup_id: id,
              });
            }
          }
        }

        data[rep.field] = arrayElement;
      } catch (error) { }
    }

    return data;
  };

  const handleDataToSend = async (data: any) => {
    let dataToSend = _.cloneDeep(dataWatch);

    //files to send
    let arrayOfFiles = fields.filter(
      (field: any) =>
        field.meta.interface === "file" || field.meta.interface === "file-image"
    );
    for (const file of arrayOfFiles) {
      if (data[file.field] != null) {
        if (data[file.field].id) {
          dataToSend[file.field] = data[file.field].id;
        } else if (typeof data[file.field] === "object") {
          const res = await handleUploadFiles(data[file.field]);
          if (res?.status === 200) {
            dataToSend[file.field] = res.data.data.id;
          }
        }
      }
    }

    //multiselect to send
    let arraySelectMultiple = fields.filter(
      (field: any) =>
        field.meta.interface === "select-multiple-checkbox" ||
        field.meta.interface === "select-multiple-dropdown"
    );
    arraySelectMultiple.forEach((element: any) => {
      let arrayOfString: any[] = [];
      if (dataWatch[element.field] != null) {
        dataWatch[element.field].forEach((el: any) => {
          if (typeof el === "string") arrayOfString.push(el);
          else {
            arrayOfString.push(el.text);
          }
        });
      }
      dataToSend[element.field] = arrayOfString;
    });

    //boolean to send
    let arrayOfBoolean = fields.filter(
      (field: any) => field.meta.interface === "boolean"
    );
    for (const booleanVar of arrayOfBoolean) {
      if (dataWatch[booleanVar.field] != null) {
        dataToSend[booleanVar.field] =
          dataWatch[booleanVar.field] === "true" ? true : false;
      }
    }

    //remove multifiles from object to send
    let arrayOfMultiFiles = fields.filter(
      (field: any) => field.meta.interface === "files"
    );
    arrayOfMultiFiles.forEach((element: any) => {
      delete dataToSend[element.field];
    });

    //remove repeaters from object to send
    let arrayRepeater = startupFields?.data?.data.data.filter(
      (field: any) => field.meta.interface === "list-m2m"
    );
    arrayRepeater.forEach((element: any) => {
      delete dataToSend[element.field];
    });

    //age
    dataToSend.age = startupAge;

    //numbers
    let arrayOfNumbers = fields.filter(
      (field: any) =>
        field.meta.interface === "input" &&
        ["integer", "decimal", "bigInteger", "float"].includes(field.type)
    );

    arrayOfNumbers.forEach((element: any) => {
      if (data[element.field] === "") delete dataToSend[element.field];
    });

    delete dataToSend["status"];
    delete dataToSend["id"];
    delete dataToSend["give_permission_sending_documents_to_analyst"];
    delete dataToSend["requested_documents_description"];
    delete dataToSend["confirm_requested_documents_by_analyst"];
    return dataToSend;
  };

  const handleHideFields = (conditions: any, field: any) => {
    if (conditions == null) return true;

    if (conditions[0] != null) {
      let fieldToChekForCondition: any;
      if (
        conditions[0]?.rule &&
        conditions[0]?.rule._and &&
        conditions[0]?.rule._and[0] &&
        conditions[0]?.rule._and[0]?._or &&
        conditions[0]?.rule._and[0]?._or[0] &&
        conditions[0] &&
        conditions
      ) {
        for (var property in conditions[0]?.rule._and[0]?._or[0]) {
          fieldToChekForCondition = property;
        }
      }
      if (dataWatch[fieldToChekForCondition] != null) {
        if (dataWatch[fieldToChekForCondition] === "false") {
          delete dataWatch[field];
          return false;
        } else if (dataWatch[fieldToChekForCondition] === "true") {
          return true;
        }
      }
      if (
        conditions[0]?.rule._and[0]?.company_legal_form?._eq ===
        dataWatch.company_legal_form
      ) {
        return true;
      }
    }
  };
  const handleUploadFiles = async (file: any) => {
    if (!auth.token) return;
    try {
      return await fileUploading.mutateAsync({ file: file, token: auth.token });
    } catch (error: any) {
      throw new Error(error);
    }
  };
  const handleCreaterFounder = async (founder: any) => {
    if (!auth.token) return;
    try {
      return await founderCreation.mutateAsync({
        data: founder,
        token: auth.token,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  };

  //renders
  if (auth.token && !params.id && getUser.isFetched && user.last_page) {
    return <Navigate to={user.last_page} state={{ from: location }} replace />;
  }
  const renderGroupItems = (partnerField: any) => {
    const group = partnerField.field;
    let arrayOfGroupInputs = fields.filter(
      (field: any) => field.meta.group === group
    );
    return (
      <>
        {partnerField.type === "alias" &&
          partnerField.field !== "upporting_documents_if_you_have_any" &&
          partnerField.field !== "founder" &&
          partnerField.field !== "Partenaire" && (
            <>
              {arrayOfGroupInputs.map(
                (startupField: any, index: number) =>
                  !exeptionFields.includes(startupField.field) && (
                    <Fragment key={index}>
                      {handleHideFields(
                        startupField.meta.conditions,
                        startupField.field
                      ) &&
                        startupField.meta.group === group && (
                          <DisplayFormInputs field={startupField} />
                        )}
                      {startupField.field === "line_of_business_other" &&
                        renderOtherTag(startupField)}
                    </Fragment>
                  )
              )}
            </>
          )}
      </>
    );
  };
  const renderOtherTag = (otherField: any) => {
    let arraySelectMultiple = fields.filter(
      (field: any) =>
        field.meta.interface === "select-multiple-checkbox" ||
        field.meta.interface === "select-multiple-dropdown"
    );
    // eslint-disable-next-line array-callback-return
    return arraySelectMultiple.map((element: any) => {
      let arrayOfString: any[] = [];
      if (dataWatch[element.field] != null) {
        dataWatch[element.field].forEach((el: any) => {
          if (typeof el === "string") arrayOfString.push(el);
          else {
            arrayOfString.push(el.text);
          }
        });
        if (arrayOfString.includes("Autre")) {
          let label = otherField.meta.note
          if (language === "fr"){
            label= otherField.meta.note
          }else if (otherField.meta.note && otherField.meta?.translations){
            otherField.meta?.translations.map((trans:any)=>{
              if (trans['language']==='en-US'){
                label= trans.translation
              }
            })

          }
          return (
            <Input
              minWidth={360}
              {...methods.register(otherField.field, {
                maxLength: 25,
                minLength: 2,
                required:
                  otherField.meta.required || otherField.meta.conditions != null
                    ? (language === "fr" ?"Ce champ est requis.":"This field is required.")
                    : false,
              })}
              label={
                <Box display="flex">
                  <Labeltext color="rgba(43, 45, 50, 0.64)">
                    {label}
                  </Labeltext>
                  {otherField.meta.required && (
                    <Labeltext color="#EF4B12">*</Labeltext>
                  )}
                </Box>
              }
            />
          );
        }
      }
    });
  };
  return (
    !firstAcces && !dataWatch.id ? <Loader /> :
      <FormHandlerProvider
        loading={
          startupFields.isLoading || dataStartup.isLoading || getUser.isLoading
        }
      >
        <FormProvider {...methods}>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Container
                minHeight={width < 490 ? deviceHeight - 400 : height - 420}
                maxHeight={width < 490 ? deviceHeight - 400 : height - 420}
              >
                <Typography
                  fontWeight={700}
                  fontSize={16}
                  color="#616161"
                  fontFamily="Mont"
                  pb={2}
                >
                  {language === "fr" ?"Description de la Startup":"Startup description"}

                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  mb={1}
                >
                  <DropzoneAvatar
                    name="logo"
                    width={width < 490 ? 70 : 100}
                    height={width < 490 ? 70 : 100}
                  />
                  <Box display="flex" flexDirection="column">
                    <Typography
                      // fontFamily="Mont"
                      fontWeight={600}
                      fontSize={14}
                      color="#616161"
                    >
                      {language === "fr" ?"Ajoutez le logotype":"Add logo"}

                    </Typography>
                    <Typography
                      // fontFamily="Mont"
                      fontWeight={400}
                      fontSize={12}
                      color="#616161"
                    >
                      {language === "fr" ?"Format recommandé PNG/JPEG 256*256":"Recommended format PNG/JPEG 256*256"}

                    </Typography>
                  </Box>
                </Box>
                {fields &&
                  fields.map(
                    (startupField: any, index: number) =>
                      !exeptionFields.includes(startupField.field) && (
                        <Fragment key={index}>
                          {handleHideFields(
                            startupField.meta.conditions,
                            startupField.field
                          ) &&
                            startupField.meta.group === null && (
                              <DisplayFormInputs field={startupField} />
                            )}
                          {startupField.type === "alias" &&
                            handleHideFields(
                              startupField.meta.conditions,
                              startupField.field
                            ) &&
                            renderGroupItems(startupField)}
                        </Fragment>
                      )
                  )}
              </Container>
            </Form>
            <Box display="flex" justifyContent="flex-end" mt={2} width="90%">
              <Box display="flex" gap={1}>
                <Button
                  variant="contained"
                  boxshadow="0px 5px 5px rgba(172, 172, 172, 0.2)"
                  width={width < 490 ? 110 : 190}
                  loading={
                    trackPage.isLoading ||
                    startupCreation.isLoading ||
                    founderCreation.isLoading ||
                    startupUpdate.isLoading ||
                    fileUploading.isLoading
                  }
                  onClick={handleDraft}
                  sx={{ backgroundColor: "#E0E0E7", color: "#000000" }}
                >

                  {language === "fr" ?"Sauvegarder":"Save"}
                </Button>


                <Button
                  variant="contained"
                  color="primary"
                  boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
                  width={width < 490 ? 110 : 190}
                  loading={
                    trackPage.isLoading ||
                    startupCreation.isLoading ||
                    founderCreation.isLoading ||
                    startupUpdate.isLoading ||
                    fileUploading.isLoading
                  }
                  onClick={methods.handleSubmit(onSubmit, onFailSubmit)}
                >
                  {language === "fr" ?"Suivant":"Next"}
                </Button>
              </Box>
            </Box>
          </Box>
        </FormProvider>
      </FormHandlerProvider>

  );
};

export default FormStartup;
