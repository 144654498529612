import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import theme from "../../../../theme";

export const Container = styled(Box)<BoxProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: ${theme.spacing(6)};
  padding: ${theme.spacing(10)};
  @media screen and (max-width: 1060px) {
    padding: ${theme.spacing(2)};
    gap: ${theme.spacing(3)};
  }
`;
export const NormalText = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: "center",
  fontSize: 16,
  color: "#616161",
  fontFamily: "Mont",
  fontWeight: 300,
}));
export const ColoredText = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: "center",
  fontSize: 14,
  color: "#EF4B12",
  fontWeight: 600,
  fontFamily: "Mont",
  cursor: "pointer",
}));

export const LinkText = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
}));
export const BottomText = styled(Typography)<TypographyProps>`
  width: 100%;
  bottom: 70px;
  right: 120px;
  text-align: end;
  font-size: 12px;
  color: #616161;
  font-family: "Mont";
  font-weight: 300;
`;