import { FC } from "react";
//Material UI
import Box from "@mui/material/Box";
//Components
import { Image } from "../Image";
//Styles
import { Container, Message, Title } from "./errorComponent.styles";
//Types
import { ErrorComponentProps } from "./errorComponent.types";

const ErrorComponent: FC<ErrorComponentProps> = (props) => {
  const { image, sizeImage, title, message } = props;
  return (
    <Container>
      <Image src={image} width={sizeImage || 300} height={sizeImage || 300} />
      <Box p={4}>
        <Title variant="body1">{title}</Title>
        <Message mt={2} variant="body1">
          {message}
        </Message>
      </Box>
    </Container>
  );
};

export { ErrorComponent };
