import { Controller, useFormContext } from "react-hook-form";
//Material UI
import Radio from "@mui/material/Radio";
import Group from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
//ContextAPI
import { useFormHandlerContext } from "../../../contextAPI";
//Types
import { RadioGroupProps } from "./radioGroup.types";

function RadioGroup<T>(props: RadioGroupProps<T>): JSX.Element {
  const {
    id,
    name,
    label,
    data,
    margin,
    fullWidth,
    rules,
    getOptionValue,
    getOptionLabel,
  } = props;
  const { control } = useFormContext();
  const dataForm = useFormHandlerContext();
  //render
  const renderItem = () => {
    return data.map((item, index) => {
      return (
        <FormControlLabel
          key={index}
          value={getOptionValue(item)}
          control={<Radio />}
          label={getOptionLabel(item)}
        />
      );
    });
  };
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, value, ...rest },
        fieldState: { error },
      }) => (
        <FormControl
          style={{ margin }}
          disabled={dataForm?.loading}
          fullWidth={typeof fullWidth === "boolean" ? fullWidth : true}
        >
          <FormLabel id={id}>{label}</FormLabel>
          <Group
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={value == null || value === undefined ? null : value}
            onChange={onChange}
          >
            {renderItem()}
          </Group>
          <FormHelperText error={error?.message !== null}>
            {error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
export { RadioGroup };
