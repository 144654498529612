import { Box, BoxProps, styled } from "@mui/material";
import theme from "../../theme";

export const Container = styled(Box)<BoxProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${theme.spacing(2, 10, 10, 10)};
  height: 100vh;
  @media screen and (max-width: 490px) {
    padding: ${theme.spacing(2, 2, 10, 2)};
  }
`;
export const TabsHeader = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flex: 1,
  minHeight: "50px",
  maxHeight: "50px",
}));
export const Tabs = styled(Box)<BoxProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #fff;
  padding: ${theme.spacing(0, 3)};
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  font-size: 20px;
  font-weight: 800;
  font-family: "Mont";
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const Content = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flex: 1,
  backgroundColor: "#fff",
  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.05)",
  borderBottomRightRadius: "20px",
  borderBottomLeftRadius: "20px",
  padding: theme.spacing(2, 4),
}));
