import {useContext, useEffect, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import Validator from "validator";
//Material UI
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Typography } from "@mui/material";
//contextAPI
import { useAuth } from "../../../contextAPI";
//assets
import { Images } from "../../../assets";
//hooks
import { useWindowDimensions } from "../../../hooks";
//helpers
import { Partner, SnackbarHolder, Startup } from "../../../helpers";
//services
import { getPartners, getStartups, getUserMe, signIn } from "../../../services";
//Components
import { Form, Image, Button, Input } from "../../../components/Commons";
//Styles
import { Container, FormContainer } from "./signIn.styles";
//Types
import { SignInState } from "./signIn.types";
import {LanguageContext} from "../../../LanguageContext";

const SignIn = () => {
  const { language } = useContext(LanguageContext);

  const auth = useAuth();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  //states
  const methods = useForm<SignInState>({
    defaultValues: {
      email: "",
      password: "",
      showPassword: false,
    },
  });
  const dataWatch = useWatch({ control: methods.control });
  //useEffects
  useEffect(() => {
    methods.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataWatch]);

  //memos
  const boxWidth = useMemo(() => (width < 500 ? 280 : 400), [width]);
  const fontSize = useMemo(() => (width < 490 ? 12 : 16), [width]);
  //queries & mutations
  const login = useMutation(signIn);
  //functions
  const onClickShowPassword = () => {
    methods.setValue("showPassword", !dataWatch.showPassword);
  };
  const onSubmit: SubmitHandler<SignInState> = async (data) => {
    try {
      const res = await login.mutateAsync({
        email: data.email,
        password: data.password,
      });
      if (res?.status === 200) {
        try {
          const getUser = await getUserMe({
            token: res.data.data.access_token,
          });
          if (getUser.status === 200) {
            auth.signIn({
              token: res.data.data.access_token,
              refreshToken: res.data.data.refresh_token,
              role: getUser.data.data.role,
            });
            if (getUser.data.data.role === Partner) {
              try {
                const mePartner = await getPartners(
                  "partner",
                  res.data.data.access_token
                );
                if (mePartner.status === 200) {
                  if (mePartner.data.data.length > 0) {
                    if (mePartner.data.data[0].status !== "En attente") {
                      if (mePartner.data.data[0].status === "Approuvé") {
                        auth.signOut(`${process.env.REACT_APP_BACK_OFFICE}`);
                      } else if (mePartner.data.data[0].status === "Refusé") {
                        auth.signOut();
                      }
                    } else {
                      navigate("/partner/form-company", {
                        replace: true,
                      });
                    }
                  }
                }
              } catch (error) {
                console.log(error);
              }
            }
            if (getUser.data.data.role === Startup) {
              try {
                const myStartup = await getStartups({
                  token: res.data.data.access_token,
                });

                if (myStartup.status === 200) {
                  if (myStartup.data.data.length > 0) {
                    navigate(`/no-draft/${myStartup.data.data[0].id}`, {
                      replace: true,
                    });
                  } else {
                    navigate("/form-startup", {
                      replace: true,
                    });
                  }
                }
              } catch (error) {
                console.log(error);
              }
            }
          }
        } catch (error: any) {
          throw new Error(error);
        }
      } else {
        res.data.errors.forEach((error: any) => {
          methods.setError(
            "email",
            {
              type: "custom",
              message: error.message,
            },
            { shouldFocus: true }
          );
          methods.setError(
            "password",
            {
              type: "custom",
              message: error.message,
            },
            { shouldFocus: true }
          );
        });
      }
    } catch (error: any) {
      SnackbarHolder.alert("error", error);
    }
  };
  const handleForgetPassword = () => {
    navigate("/forget-password", { replace: true });
  };

  //render
  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container>
          <FormContainer
            elevation={0}
            sx={{ maxHeight: height - 250, minHeight: height - 260 }}
          >
            {width < 500 ? <></> : <Image src={Images.SignIn} width={100} />}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={boxWidth}
              gap={2}
              mt={4}
            >
              <Typography
                fontFamily="Mont"
                fontSize={fontSize}
                fontWeight={600}
                color="#616161"
                textAlign="center"
              >
                {language==="fr" ? "Connectez-vous à votre compte":"Log in to your account"}

              </Typography>
              <Input
                name="email"
                minWidth={360}
                label="Email"
                rules={{
                  required: language === "fr" ?"Ce champ est requis.":"This field is required.",
                  validate: (value: string) =>
                    Validator.isEmail(value) || "Invalid email address",
                }}
              />
              <Input
                name="password"
                minWidth={360}
                label={language==="fr" ? "Mot de passe":"Password"}
                type={dataWatch.showPassword ? "text" : "password"}
                rules={{
                  required: language === "fr" ?"Ce champ est requis.":"This field is required.",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={onClickShowPassword}
                      edge="end"
                    >
                      {dataWatch.showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Typography
                fontFamily="Mont"
                fontSize={14}
                color="#EC6D3F"
                sx={{ cursor: "pointer" }}
                alignSelf="flex-end"
                onClick={handleForgetPassword}
              >
                {language==="fr" ? "Mot de passe oublié?":"Forgot your password?"}
              </Typography>
              <Button
                margin="32px 0 0 0"
                variant="contained"
                type="submit"
                width={190}
                boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
                onClick={methods.handleSubmit(onSubmit)}
              >
                Login
              </Button>
            </Box>
          </FormContainer>
        </Container>
      </Form>
    </FormProvider>
  );
};
export default SignIn;
