import { FC } from "react";
//Material UI
import Box from "@mui/material/Box";
//Styles
import { Container, LabelTag } from "./tag.styles";
//Types
import { TagProps } from "./tag.types";

const Tag: FC<TagProps> = (props) => {
  const { icon, label, ...rest } = props;
  if (!label) {
    return null;
  }
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Container>{icon}</Container>
      <LabelTag {...rest} variant="caption">
        {label}
      </LabelTag>
    </Box>
  );
};
export { Tag };
