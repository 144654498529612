import { Typography } from "@mui/material";
import { Container } from "./cardNotification.styles";
import { CardNotificationProps } from "./cardNotification.types";

const CardNotification = (props: CardNotificationProps) => {
  const { title, date_created } = props;
  return (
    <Container>
      <Typography
        color="#616161"
        fontSize={14}
        fontWeight={400}
        fontFamily="Mont"
      >
        {title}
      </Typography>
      <Typography
        color="#616161"
        fontSize={10}
        fontWeight={400}
        fontFamily="Mont"
        alignSelf="flex-end"
      >
        {date_created}
      </Typography>
    </Container>
  );
};

export default CardNotification;
