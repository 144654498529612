import {useContext, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
//Material UI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//assets
import { Images } from "../../../assets";
//hooks
import { useWindowDimensions } from "../../../hooks";
//Helpers
import { getParameterByName, SnackbarHolder } from "../../../helpers";
//services
import { resetPassword } from "../../../services";
//Components
import { Form, Image, Button, Input } from "../../../components/Commons";
//Styles
import { Container, FormContainer } from "./resetPassword.styles";
//Types
import { ForgetPasswordState } from "./resetPassword.types";
import {LanguageContext} from "../../../LanguageContext";

const ResetPassword = () => {
  const navigate = useNavigate();
  const token = getParameterByName("token");
  const { height, width } = useWindowDimensions();
  const { language } = useContext(LanguageContext);

  //states
  const methods = useForm<ForgetPasswordState>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const dataWatch = methods.watch();
  //queries & mutations
  const reset = useMutation(resetPassword);
  //memos
  const fontSize = useMemo(() => (width < 490 ? 12 : 16), [width]);
  //functions
  const onSubmit: SubmitHandler<ForgetPasswordState> = async (data) => {
    try {
      const res = await reset.mutateAsync({
        token: token,
        password: data.password,
      });

      if (res?.status === 204) {
        SnackbarHolder.alert("success", language==="fr"?"Mot de passe modifié avec succès.":"Password changed successfully.");

        navigate("/sign-in", { replace: true });

      }
    } catch (error) {
      SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
    }
  };

  //render
  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container>
          <FormContainer
            elevation={0}
            sx={{ maxHeight: height - 250, minHeight: height - 260 }}
          >
            <Image src={Images.ForgetPassword} width={100} />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={400}
              gap={2}
              mt={4}
            >
              <Typography
                fontFamily="Mont"
                fontSize={fontSize}
                fontWeight={600}
                color="#616161"
                textAlign="center"
              >
                {language==="fr"? "Créez un nouveau mot de passe!": "Create a new password!"}

              </Typography>
              <Input
                name="password"
                minWidth={360}
                label=  {language==="fr"? "Nouveau Mot de passe": "New Password"}
                type="password"
                rules={{
                  required: language === "fr" ?"Ce champ est requis.":"This field is required.",
                  pattern: {
                    value:
                      /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/gm,
                    message:
                        language==="fr"? "Mot de passe doit être d'une longueur minimum de 8 caractères avec au moins 1 lettre minuscule, 1 lettre majuscule, 1 chiffre et un caractère spécial": "Password must be at least 8 characters long with at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character",
                  },
                }}
              />
              <Input
                name="confirmPassword"
                minWidth={360}
                label={language==="fr"? "Confirmer Mot de passe": "Confirm Password"}
                type="password"
                rules={{
                  required: language === "fr" ?"Ce champ est requis.":"This field is required.",
                  validate: (value: string) =>
                    value === dataWatch.password || language==="fr"? "Mot de passe non conforme.": "Password not compliant.",
                }}
              />
              <Button
                margin="32px 0 0 0"
                variant="contained"
                type="submit"
                width={190}
                boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
                onClick={methods.handleSubmit(onSubmit)}
              >
                {language === "fr" ?"Envoyer":"Send"}
              </Button>
            </Box>
          </FormContainer>
        </Container>
      </Form>
    </FormProvider>
  );
};
export default ResetPassword;
