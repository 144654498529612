import { styled } from "@mui/material";

export const Table = styled("table")`
border: 1px solid black;
border-collapse: collapse;
width: 100%;
color: #616161;
font-family: "Mont";
font-weight: 300;
th {
  border: 1px solid black;
  border-collapse: collapse;
}
td {
  border: 1px solid black;
  border-collapse: collapse;
}
@media screen and (max-width: 500px) {
  font-size: 12px;
  overflow: auto;
}
`;
