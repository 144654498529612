import React, { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useFormContext, useWatch } from "react-hook-form";
//Material UI
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
//assets
import { Svg } from "../../../assets";
//Styles
import { Container, FormHelper, Input, Text } from "./dropZoneFile.styles";
//Types
import { DropzoneFileProps, FileProps } from "./dropZoneFile.types";
import { IconButton } from "@mui/material";

const maxSize = 5242880;

const DropzoneFiles: FC<DropzoneFileProps> = (props) => {
  const { name, message, rules, height, width, onAddFiles } = props;
  const { control, setValue } = useFormContext();

  const dataValue = useWatch({ control, name });
  //functions
  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      const list: Array<FileProps> = [];
      acceptedFiles.forEach((item) => {
        const newFile: FileProps = Object.assign(item, {
          preview: URL.createObjectURL(item),
        });
        list.push(newFile);
      });
      if (!dataValue) {
        setValue(name, list);
      } else {
        setValue(name, [...dataValue, ...list]);
      }
      if (onAddFiles) {
        onAddFiles(list);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataValue]
  );
  const onClickDelete = (index: number) => () => {
    let newList: Array<FileProps> = Object.assign([], dataValue);
    newList = newList.filter((_, iterator) => iterator !== index);
    setValue(name, newList);
  };
  //render
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: {
        "application/*": [".pdf", ".xlsx", ".xls"],
      },
      maxFiles: 3,
      maxSize: maxSize,
    });
  const handleError = () => {
    if (fileRejections.length > 0) {
      return fileRejections.map(({ file, errors }) => {
        return errors.map((e) => (
          <Typography color="red" fontSize={10}>
            Les Documents doivent être de type PDF ou exil et de taille de 5 Mb
            maximum
          </Typography>
        ));
      });
    }
  };

  const renderListFiles = (values: Array<FileProps>) => {
    if (values === undefined || values.length < 0) return;
    return values.map((item, index) => {
      return (
        <Box
          key={index}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize={10} color="#B5B9C7">
            {item.name}
          </Typography>
          <IconButton aria-label="delete" onClick={onClickDelete(index)}>
            <DeleteIcon color="primary" fontSize="small" />
          </IconButton>
        </Box>
      );
    });
  };

  const renderContent = () => {
    if (isDragActive) {
      return <Text>Drop the files here ...</Text>;
    }
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Svg.DropZone />
        <Text mt={2}>{message}</Text>
      </Box>
    );
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value }, fieldState: { error } }) => (
        <React.Fragment>
          {handleError()}
          <Container
            {...getRootProps()}
            style={{ height: height || 270, width }}
          >
            <Input {...getInputProps()} />
            {renderContent()}
          </Container>
          <Box display="flex" flexDirection="column" mt={1}>
            {renderListFiles(value)}
          </Box>
          <FormHelper error>{error?.message}</FormHelper>
        </React.Fragment>
      )}
    />
  );
};

export { DropzoneFiles };
