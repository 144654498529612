import { Box, BoxProps, Paper, PaperProps, styled } from "@mui/material";
import theme from "../../../theme";

export const Container = styled(Box)<BoxProps>`
  display: "flex";
  flex-direction: "column";
  flex: 1;
  padding: ${theme.spacing(2, 10, 10, 10)};
  height: "100vh";
  @media screen and (max-width: 490px) {
    padding: ${theme.spacing(2, 2, 10, 2)};
  }
`;

export const FormContainer = styled(Paper)<PaperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: ${theme.spacing(6)};
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 490px) {
    padding: ${theme.spacing(3)};
  }
`;
