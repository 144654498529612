import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
//services
import { authRefreshToken } from "../../services";
//Types
import {
  AuthContextProps,
  AuthProviderProps,
  RequireAuthProps,
  SignInProps,
} from "./authContext.types";

let AuthContext = createContext<AuthContextProps>(null!);

const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;

  //state
  const [token, setToken] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [acceptedCGU, setAccpectedCGU] = useState<boolean>(false);

  //queries & mutations
  const queryClient = useQueryClient();
  const refrechingToken = useMutation(authRefreshToken);

  //use effect
  useEffect(() => {
    const role = sessionStorage.getItem("role");
    const token = sessionStorage.getItem("token");
    const refreshToken = sessionStorage.getItem("refreshToken");
    if (!token || !refreshToken) {
      setIsLoading(false);
    } else {
      setRole(role);
      setToken(token);
      setRefreshToken(refreshToken);
    }
  }, []);

  //functions
  const signIn = (params: SignInProps) => {
    const { token, refreshToken, role } = params;
    sessionStorage.setItem("refreshToken", refreshToken);
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("role", role);
    if (!token || !refreshToken) {
      setIsLoading(false);
    } else {
      setRole(role);
      setToken(token);
      setRefreshToken(refreshToken);
    }
  };

  const handlingErrorUnauthorized = (queryName?: any) => {
    if (!refreshToken) {
      sessionStorage.clear();
      globalThis.location.reload();
      return;
    }
    refrechingToken.mutate(
      { refresh_token: refreshToken },
      {
        onSuccess: (data) => {
          if (data.status !== 200) {
            sessionStorage.clear();
            globalThis.location.reload();
          }
          sessionStorage.setItem("refreshToken", data?.data.data.refresh_token);
          sessionStorage.setItem("token", data?.data.data.access_token);
          setToken(data?.data.data.access_token);
          setRefreshToken(data?.data.data.refresh_token);
          if (queryName) {
            queryClient.invalidateQueries(queryName);
          }
          setIsLoading(false);
        },
      }
    );
  };

  const handleCGUAcceptation = (status: boolean) => {
    setAccpectedCGU(status);
  };

  const signOut = async (url?: string) => {
    sessionStorage.clear();
    sessionStorage.clear();
    setRole(null);
    setToken(null);
    setRefreshToken(null);
    if (url) window.location.href = url;
  };

  let value = {
    isLoading,
    token,
    role,
    refreshToken,
    acceptedCGU,
    signIn,
    signOut,
    handlingErrorUnauthorized,
    handleCGUAcceptation,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

const RequireAuth: FC<RequireAuthProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();

  if (!auth.token && !auth.isLoading) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

export { AuthProvider, useAuth, RequireAuth };
