import { FormControl, styled } from "@mui/material";

export const FormControlStyled = styled(FormControl)<{ label?: number }>`
  .MuiSelect {
    background-color: #ffffff;
    border-radius: 10px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 10px;
  }
`;
