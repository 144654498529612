import { Box, BoxProps, lighten, styled } from "@mui/material";
import theme from "../../../../theme";

export const Container = styled(Box)<BoxProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${theme.spacing(4)};
  padding: ${theme.spacing(2, 6)};
  @media screen and (max-width: 1400px) {
    justify-content: flex-start;
  }
`;

export const ContainerCondition = styled(Box)<BoxProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 300px;
  border-radius: 20px;
  background-color: ${lighten("#e0e0e7", 0.8)};
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(5)};
  @media screen and (max-width: 1060px) {
    min-width: 190px;
  }
`;
