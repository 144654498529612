import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/system";
//Assets
import { Images } from "../../../assets";
import theme from "../../../theme";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "100vw",
  height: "100vh",
  backgroundImage: `url(${Images.HeaderBackground})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  overflow: "hidden",
  maxHeight: "100vh",
}));

export const InnerBox = styled(Box)<BoxProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${theme.spacing(2, 10, 10, 10)};
  height: 100vh;
  @media screen and (max-width: 490px) {
    padding: ${theme.spacing(2, 2, 10, 2)};
  }
`;
export const Content = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  backgroundColor: "#fff",
  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.05)",
  borderRadius: "20px",
  padding: theme.spacing(2, 4),
}));
