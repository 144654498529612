import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/system";
//Assets
import { Images } from "../../assets";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "100vw",
  height: "100vh",
  backgroundImage: `url(${Images.HeaderBackground})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  overflow: "hidden",
  maxHeight: "100vh",
}));
