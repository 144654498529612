import { FC } from "react";
//Material UI
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
//Types
import { ProgressBarProps } from "./progressBar.types";

const ProgressBar: FC<ProgressBarProps> = (props) => {
  const { value, textColor, minWidth, ...rest } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" value={props.value} {...rest} />
      </Box>
      <Box sx={{ minWidth: minWidth || 35 }}>
        <Typography
          variant="body2"
          color="InfoText"
          style={{ color: textColor }}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};
export { ProgressBar };
