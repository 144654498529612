import { Box, BoxProps, styled } from "@mui/material";
import theme from "../../../theme";

export const Container = styled(Box)<BoxProps>`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: ${theme.spacing(2)};
  gap: ${theme.spacing(1)};
  cursor: pointer;
  :hover {
    background-color: rgba(239, 75, 18, 0.05);
  }
`;
