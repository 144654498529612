import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
//Material UI
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
//ContextAPI
import { useFormHandlerContext } from "../../../contextAPI";
//Types
import { DatePickerProps } from "./datePicker.types";
import { CustomTextField } from "../TextField";

const DatePicker: FC<DatePickerProps> = (props) => {
  const {
    id,
    name,
    label,
    margin,
    fullWidth,
    minDate,
    maxDate,
    rules,
    disableFuture,
    initValue,
  } = props;
  const { control } = useFormContext();
  const dataForm = useFormHandlerContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DesktopDatePicker
          label={label}
          value={value || initValue}
          onChange={onChange}
          inputFormat="dd/MM/yyyy"
          minDate={minDate}
          maxDate={maxDate}
          disabled={dataForm?.loading}
          disableFuture={disableFuture || false}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              style={{ margin }}
              size="small"
              variant="outlined"
              fullWidth={typeof fullWidth === "boolean" ? fullWidth : true}
              id={id}
              helperText={error?.message}
              error={error?.message !== undefined}
            />
          )}
        />
      )}
    />
  );
};
export { DatePicker };
