import { Box, BoxProps, styled } from "@mui/material";
import theme from "../../../theme";

export const Container = styled(Box)<BoxProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(1)};
  position: relative;
  align-items: center;
  border: 1px solid ${theme.palette.secondary.main};
  min-height: 60px;
  /* min-width: 220px; */
  border-radius: 20px;
  cursor: pointer;
  @media screen and (max-width: 1060px) {
    flex-direction: column;
    min-height: 60px;
    min-width: 190px;
    gap: ${theme.spacing(1)};
  }
`;

export const BottomBtn = styled(Box)<BoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing(2)};
  @media screen and (max-width: 1060px) {
    padding: ${theme.spacing(1)};
  }
`;
