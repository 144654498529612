import { Controller, useFormContext } from "react-hook-form";
//Material UI
import SelectList from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
//ContextAPI
import { useFormHandlerContext } from "../../../contextAPI";
//Types
import { SelectProps } from "./select.types";
import { FormControlStyled } from "./select.styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function Select<T>(props: SelectProps<T>): JSX.Element {
  const {
    id,
    name,
    label,
    data,
    margin,
    fullWidth,
    rules,
    required,
    helperText,
    disabled,
    getOptionValue,
    getOptionLabel,
  } = props;
  const { control } = useFormContext();
  const dataForm = useFormHandlerContext();
  //render
  const renderItem = () => {
    return data.map((item, index) => {
      return (
        <MenuItem key={index} value={getOptionValue(item)}>
          {getOptionLabel(item)}
        </MenuItem>
      );
    });
  };
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const isError = error && error.message !== null;
        return (
          <FormControlStyled
            variant="outlined"
            style={{ margin, backgroundColor: "#fff" }}
            error={isError}
            size="small"
            required={required}
            fullWidth={typeof fullWidth === "boolean" ? fullWidth : true}
          >
            <InputLabel id={id}>{label}</InputLabel>
            <SelectList
              labelId={`select-${id}`}
              id={id}
              value={value || ""}
              error={isError}
              label={label}
              disabled={dataForm?.loading || disabled}
              onChange={onChange}
              MenuProps={MenuProps}
            >
              {renderItem()}
            </SelectList>
            <FormHelperText error={props.isError || isError}>
              {helperText || error?.message}
            </FormHelperText>
          </FormControlStyled>
        );
      }}
    />
  );
}
export { Select };
