import {
  Box,
  BoxProps,
  lighten,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import theme from "../../../../theme";

export const Container = styled(Box)<BoxProps>`
  display: flex;
  flex-direction: column;
  width: 670px;
  gap: ${theme.spacing(2)};
  overflow: auto;
  padding: ${theme.spacing(0, 2)};
  @media screen and (max-width: 1024px) {
    width: 100%;
    min-width: 200px;
  }
`;

export const ButtonText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: "#616161",
  marginTop: "-3px",
}));

export const CoFoundersContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: lighten("#E0E0E7", 0.8),
  borderRadius: "10px",
  gap: theme.spacing(2),
  padding: theme.spacing(1),
}));

export const AddElement = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
}));

export const RemoveContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "56px",
  minWidth: "56px",
  backgroundColor: lighten("#999999", 0.9),
  borderRadius: "6px",
  cursor: "pointer",
}));

export const Labeltext = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: "Mont",
  fontSize: 14,
  fontWeight: 400,
}));
