function DorpZoneAvatar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="23"
      fill="none"
      viewBox="0 0 22 23"
    >
      <path
        fill="#999"
        d="M16.524 22.5H5.861c-2.848 0-4.66-1.7-4.822-4.538l-.53-8.394A4.518 4.518 0 011.742 6.15a4.597 4.597 0 013.347-1.455.901.901 0 00.794-.499l.732-1.455c.6-1.19 2.106-2.116 3.419-2.116h2.33c1.312 0 2.808.926 3.408 2.106l.733 1.486a.909.909 0 00.793.478c1.262 0 2.483.529 3.348 1.455a4.532 4.532 0 011.23 3.418l-.528 8.404c-.183 2.88-1.944 4.528-4.823 4.528zM10.033 2.151c-.753 0-1.71.59-2.055 1.272l-.733 1.465a2.428 2.428 0 01-2.157 1.333c-.855 0-1.648.346-2.238.966a3.003 3.003 0 00-.814 2.28l.529 8.404c.122 2.055 1.231 3.103 3.296 3.103h10.663c2.055 0 3.164-1.048 3.297-3.103l.529-8.404a3.042 3.042 0 00-.814-2.28 3.063 3.063 0 00-2.239-.966c-.905 0-1.73-.509-2.157-1.313l-.742-1.485c-.336-.672-1.293-1.262-2.045-1.262h-2.32v-.01z"
      ></path>
      <path
        fill="#999"
        d="M12.717 8.256H9.665a.769.769 0 01-.763-.763c0-.417.346-.763.763-.763h3.052c.417 0 .763.346.763.763a.769.769 0 01-.763.763zM11.191 18.43a4.069 4.069 0 01-4.07-4.07 4.069 4.069 0 014.07-4.07 4.069 4.069 0 014.07 4.07 4.069 4.069 0 01-4.07 4.07zm0-6.613a2.545 2.545 0 000 5.087 2.545 2.545 0 000-5.087z"
      ></path>
    </svg>
  );
}

export default DorpZoneAvatar;
