import { Route, Routes } from "react-router-dom";
//pages
import AuthLayout from "../pages/Auth";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import SignIn from "../pages/Auth/SignIn";
import ChoiceSelect from "../pages/Auth/SignUp/ChoiceSelect";
import ConfirmChoice from "../pages/Auth/SignUp/ConfirmChoice";
import ConfirmChoicePartner from "../pages/Auth/SignUp/ConfirmChoicePartner"
import UserForm from "../pages/Auth/SignUp/UserForm";
import UserPartener from "../pages/Auth/SignUp/UserPartener";
import MainLayout from "../pages/MainLayout";
import FormCompany from "../pages/MainLayout/PartnerForm/FormCompany";
import ValidationPartner from "../pages/MainLayout/PartnerForm/ValidationPartner";
import AddSupport from "../pages/MainLayout/StartupForm/AddSupport";
import FormStartup from "../pages/MainLayout/StartupForm/FormStartup";
import NoDraft from "../pages/MainLayout/StartupForm/StartupNoDraft";
import ValidationStartup from "../pages/MainLayout/StartupForm/ValidationStartup";
import NotFound from "../pages/SharedPages/PageNotFound";
import Partner from "../pages/SharedPages/Partner";
import Startup from "../pages/SharedPages/Startup";

const AppRoute = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<AuthLayout />}>
        <Route path="/" element={<Startup />}>
          <Route index element={<ChoiceSelect />} />
          <Route path="confirm-choice" element={<ConfirmChoice />} />
          <Route path="user-form" element={<UserForm />} />
        </Route>
        <Route path="partner" element={<Partner />}>
          <Route path="confirm-choice-partner" element={<ConfirmChoicePartner />} />
          <Route path="add-partner" element={<UserPartener />} />
        </Route>
        <Route path="sign-in" element={<SignIn />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<Startup />}>
          <Route path="form-startup" element={<FormStartup  firstAcces={true} />}  />
          <Route path="edit-startup/:id" element={<FormStartup />} />
          <Route path="add-support/:id" element={<AddSupport />} />
          <Route path="validation/:id" element={<ValidationStartup />} />
          <Route path="no-draft/:id" element={<NoDraft />} />
        </Route>
        <Route path="partner" element={<Partner />}>
          <Route path="form-company" element={<FormCompany />} />
          <Route path="edit-company/:id" element={<FormCompany />} />
          <Route path="validation/:id" element={<ValidationPartner />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoute;
