function ToForm() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 80 80"
    >
      <circle cx="40" cy="40" r="40" fill="#F9BC4A" opacity="0.1"></circle>
      <path
        stroke="#F9BC4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M40 40c5.753 0 10.417-4.664 10.417-10.417S45.753 19.167 40 19.167 29.583 23.83 29.583 29.583 34.247 40 40 40zM22.104 60.833c0-8.062 8.021-14.583 17.896-14.583 2 0 3.938.27 5.75.77"
      ></path>
      <path
        stroke="#F9BC4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M60.833 52.5a8.169 8.169 0 01-1.208 4.292c-.438.75-1 1.416-1.646 1.958a8.135 8.135 0 01-5.479 2.083 8.27 8.27 0 01-7.125-4.041 8.169 8.169 0 01-1.208-4.292 8.272 8.272 0 013.125-6.5 8.34 8.34 0 015.208-1.833 8.331 8.331 0 018.333 8.333z"
      ></path>
      <path
        stroke="#F9BC4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M49.25 52.5l2.063 2.063 4.437-4.105"
      ></path>
    </svg>
  );
}

export default ToForm;
