import { FC } from "react";
//Types
import { FormProps } from "./form.types";

const Form: FC<FormProps> = (props) => {
  const { children, ...rest } = props;
  return <form {...rest}>{children}</form>;
};

export { Form };
