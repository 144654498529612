function PDFIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#999"
        d="M29.623 36.364H16.197c-6.126 0-9.65-3.552-9.65-9.725V9.724C6.546 3.552 10.07 0 16.196 0h13.426c6.126 0 9.65 3.552 9.65 9.725v16.913c0 6.174-3.524 9.726-9.65 9.726zM16.197 2.537c-4.8 0-7.133 2.351-7.133 7.188v16.913c0 4.838 2.333 7.189 7.133 7.189h13.426c4.8 0 7.133-2.351 7.133-7.188V9.724c0-4.837-2.333-7.188-7.133-7.188H16.197z"
      ></path>
      <path
        fill="#999"
        d="M33.719 13.818h-3.174c-2.412 0-4.363-2.115-4.363-4.728V5.652c0-.705.54-1.289 1.19-1.289.65 0 1.19.584 1.19 1.29V9.09c0 1.186.888 2.15 1.983 2.15h3.174c.65 0 1.19.583 1.19 1.288 0 .705-.54 1.29-1.19 1.29zM22.81 18.183h-6.348c-.65 0-1.19-.495-1.19-1.091S15.813 16 16.463 16h6.348c.65 0 1.19.494 1.19 1.09 0 .597-.54 1.092-1.19 1.092zM30.01 21.092H16.535c-.69 0-1.264-.495-1.264-1.09 0-.597.573-1.092 1.264-1.092h13.473c.69 0 1.264.495 1.264 1.091s-.573 1.091-1.264 1.091z"
      ></path>
      <path
        fill="#FF1D00"
        d="M26.182 38.91c0 .6-.491 1.09-1.091 1.09h-24C.49 40 0 39.51 0 38.91V28c0-.6.49-1.09 1.09-1.09h24c.6 0 1.092.49 1.092 1.09v10.91z"
      ></path>
      <path
        fill="#fff"
        d="M5.278 36.045a.391.391 0 01-.288-.117.391.391 0 01-.117-.288v-5.508c0-.114.039-.21.117-.288a.378.378 0 01.288-.126H7.33c.57 0 1.035.096 1.395.288.36.192.618.438.774.738.156.3.234.624.234.972s-.078.672-.234.972c-.156.3-.414.546-.774.738-.36.192-.825.288-1.395.288H5.692v1.926c0 .114-.042.21-.126.288a.391.391 0 01-.288.117zm1.971-3.105c.612 0 1.041-.117 1.287-.351.252-.234.378-.525.378-.873s-.126-.639-.378-.873c-.246-.234-.675-.351-1.287-.351H5.692v2.448h1.557zM11.325 36a.391.391 0 01-.288-.117.391.391 0 01-.117-.288v-5.463c0-.114.039-.21.117-.288a.378.378 0 01.288-.126h1.809c.684 0 1.263.153 1.737.459.48.3.84.69 1.08 1.17.24.48.36.984.36 1.512s-.12 1.032-.36 1.512-.6.873-1.08 1.179c-.474.3-1.053.45-1.737.45h-1.81zm1.71-.765c.492 0 .924-.099 1.296-.297a2.19 2.19 0 00.864-.837c.204-.36.306-.774.306-1.242 0-.468-.102-.879-.306-1.233a2.112 2.112 0 00-.864-.837c-.372-.204-.804-.306-1.296-.306h-1.296v4.752h1.296zm5.049.81a.391.391 0 01-.288-.117.391.391 0 01-.117-.288v-5.508c0-.114.039-.21.117-.288a.378.378 0 01.288-.126h3.5c.109 0 .199.039.27.117a.352.352 0 01.118.27.352.352 0 01-.117.27.366.366 0 01-.27.108h-3.087v1.989h2.772c.108 0 .198.039.27.117a.352.352 0 01.117.27.352.352 0 01-.117.27.366.366 0 01-.27.108h-2.772v2.403c0 .114-.042.21-.126.288a.391.391 0 01-.288.117z"
      ></path>
    </svg>
  );
}

export default PDFIcon;
