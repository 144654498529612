import { Location } from "react-router-dom";

export const getHierarchicalFromPath = (pathname: string) => {
  const tab = pathname.split("/").filter((row) => {
    if (row.trim() === "") {
      return false;
    } else if (row.match(".*\\d.*")) {
      return false;
    }
    return true;
  });
  return tab;
};
export const getLastRouteName = (location: Location) => {
  const tab = getHierarchicalFromPath(location.pathname);
  const lastRouteName = tab.slice(-1).shift();
  return lastRouteName;
};
export const getPageNameFromPathName = (location: Location) => {
  const tab = getHierarchicalFromPath(location.pathname);
  const filtered: Array<string> = [];
  tab.forEach((item, index) => {
    const result = getNameRoute(index, item);
    if (result) {
      filtered.push(result);
    }
  });
  return filtered;
};

const getNameRoute = (index: number, name: string) => {
  let routeName: string | null = name;
  return routeName;
};

export function getParameterByName(name: string, url?: string) {
  if (!url) url = window?.location?.href || "";
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&#]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
