//Materil UI
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { StepIconProps } from "@mui/material/StepIcon";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Typography from "@mui/material/Typography";
//Styles
import { ColorlibConnector, ColorlibStepIconRoot } from "./stepper.styles";
//Types
import { StepperListProps } from "./stepper.types";

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? <CheckCircleIcon /> : <TripOriginIcon />}
    </ColorlibStepIconRoot>
  );
}

function StepperList(props: StepperListProps) {
  const { steps, activeStep, completed } = props;
  //functions
  return (
    <Stack spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <Typography fontFamily="Mont" fontSize={12} fontWeight={400}>
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

export { StepperList };
