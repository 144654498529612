import { Controller, useFormContext } from "react-hook-form";
//Material UI
import Autocomplete from "@mui/material/Autocomplete";
//ContextAPI
import { useFormHandlerContext } from "../../../contextAPI";
//Components
import { CustomTextField } from "../TextField";
//Types
import { AutoCompleteProps } from "./autoComplete.types";

function AutoComplete<T>(props: AutoCompleteProps<T>): JSX.Element {
  const {
    id,
    name,
    label,
    placeholder,
    data,
    margin,
    fullWidth,
    rules,
    disabled,
    getOptionLabel,
    getOptionValue,
  } = props;
  const { control } = useFormContext();
  const dataForm = useFormHandlerContext();

  //render
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, value, ...rest },
        fieldState: { error },
      }) => (
        <Autocomplete
          isOptionEqualToValue={(option, value) =>
            getOptionValue(option, value)
          }
          style={{ margin }}
          id={id}
          options={data}
          value={value == null || value === undefined ? "" : value}
          disabled={dataForm?.loading || disabled}
          getOptionLabel={getOptionLabel}
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            value: T | null
          ) => onChange(value)}
          ChipProps={{ color: "primary", style: { borderRadius: 4 } }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              size="small"
              fullWidth={fullWidth || true}
              label={label}
              variant="outlined"
              placeholder={placeholder}
              helperText={error?.message}
              error={error?.message !== undefined}
            />
          )}
        />
      )}
    />
  );
}
export { AutoComplete };
