import { useState } from "react";
//Material Ui
import { lighten } from "@mui/material";
//ContextAPI
import { useAuth } from "../../contextAPI";
//Hooks
import { useWindowDimensions } from "../../hooks";
//Styles
import { Container, Content, Tabs, TabsHeader } from "./tabsContainer.styles";
//Types
import { TabsContainerProps } from "./tabsContainer.types";

const TabsContainer = (props: TabsContainerProps) => {
  const { children, tabList, currenct, tabClick } = props;
  const { height } = useWindowDimensions();
  const auth = useAuth();

  //stats
  const [currentTab, setCurrentTab] = useState<string>(
    currenct ? currenct : "startup"
  );
  //functions
  const handleClick = (type: string) => {
    if (auth.token) return;
    setCurrentTab(type);
    tabClick(type);
  };
  //renders
  const renderTabsItems = () => {
    return tabList.map((el, index: number) => {
      return (
        <Tabs
          key={index}
          onClick={() => handleClick(el.type)}
          sx={
            el.type === currentTab
              ? {
                  color: "#EC6D3F",
                  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.09)",
                }
              : {
                  color: "#999999",
                  boxShadow: "none",
                  backgroundColor: lighten("#E0E0E7", 0.9),
                }
          }
        >
          {el.label}
        </Tabs>
      );
    });
  };
  return (
    <Container>
      <TabsHeader>{renderTabsItems()}</TabsHeader>
      <Content minHeight={height - 240}>{children}</Content>
    </Container>
  );
};

export { TabsContainer };
