export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const sortFieldsArray = (a: any, b: any) => {
  if (a.meta.sort < b.meta.sort) {
    return -1;
  }
  if (a.meta.sort > b.meta.sort) {
    return 1;
  }
  return 0;
};

export const getMonthDifference = (startDate: Date, endDate: Date) => {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
};
