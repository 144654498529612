import {
  Box,
  BoxProps,
  Link,
  LinkProps,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import theme from "../../../../theme";

export const SectionContainer = styled(Box)<BoxProps>`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: ${theme.spacing(1)};
  width: 100%;
  @media screen and (max-width: 1060px) {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
`;

export const Container = styled(Box)<BoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(0, 2)};
`;
export const ContainerLeft = styled(Box)<BoxProps>`
  display: "flex";
  flex-direction: column;
  padding: ${theme.spacing(1, 2, 2, 2)};
  background-color: rgba(224, 224, 231, 0.1);
  border-radius: 20px;
`;
export const ContainerRight = styled(Box)<BoxProps>`
  padding: ${theme.spacing(3)};
  background-color: rgba(224, 224, 231, 0.1);
  border-radius: 20px;
  overflow: auto;
  @media screen and (max-width: 1060px) {
    overflow: unset;
  }
`;
export const ContainerHalf = styled(Box)<BoxProps>`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: ${theme.spacing(1)};
  @media screen and (max-width: 1060px) {
    display: flex;
    flex-direction: column;
  }
`;

export const SectionTitle = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontWeight: 600,
    fontSize: "16px",
    color: "#999999",
  })
);
export const SectionSubTitle = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontWeight: 600,
    fontSize: "14px",
    color: "#616161",
  })
);
export const Information = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  color: "#999999",
}));

export const ButtonText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: "#616161",
  marginTop: "-3px",
}));

export const DocumentBox = styled(Link)<LinkProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing(1)};
  border-radius: 10px;
  height: 110px;
  width: 110px;
  border: 1px solid #e0e0e7;
  text-decoration: none;
`;

export const DocumentText = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontWeight: 500,
    width: "90px",
    height: "40px",
    fontSize: "12px",
    color: "#C2C2C2",
    overflow: "hidden",
    textOverflow: "ellipsis",
  })
);

export const Labeltext = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontFamily: "Mont",
  fontSize: 14,
  fontWeight: 400,
}));
