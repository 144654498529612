function DropeZone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#EF4B12"
        d="M10.198 3.697a.676.676 0 01-.483-.2l-1.85-1.85-1.85 1.85a.688.688 0 01-.965 0 .688.688 0 010-.966L7.383.198a.688.688 0 01.965 0l2.333 2.333a.688.688 0 010 .966.676.676 0 01-.483.2z"
      ></path>
      <path
        fill="#EF4B12"
        d="M7.863 10.698a.688.688 0 01-.684-.683V.748c0-.374.31-.684.684-.684.373 0 .683.31.683.684v9.267c0 .383-.31.683-.683.683z"
      ></path>
      <path
        fill="#EF4B12"
        d="M7.973 16C3.28 16 0 12.72 0 8.027c0-.374.31-.684.683-.684.374 0 .684.31.684.684 0 3.89 2.715 6.606 6.606 6.606s6.607-2.715 6.607-6.606c0-.374.31-.684.683-.684.374 0 .684.31.684.684 0 4.693-3.28 7.973-7.974 7.973z"
      ></path>
    </svg>
  );
}

export default DropeZone;
