import React, { createContext, useState, useEffect, ReactNode } from "react";

// Define the shape of the LanguageContext
interface LanguageContextType {
    language: string;
    setLanguage: (lang: string) => void;
}

// Create a Context for the language with default values
export const LanguageContext = createContext<LanguageContextType>({
    language: "en", // Default language value
    setLanguage: () => {}, // Default no-op function
});

// Define props type for LanguageProvider
interface LanguageProviderProps {
    children: ReactNode; // Explicitly typing the children prop
}

// Create a provider component
export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem("language") || "en");

    useEffect(() => {
        // Store the selected language in localStorage whenever it changes
        localStorage.setItem("language", language);
    }, [language]);

    // Provide both the current language and a method to update it
    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
