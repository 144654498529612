import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
//Assets
import { Svg } from "../../../assets";
//hooks
import { useWindowDimensions } from "../../../hooks";
//Styles
import { Container } from "./footerGeneral.styles";

const FooterGeneral = () => {
  const { width } = useWindowDimensions();
  const fontSize = useMemo(() => (width < 490 ? 10 : 14), [width]);
  return (
    <Container>
      <Typography
        fontWeight={400}
        fontFamily="Mont"
        fontSize={fontSize}
        color="#000000"
      >
        ©2022 FUZÉ by Digital Africa all rights reserved
      </Typography>
      <Box display="flex" gap={1} alignItems="center" pr={6}>
        <Typography
          fontWeight={400}
          fontFamily="Mont"
          fontSize={fontSize}
          color="#000000"
        >
          Technology by
        </Typography>
        <Svg.FooterSatoripopLogo />
      </Box>
    </Container>
  );
};

export { FooterGeneral };
