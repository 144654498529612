import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "transparent",
  position: "absolute",
  width: "100%",
  bottom: 0,
  padding: theme.spacing(2),
}));
