import { Box, BoxProps, FormHelperText, TypographyProps } from "@mui/material";
import { styled } from "@mui/system";

export const FormHelper = styled(FormHelperText)(({ theme }) => ({
  margin: "3px 14px 0 14px",
}));

export const Container = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(236, 109, 63, 0.05)",
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: 6,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
}));
export const ContainerFilled = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(224, 224, 231, 0.1)",
  border: `1px solid #E0E0E7`,
  borderRadius: 6,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const Input = styled("input")(({ theme }) => ({
  overflow: "hidden",
}));

export const Span = styled("span")<TypographyProps>(({ theme }) => ({
  fontSize: 24,
  fontWeight: "400",
  color: theme.palette.primary.main,
  textAlign: "center",
}));

export const Text = styled("p")<TypographyProps>(({ theme }) => ({
  fontSize: 12,
  fontWeight: "400",
  color: "#EF4B12",
  textAlign: "center",
}));

export const ContainerLoading = styled(Box)<BoxProps>(({ theme }) => ({
  position: "absolute",
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  padding: theme.spacing(1),
  left: 0,
  right: 0,
  bottom: 0,
  background:
    "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.50) 50%, rgba(0,0,0,0.2) 100%);",
}));
