import { Box, Typography } from "@mui/material";
import { useWindowDimensions } from "../../../hooks";
import { Table } from "./cgu.styles";
import {useContext} from "react";
import {LanguageContext} from "../../../LanguageContext";

const CGU = () => {
  const { width } = useWindowDimensions();
    const { language } = useContext(LanguageContext);

    return (
    <>
      <Typography
        fontFamily="Mont"
        fontSize={width < 500 ? 16 : 20}
        color="#EF4B12"
        textAlign="center"
      >
        {language==="fr" ? "CONDITIONS GÉNÉRALES D’UTILISATION DES SERVICES DU PROGRAMME Fuzé": "GENERAL TERMS AND CONDITIONS OF USE FOR FUZE PROGRAM OPERATIONS"}

      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" mt={2}>
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Préambule": "Preamble"}

        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "L’initiative DIGITAL AFRICA est née de la volonté du président de la\n" +
            "        République, Emmanuel Macron, de soutenir la dynamique entrepreneuriale\n" +
            "        et d’accompagner le développement de l’entrepreneuriat innovant en\n" +
            "        Afrique. DIGITAL AFRICA est une initiative collaborative et ouverte au\n" +
            "        service de l’entrepreneur africain.": "The DIGITAL AFRICA initiative was born of French President Emmanuel Macron's desire to support the entrepreneurial dynamic and the development of innovative entrepreneurship in Africa. DIGITAL AFRICA is a collaborative and open initiative at the service of African entrepreneurs."}

      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "DIGITAL AFRICA, ci-après DIGITAL AFRICA, met, notamment en œuvre un\n" +
            "        programme Fuzé visant à accompagner les entrepreneurs de l’Afrique\n" +
            "        francophone, qui œuvrent dans le numérique et tout particulièrement dans\n" +
            "        certains secteurs prioritaires. Le programme Fuzé vise à octroyer un\n" +
            "        financement aux premières étapes du projet (phase d’idéation et\n" +
            "        d’amorçage).": "DIGITAL AFRICA, hereinafter referred to as DIGITAL AFRICA, is implementing a FUZE program designed to support entrepreneurs in Africa, who are active in the digital sector and particularly in certain priority sectors. The FUZE program aims to provide funding for the early stages of a project (ideation and seed phase)."}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "La plateforme numérique Fuzé, ci-après la “ Plateforme ”, permettra aux\n" +
            "        startup d’accéder aux informations sur le programme Fuzé et la procédure\n" +
            "        de sélection, et de soumettre leurs candidatures en ligne.": "The Digital Africa digital platform, hereinafter the “Platform”, will enable start-ups to access information on the FUZE program and the selection procedure, and to submit their applications online. "}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Les présentes Conditions Générales d’Utilisation (CGU) ont pour objet de\n" +
            "        fixer les conditions d'octroi de financement et de labellisation des\n" +
            "        partenaires du Programme Fuzé à l’Utilisateur, d’une part, et de définir\n" +
            "        les droits et obligations de DIGITAL AFRICA et l’Utilisateur, d’autre\n" +
            "        part. Ces Conditions générales d’utilisation (CGU) constituent un\n" +
            "        contrat entre DIGITAL AFRICA et l’Utilisateur dont le recueil des\n" +
            "        données n’est pas facultatif mais reste limité dans le temps (défini\n" +
            "        dans le volet “Base de données”).": "The purpose of these General Terms and Conditions of Use (GTCU) is to set the conditions for granting funding and labeling partners of the FUZE Program to the User, on the one hand, and to define the rights and obligations of DIGITAL AFRICA and the User, on the other. These General Conditions of Use (GCU) constitute a contract between DIGITAL AFRICA and the User whose data collection is not optional but remains limited in time (defined in the “Database” section). "}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Définition": "Definitions"}


        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Pour les CGU des services du programme Fuzé , on entend par": "For the purposes of the GCU of the FUZE program operations, the following definitions apply "}


        </Typography>
      </Box>
      <ol>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "Données : toute information transmise par la startup candidate au\n" +
                "            financement du programme Fuzé ainsi que les données relatives aux\n" +
                "            partenaires (les structures d’accompagnement partenaires).": "Data: any information transmitted by the start-up applying for funding from the FUZE program, as well as data relating to partners (partner support structures)."}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "Données à caractère personnel : Toute information se rapportant à\n" +
                "            une personne physique identifiée ou identifiable (ci-après dénommée\n" +
                "            «personne concernée»); est réputée être une «personne physique\n" +
                "            identifiable» une personne physique qui peut être identifiée,\n" +
                "            directement ou indirectement, notamment par référence à un\n" +
                "            identifiant, tel qu’un nom, un numéro d’identification, des données\n" +
                "            de localisation, un identifiant en ligne, ou à un ou plusieurs\n" +
                "            éléments spécifiques propres à son identité physique, physiologique,\n" +
                "            génétique, psychique, économique, culturelle ou sociale; (Art 4.1,\n" +
                "            Règlement Général de la Protection des Données à caractère personnel\n" +
                "            (RGPD)).": "Personal Data: any information relating to an identified or identifiable natural person (hereinafter referred to as the “Data Subject”); an “identifiable natural person” is one who can be identified, directly or indirectly, in particular by reference to an identifier, such as a name, an identification number, location data, an online identifier, or to one or more factors specific to his or her physical, physiological, genetic, mental, economic, cultural or social identity; (Art 4. 1, General Regulation on the Protection of Personal Data (RGPD))."}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "Formulaire : questionnaire de présentation et d’évaluation du\n" +
                "            candidat startup souhaitant bénéficier des services du Programme\n" +
                "            Fuzé ou de la structure d’accompagnement souhaitant devenir\n" +
                "            partenaire. Le formulaire doit être rempli par le candidat, qui\n" +
                "            deviendra Partenaire Fuzé .": "Form: presentation and evaluation questionnaire for the start-up candidate wishing to benefit from the FUZE Program, or for the support structure wishing to become a partner. The form must be completed by the candidate, who will become a FUZE Partner."}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "Utilisateur : toute startup candidate au programme Fuzé , toute\n" +
                "            structure d’accompagnement souhaitant devenir Partenaire Fuzé ,\n" +
                "            toute startup sélectionnée pour recevoir les services du Programme\n" +
                "            Fuzé et tout Partenaire Fuzé , sont qualifiés d’utilisateurs.": "User : any start-up candidate to the FUZE program, any support structure wishing to become a FUZE Partner, any start-up selected to receive funding from the FUZE Program and any FUZE Partner, are qualified as users."}


          </Typography>
        </li>
      </ol>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Mentions légales": "Terms of use"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Le site web du programme Fuzé est édité par Digital Africa, société par\n" +
            "        actions simplifiée unipersonnelle, au capital de 10 000 euros, dont le\n" +
            "        siège social est 151 rue Saint Honoré, à Paris 1er, immatriculée au\n" +
            "        registre du commerce et des sociétés de Paris sous le numéro 912 812 203\n" +
            "        représentée, représentée par Madame Stéphan-Eloïse GRAS agissant en sa\n" +
            "        qualité de Directrice Générale.": "The FUZE program website is published by Digital Africa, a société par actions simplifiée\n" +
            "unipersonnelle, with capital of 10,000 euros, headquartered at 151 rue Saint Honoré, Paris\n" +
            "1er, registered in the Paris Trade and Companies Register under number 912 812 203,\n" +
            "represented by Mr Grégoire de Padirac acting in his capacity as CEO.\n"}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Vous pouvez contacter Digital Africa, à l’adresse mail suivante: Fuzé\n" +
              "          @digital-africa.co": "You can contact Digital Africa at the following e-mail address: fuze@digital-africa.co"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Ce site web est hébergé par la Google, les données sont conservées et\n" +
            "        traitées en Europe en conformité avec le Règlement Général sur la\n" +
            "        Protection des Données.": "This website is hosted by the Google, data is stored and processed in Europe in\n" +
            "compliance with the General Data Protection Regulation.\n"}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Objet": "Object"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Digital Africa lance Fuzé , un dispositif d’investissement pilote de 6,5\n" +
            "        ME, répartis en petits tickets, pour soutenir les startups francophones\n" +
            "        aux stades de l’idéation et du MVP. Destinés aux startups opérant en\n" +
            "        Afrique francophone, il s’agit de proposer des tickets pouvant aller\n" +
            "        jusqu’à 50KE pour permettre à des équipes proposant des solutions\n" +
            "        technologiques au service de l’économie réelle d’amorcer leur activité\n" +
            "        et de se focaliser sur leurs efforts de développement. Les présentes\n" +
            "        conditions générales d’Utilisation visent à préciser les conditions de\n" +
            "        sélection des startup qui bénéficieront des financements du Programme\n" +
            "        Fuzé y compris le processus digitalisé de candidature, ainsi que celui\n" +
            "        des structures accompagnantes.": "Digital Africa launches FUZE, a 6.5 M Eur pilot investment scheme, divided into small tickets, to support startups at the ideation and MVP stages in Arica. \n" +
            "Aimed at startups operating in underserved ecosystems in Africa, the aim is to offer tickets of up to 100KE to enable teams offering technological solutions serving the real economy to kick-start their business and focus on their development efforts.\n" +
            "The purpose of these general terms and conditions of use is to specify the conditions for the\n" +
            "selection of start-ups that will benefit from FUZE Program funding, including the\n" +
            "digitalized application process, as well as that of the supporting structures.\n"}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Acceptation des CGU": "Acceptance of T&Cs"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "L’Utilisateur est réputé avoir pris connaissance des Conditions\n" +
            "        Générales d’utilisation (ci-après CGU) des opérations du Programme Fuzé\n" +
            "        , proposé par DIGITAL AFRICA et les avoir dûment acceptées. Les CGU sont\n" +
            "        mises à disposition de l’Utilisateur sur le site où elles sont\n" +
            "        directement consultables et imprimables par un lien direct en bas de la\n" +
            "        page d’accueil du site, mais peuvent également leur être communiquées\n" +
            "        sur demande écrite à l’adresse suivante : Fuzé @digital-africa.co Elles\n" +
            "        annulent et remplacent toutes les dispositions antérieures et\n" +
            "        constituent l’intégralité des droits et obligations des parties. L’envoi\n" +
            "        du formulaire d’acte de candidature implique l’acceptation entière et\n" +
            "        sans réserve des présentes CGU La non-acceptation des CGU conduit à\n" +
            "        annuler la demande de candidature de l’Utilisateur.": "The User is deemed to have read the General Conditions of Use (hereinafter \"GCU\") of the FUZE Program operations offered by DIGITAL AFRICA and to have duly accepted them.\n" +
            "The GCU are made available to the User on the site, where they can be consulted and\n" +
            "printed via a direct link at the bottom of the site's home page, but can also be sent to\n" +
            "them on written request to the following address: fuze@digital-africa.co\n" +
            "They supersede all previous provisions and constitute the entirety of the rights and obligations of the parties. Submission of the application form implies full and unreserved acceptance of these GTCU .\n" +
            "Non-acceptance of the GCU will result in cancellation of the User's application.\n"}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Opérations déployées dans le cadre du programme Fuzé": "FUZE program operations"}

        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Fuzé investit dans des startups fondées par des entrepreneurs\n" +
              "          africains.": "Fuzé invests in startups founded by African entrepreneurs."}


        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Les montants sont les suivants": "The amounts are as follows"}


        </Typography>
      </Box>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "Pour un ticket ideation (stage 1) : 20K Eur": "For an ideation ticket (stage 1): 20K Eur"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "Pour un ticket de follow on (stage 2) : 30K Eur": "For a follow-on ticket (stage 2): 30K Eur"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? " Possibilité d’investir directement un ticket de 50K Eur sous\n" +
                "            condition de l’existence d’un co-investisseur prêt à investir au\n" +
                "            même moment du décaissement d’un ticket phase 2 Fuzé .": "Possibility of directly investing a 50K Eur ticket, provided there is a co-investor ready to invest at the same time as the disbursement of a FUZE phase 2 ticket."}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "Il ne s’agit pas de dons ou de subventions remboursables mais bien\n" +
                "            d’investissement.": "Possibility of directly investing a ticket of 100K Eur provided there is significant traction or a co-investor ready to invest at the same time as disbursement.\n" +
                "These are not repayable grants or subsidies, but investments.\n"}


          </Typography>
        </li>
      </ul>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Pour identifier les start ups cibles, Fuzé s’appuie sur un réseau de\n" +
            "        partenaires de l’écosystème qui l'accompagne dans la détection de\n" +
            "        structures cibles d’investissement.": "To identify target start-ups, FUZE relies on a network of partners in the ecosystem to help it detect investment targets."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Conditions de sélection:": "Selection criteria:  "}


        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Pour les start ups": "For start-ups"}


        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Fuzé investit dans des startups fondées par des entrepreneurs\n" +
              "          africains.": "The selection criteria are as follows: "}


        </Typography>
      </Box>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "La startup doit être une entreprise dotée d’une personnalité morale,\n" +
                "            basée dans l’un des pays africains": "The startup must be a legal entity operating in an African country."}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "L’ un des fondateurs doit obligatoirement être ressortissant d’un\n" +
                "            pays africain": "One of the founders must be a national of an African country."}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "La startup doit présenter une Solution Tech ou une orientation\n" +
                "            technologique dans le business model": "The startup must present a Tech Solution or a technological orientation in its business model."}


          </Typography>
        </li>
        {language==="fr" ?
            <li>
              <Typography
                  fontFamily="Mont"
                  color="#000"
                  textAlign="start"
                  fontSize={width < 500 ? 12 : 16}
              >
                {language==="fr" ? "Durée d'existence de la startup : moins de 18 mois pour le premier \n" +
                    "            ticket de 20K euros. Cette condition ne s’appliquera pas aux startups \n" +
                    "            qui candidatent pour le deuxième tickets de 30K euros et le ticket \n" +
                    "            de 50K euros.": ""}


              </Typography>
            </li>
            : null}

      </ul>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Fuzé se focalise sur les secteurs d’investissement à fort impact\n" +
            "        susceptibles d’améliorer la qualité de vie des africains via les\n" +
            "        nouvelles technologies.": "FUZE focuses on high-impact investment sectors likely to improve the quality of life of Africans through new technologies. "}


      </Typography>

      <Box
        width="100%"
        display="flex"
        justifyContent="flex-start"
        fontSize={width < 500 ? 12 : 16}
      >
        <ul>

          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              {language==="fr" ? "Santé": "Health"}


            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              {language==="fr" ? "Accès à l’énergie": "Energy access"}


            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              {language==="fr" ? " Accès aux services financiers": "Access to financial services"}


            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              {language==="fr" ? "Mobilité": "Mobility "}


            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              {language==="fr" ? "Agriculture": "Agriculture "}


            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              {language==="fr" ? "Education": "Education "}


            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              {language==="fr" ? "FoodTech": "FoodTech"}


            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Mont"
              color="#000"
              textAlign="start"
              fontSize={width < 500 ? 12 : 16}
            >
              {language==="fr" ? "Logistique": "Logistique"}


            </Typography>
          </li>
        </ul>
      </Box>

      {language==="fr" ?
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >        {language==="fr" ? "Pour les structures d’accompagnement": ""}


        </Typography>
      </Box>
          : null
      }

      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >        {language==="fr" ? "Les parcours utilisateurs:": "User paths:"}


        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >        {language==="fr" ? "- Parcours de l’utilisateur startup": "- Start-up user journey "}


        </Typography>
      </Box>
      <Box maxWidth={width < 500 ? "100%" : "auto"} overflow="auto">
        <Table>
          <tr>
            <th style={{ backgroundColor: "#000" }}></th>
            <th>I.</th>
            <th>II.</th>
            <th>III.</th>
            <th>IV.</th>
          </tr>
          <tr>
            <th>        {language==="fr" ? "Étape clé": "Step"}
              </th>
            <th>        {language==="fr" ? "Candidature": "Application"}
              </th>
            <th>        {language==="fr" ? "Analyse": "Analysis"}
              </th>
            <th>        {language==="fr" ? "Comité interne": "Investment committee"}
              </th>
            <th>        {language==="fr" ? "Diligence approfondie et": "Due diligence & closing"}
              </th>
          </tr>
          <tr>
            <td>1</td>
            <td>
              {language==="fr" ? "La startup remplit le formulaire et teste son éligibilité avant de\n" +
                  "              déposer sa candidature.": "The startup fills in the form and tests its eligibility before submitting its application"}

            </td>
            <td>        {language==="fr" ? "L’équipe Fuzé analyse les candidatures éligibles": "The Fuzé team analyzes eligible applications"}
              </td>
            <td>        {language==="fr" ? "Le comité interne prend la décision d’investir ou non": "The internal committee decides whether or not to invest. "}
              </td>
            <td>        {language==="fr" ? "Due diligence: financière et légale.": "Financial and legal due diligence."}
              </td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              {language==="fr" ? "La structure d’accompagnement renseignée par la startup sera\n" +
                  "              notifiée de la candidature et sera invitée à remplir une fiche de\n" +
                  "              notation.": "The support structure provided by the startup will be notified of the application and will be invited to fill in a scoring form. "}


            </td>
            <td>
              {language==="fr" ? "L’avis de la structure d’accompagnement partenaire est pris en\n" +
                  "              compte dans l’évaluation des dossiers.": "The opinion of the partner support structure is taken into account in the evaluation of applications."}


            </td>
            <td>
              {language==="fr" ? "Un comité interne Fuzé sera le seul habilité à prendre une\n" +
                  "              décision sur l’investissement dans la startup analysée; sur la\n" +
                  "              base d’un processus d’analyse de risque et d’opportunité\n" +
                  "              garantissant la neutralité.": "An internal FUZE committee will be the only one empowered to make a decision on the investment in the start-up analyzed; based on a risk and opportunity analysis process guaranteeing neutrality. "}


            </td>
            <td>
              {language==="fr" ? "En phase de closing, d’autres documents seront demandés afin de\n" +
                  "              finaliser les diligences.": "During the closing phase, further documents will be requested to complete the due diligence."}


            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              {language==="fr" ? "Si la structure d’accompagnement est un partenaire Fuzé, elle\n" +
                  "              soumet sa fiche de notation. Sinon, elle doit être approuvé par\n" +
                  "              l’équipe Fuzé avant qu’elle puisse noter les startups.": "If the support structure is a Fuzé partner, it submits its rating sheet. Otherwise, it must be approved by the Fuzé team before startups can be rated."}


            </td>
            <td>
              {language==="fr" ? "Les startups accompagnées dans le cadre d’un programme\n" +
                  "              d’accompagnement viennent en premier ordre de priorité.": "Startups supported as part of a mentoring program are the top priority."}


            </td>
            <td>
              {language==="fr" ? "La start up sera notifiée de la décision du comité interne.": "The start-up will be notified of the internal committee's decision. "}
            </td>
            <td>        {language==="fr" ? "Signature d’un Accord d’Investissement Rapide.": "Signature of a Rapid Investment Agreement."}
              </td>
          </tr>
        </Table>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "- Parcours de l’utilisateur “partenaire”:": "- Partner user journey"}
        </Typography>
      </Box>
      <Box maxWidth={width < 500 ? "100%" : "auto"} overflow="auto">
        <Table>
          <tr>
            <th style={{ backgroundColor: "#000" }}></th>
            <th>I.</th>
            <th>II.</th>
            <th>III.</th>
            <th>IV.</th>
          </tr>
          <tr>
            <th>        {language==="fr" ? "Étape clé": "Step"}
              </th>
            <th>        {language==="fr" ? "Candidature": "Application"}
              </th>
            <th>        {language==="fr" ? "Evaluation": "Analysis"}
              </th>
            <th>        {language==="fr" ? "Contrat": "Investment committee"}
              </th>
            <th>        {language==="fr" ? "Interactions avec le partenaire": "Due diligence & closing"}
               </th>
          </tr>
          <tr>
            <td></td>
            <td>
              {language==="fr" ? "La structure d’accompagnement souhaitant devenir partenaire Fuzé ,\n" +
                  "              remplit un formulaire de candidature en ligne.": "The support structure wishing to become a FUZE partner fills in an online application form."}


            </td>
            <td>
              {language==="fr" ? "L’équipe Fuzé évalue la candidature de la structure en se basant\n" +
                  "              sur la pertinence et le track record.": "The Fuzé team evaluates the structure's application based on relevance and track record. "}


            </td>
            <td>
              {language==="fr" ? "Le partenaire sélectionné signera un Memorandum Of Understanding\n" +
                  "              (MOU) et aura accès à un espace dédié sur la plateforme Fuzé .": "The selected partner will sign a Memorandum Of Understanding (MOU) and will have access to a dedicated space on the FUZE platform. "}


            </td>
            <td>
              {language==="fr" ? "Toute startup candidate au programme Fuzé sera appelée à\n" +
                  "              renseigner un contact référent dans la structure d’accompagnement.\n" +
                  "              Cette dernière est automatiquement notifiée via email dès que la\n" +
                  "              startup dépose sa candidature. Les partenaires seront aussi\n" +
                  "              sollicités dans le cadre du follow-on ticket (Stage 2)": "All start-ups applying for the Fuzé program will be asked to provide a contact person in the support structure. This contact is automatically notified by email as soon as the startup submits its application."}


            </td>
          </tr>
        </Table>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Obligation de collaboration de bonne foi": "Obligation de collaboration de bonne foi"}


        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "La bonne exécution des CGU et le bon déroulement des opérations du\n" +
              "          Programme Fuzé nécessitent une collaboration loyale, active permanente\n" +
              "          entre DIGITAL AFRICA et l’UTILISATEUR et de bonne foi.": "The proper execution of the GTU and the smooth running of the FUZE Program require loyal, active and ongoing collaboration between DIGITAL AFRICA and the USER in good faith. "}


        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Par conséquent, DIGITAL AFRICA et l’utilisateur s’engagent à :": "Consequently, DIGITAL AFRICA and the USER agree to : "}


        </Typography>
      </Box>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? " s’impliquer activement dans l’exécution de leurs obligations,": "Be actively involved in the performance of their obligations,"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "s’abstenir de tout comportement susceptible d’affecter et/ou\n" +
                "            d’entraver l’exécution des opérations du Programme Fuzé": "Refrain from any behavior likely to affect and/or hinder the execution of the FUZE Program operations,"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "se fournir mutuellement dans un délai suffisant, compatible avec le\n" +
                "            bon respect des délais convenus, toutes informations et documents\n" +
                "            nécessaires à l’examen des candidatures,": "Provide each other with all the information and documents required for the examination of applications, within a sufficient period of time, compatible with the respect of agreed deadlines,"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "s’alerter mutuellement le plus vite possible en cas de difficulté et\n" +
                "            se concerter pour mettre en place la meilleure solution possible\n" +
                "            dans les meilleurs délais.": "To alert each other as soon as possible in the event of difficulties, and to work together to find the best possible solution as quickly as possible. "}


          </Typography>
        </li>
      </ul>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Obligation de l’Utilisateur": "Obligation of the User"}


        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Inscription pour candidater au Programme Fuzé": "Registration for the FUZE program"}


        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "- Pour la startup candidate": "- For the Start-up candidate"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "La startup aura accès au questionnaire en ligne via le site Fuzé . Un\n" +
            "        compte utilisateur sera créé pour le fondateur, qui recevra un\n" +
            "        identifiant et un mot de passe. L’Utilisateur est responsable du\n" +
            "        maintien de la confidentialité de son identifiant et mot de passe et\n" +
            "        reconnaît expressément que toute utilisation depuis son compte sera\n" +
            "        réputée avoir été effectuée par lui-même. Dans l’hypothèse où\n" +
            "        l’Utilisateur constate que son compte est ou a été utilisé à son insu,\n" +
            "        il s’engage à en avertir DIGITAL AFRICA dans les plus brefs délais, à\n" +
            "        l’adresse email de contact Fuzé @digital-africa.co.": "The start-up will have access to the online questionnaire via the FUZE website.\n" +
            "A user account will be created for the founder, who will receive a login and password. The User is responsible for maintaining the confidentiality of his/her login and password, and expressly acknowledges that any use of his/her account will be deemed to have been made by him/her. In the event that the User notices that his account is being or has been used without his knowledge, he undertakes to notify DIGITAL AFRICA as soon as possible, at the contact email address fuze@digital-africa.co.\n"}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "- Pour le Partenaire candidat": "- For the Applicant Partner"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Un compte utilisateur sera créé pour le Partenaire. Il recevra un\n" +
            "        identifiant et un mot de passe. Le partenaire bénéficiera d’un espace\n" +
            "        personnel où il pourra accéder à la liste des startups candidates qu’il\n" +
            "        accompagne ainsi qu’au scoring sheets qui lui permettront de noter les\n" +
            "        startups candidates.": "A user account will be created for the Partner. They will receive a login and password. The\n" +
            "partner will have a personal space where he can access the list of candidate startups he is\n" +
            "supporting, as well as the scoring sheets that will enable him to rate the candidate\n" +
            "startups."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "- Obligation de loyauté": "- Duty of loyalty"}

        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "L’Utilisateur s’engage à respecter les principes de loyauté,\n" +
            "        transparence dans ses relations avec DIGITAL AFRICA Toute information\n" +
            "        fournie par l’Utilisateur dans le cadre de la sélection et de manière\n" +
            "        générale dans tout échange avec l’équipe du Programme Fuzé et DIGITAL\n" +
            "        AFRICA, doit être licite, authentique, loyale, actualisée et refléter la\n" +
            "        réalité.": "The User agrees to respect the principles of loyalty and transparency in its relations with\n" +
            "DIGITAL AFRICA.\n" +
            "All information provided by the User as part of the selection process and in general in any\n" +
            "exchange with the FUZE and DIGITAL AFRICA Program team, must be lawful, authentic,\n" +
            "fair, up-to-date and reflect reality."}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "L’Utilisateur s’engage à ne pas communiquer un contenu protégé par un\n" +
            "        droit de propriété intellectuelle dont l’Utilisateur n’est pas titulaire\n" +
            "        des droits. L’Utilisateur s’engage à coopérer de bonne foi lors des\n" +
            "        vérifications (due diligence) effectuées par l’équipe du Programme Fuzé": "The User undertakes not to communicate any content protected by intellectual property\n" +
            "rights of which the User is not the owner."}


        .
      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Toute communication de contenu illicite entraînera la résiliation de la\n" +
            "        relation contractuelle.": "The User agrees to cooperate in good faith during due diligence by the FUZE Program team.\n" +
            "Any communication of unlawful content will result in termination of the contractual relationship.\n"}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "En conséquence, l’Utilisateur s’engage à indemniser DIGITAL AFRICA de\n" +
            "        tout préjudice qu’elle subirait en cas de manquement à ses obligations,\n" +
            "        et à prendre à sa charge tous les dommages-intérêts, ainsi que les\n" +
            "        frais, charges et dépens auxquels DIGITAL AFRICA pourrait être condamné.": "Consequently, the User agrees to compensate DIGITAL AFRICA for any damages it may suffer in the event of a breach of its obligations, and to assume all damages, as well as\n" +
            "costs, charges and expenses to which DIGITAL AFRICA may be condemned."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "- Comportements prohibés": "- Prohibited behaviour"}

        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Le Partenaire Fuzé n’agit pas en qualité de mandataire de DIGITAL\n" +
            "        AFRICA, dès lors, il ne peut s’engager vis-à-vis de la startup\n" +
            "        sélectionnée au nom et pour le compte de DIGITAL AFRICA.": "The FUZE Partner does not act as an agent for DIGITAL AFRICA, and therefore cannot commit to the selected startup in the name and on behalf of DIGITAL AFRICA."}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Il est strictement interdit à l’Utilisateur d’utiliser les\n" +
            "        investissements du programme Fuzé pour": "It is strictly forbidden for the User to use the investments of the FUZE program to"}


      </Typography>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "l’exercice d’une activité illégale, frauduleuse ou portant atteinte\n" +
                "            aux droits ou à la sécurité des tiers ,": "engaging in illegal or fraudulent activities, or activities that infringe on the rights or\n" +
                "safety of third parties,"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "l’atteinte à l’ordre public ou la violation des lois et règlements\n" +
                "            en vigueur,": "undermining public order or violating applicable laws and regulations,"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "l’intrusion dans le système informatique d’un tiers ou toute\n" +
                "            activité de nature à nuire, contrôler, interférer, ou intercepter\n" +
                "            tout ou partie du système informatique d’un tiers, en violer\n" +
                "            l’intégrité ou la sécurité,": "intrusion into a third party's computer system or any activity of a nature to harm,\n" +
                "control, interfere with, or intercept all or part of a third party's computer system, violate its\n" +
                "integrity or security,"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "l’aide ou l’incitation, sous quelque forme et de quelque manière que\n" +
                "            ce soit, à un ou plusieurs des actes et activités décrits ci-dessus,": "aiding or abetting, in any form or manner whatsoever, one or more of the acts and\n" +
                "activities described above,"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "plus généralement toute pratique détournant les Services à des fins\n" +
                "            autres que celles pour lesquelles ils ont été conçus ;": "more generally, any practice that misuses the Services for purposes other than those for\n" +
                "which they were designed;"}


          </Typography>
        </li>
      </ul>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Pour tout manquement aux présentes CGU.ou de violation répétée de\n" +
            "        celles-ci, DIGITAL AFRICA se réserve le droit de suspendre l’accès de\n" +
            "        l'utilisateur, auteur du manquement, à tout ou partie des opérations de\n" +
            "        Fuzé , avec effet immédiat.": "DIGITAL AFRICA reserves the right to suspend the user's access to all or part of FUZE's\n" +
            "operations, with immediate effect, in the event of any breach or repeated breach of\n" +
            "these GTUs."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          fontWeight={600}
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "- Suspension temporaire d’accès au compte Utilisateur ou Fermeture\n" +
              "          définitive": "- Temporary suspension of access to the User account or permanent closure"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "En cas de manquement aux obligations des CGU, DIGITAL AFRICA se réserve\n" +
            "        la possibilité de suspendre l’accès au compte utilisateur ou de le\n" +
            "        fermer définitivement en cas de manquement grave constaté aux présentes\n" +
            "        CGU. DIGITAL AFRICA s’engage à notifier par courriel au titulaire du\n" +
            "        compte les griefs retenus à son encontre et la motivation de la décision\n" +
            "        soit de suspension temporaire de l’accès au compte utilisateur, soit sa\n" +
            "        fermeture définitive.": "DIGITAL AFRICA reserves the right to suspend access to the user account or to close it\n" +
            "permanently in the event of a serious breach of the GTU.\n" +
            "DIGITAL AFRICA undertakes to notify the account holder by e-mail of the grievances against him and the reasons for the decision either to temporarily suspend access to the user account, or its permanent closure."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Obligation de Digital Africa:": "Obligation of Digital Africa:"}


        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "- Devoir de transparence": "- Duty of transparency"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "DIGITAL AFRICA est soumis à une obligation de transparence sur la\n" +
            "        procédure de sélection des utilisateurs. Si l’équipe du Programme Fuzé\n" +
            "        considère que les critères de sélection ne sont pas satisfaits, elle en\n" +
            "        informe le candidat par courriel et motive le refus de la candidature\n" +
            "        sur demande. Si la startup ne remplit pas les critère primaires\n" +
            "        d’éligibilité, elle sera informée automatiquement via la plateforme à la\n" +
            "        fin du questionnaire. Il sera indiqué dans quel délai la startup pourra\n" +
            "        re-candidater en fonction du défaut de compliance aux critères primaires\n" +
            "        d’éligibilité.": "DIGITAL AFRICA has an obligation of transparency regarding the user selection procedure. If the FUZE Program team considers that the selection criteria have not been met, it will inform the applicant by e-mail, giving reasons for refusal of the application on request.\n" +
            "\n" +
            "If the start-up does not meet the primary eligibility criteria, it will be informed automatically via the platform at the end of the questionnaire. It will be indicated how soon the\n" +
            "start-up will be able to reapply, depending on its failure to comply with the primary\n" +
            "eligibility criteria."}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "La structure accompagnante, dont la candidature a été rejetée, sera\n" +
            "        automatiquement informée de la décision de refus et des fondements de\n" +
            "        son rejet, via la plateforme. Il sera indiqué dans quel délai, elle\n" +
            "        pourra re-candidater en fonction des motifs de refus.": "The supporting structure whose application has been rejected will automatically be\n" +
            "informed of the rejection decision and the reasons for it, via the platform. It will be\n" +
            "informed of the deadline for reapplying, based on the reasons for rejection."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start" pl={10}>
        <Typography
          fontFamily="Mont"
          color="#000"
          fontWeight={600}
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "- Limitation de responsabilité de DIGITAL AFRICA": "- Limitation of liability of DIGITAL AFRICA"}

        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "DIGITAL AFRICA s’engage à faire les meilleurs efforts pour apporter tout\n" +
            "        le soin possible à l’exécution de ses obligations conformément à l’état\n" +
            "        de l’Art et dans le respect des réglementations applicables, ce que\n" +
            "        l’Utilisateur reconnaît et accepte expressément.": "DIGITAL AFRICA undertakes to use its best efforts to perform its obligations in\n" +
            "accordance with the state of the art and in compliance with applicable regulations,\n" +
            "which the User expressly acknowledges and accepts."}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Sa responsabilité est exclusivement limitée à la fourniture des\n" +
            "        Opérations selon les modalités décrites aux présentes, à l’exclusion de\n" +
            "        toute autre prestation.": "Sa responsabilité est exclusivement limitée à la fourniture des\n" +
            "        Opérations selon les modalités décrites aux présentes, à l’exclusion de\n" +
            "        toute autre prestation."}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "En aucun cas, DIGITAL AFRICA ne pourra être tenue pour responsable tant\n" +
            "        à l’égard de l’Utilisateur qu’à l’égard de ses partenaires et des tiers,\n" +
            "        pour tout dommage imprévisible ou pour tout dommage indirect, qu’il soit\n" +
            "        matériel ou immatériel, causé par l'utilisation de la plateforme de\n" +
            "        candidature utilisée par l’Utilisateur ainsi que toute perte ou\n" +
            "        détérioration d’informations pour lesquelles DIGITAL AFRICA ne peut être\n" +
            "        tenue pour responsable. Tout dommage subi par un tiers est un dommage\n" +
            "        indirect et ne donne pas lieu en conséquence à indemnisation. De manière\n" +
            "        expresse, DIGITAL AFRICA et l’Utilisateur conviennent que les\n" +
            "        limitations de responsabilité continuent à s’appliquer même en cas de\n" +
            "        résiliation des CGU": "In no event will DIGITAL AFRICA be liable to the User, its partners or third parties for\n" +
            "any unforeseeable damage or indirect damage, whether material or immaterial, caused by the use of the application platform used by the User, nor for any loss or deterioration of\n" +
            "information for which DIGITAL AFRICA cannot be held responsible.\n" +
            "\n" +
            "Any damage suffered by a third party is indirect damage and does not give\n" +
            "rise to compensation.\n" +
            "DIGITAL AFRICA and the User expressly agree that the limitations of liability shall\n" +
            "continue to apply even in the event of termination of the GCU."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Protection de la propriété intellectuelle": "Protection of intellectual property"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "L'utilisateur conserve la propriété intellectuelle de ses actifs\n" +
            "        immatériels. Aucune propriété intellectuelle n’est concédée à Digital\n" +
            "        Africa, sauf convention contraire entre les Parties.": "The user retains the intellectual property of its intangible assets. No intellectual property is\n" +
            "granted to Digital Africa, unless otherwise agreed between the Parties."}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "DIGITAL AFRICA détient les droits de propriété intellectuelle sur les\n" +
            "        formulaires et tout document du programme Fuzé . Elle est également\n" +
            "        producteur de base de données, comme précisé ci-après.": "DIGITAL AFRICA holds the intellectual property rights to the forms and all documents of\n" +
            "the FUZE program. It is also the producer of the database, as specified below."}


      </Typography>

      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Politique de protection des données à caractère personnel": "Personal data protection policy"}

        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Les informations transmises par l’Utilisateur dans le cadre de l’examen\n" +
            "        de sa candidature et dans les échanges avec l’équipe du Programme Fuzé\n" +
            "        susceptibles d’être considérées comme des données à caractère personnel\n" +
            "        au sens du Règlement Général sur la protection des données à caractère\n" +
            "        personnel (RGPD) seront traitées conformément à la politique de\n" +
            "        protection des données personnelles de DIGITAL AFRICA.": "Information transmitted by the User as part of the examination of his/her application and in\n" +
            "exchanges with the FUZE Program team that may be considered personal data within the\n" +
            "meaning of the General Regulation on the Protection of Personal Data (RGPD) will be\n" +
            "processed in accordance with DIGITAL AFRICA's personal data protection policy."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Confidentialité": "Privacy"}

        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Toutes les informations, toutes les données, tous les livrables et/ou\n" +
            "        tout le savoir-faire, couverts ou non par les lois de propriété\n" +
            "        intellectuelle, quelle qu’en soient la forme et la nature (commerciale,\n" +
            "        industrielle, technique, financière, etc…), échangées entre les Parties\n" +
            "        ou dont elles auraient connaissance lors de la phase de sélection et\n" +
            "        l’implémentation du Programme Fuzé seront considérées comme\n" +
            "        confidentielles (ci-après les “Informations Confidentielles”). Chacune\n" +
            "        des Parties s’engage à n’utiliser les Informations Confidentielles que\n" +
            "        dans le cadre de l’exécution du Programme Fuzé , à protéger les\n" +
            "        Informations Confidentielles et à ne pas les divulguer à des tiers\n" +
            "        autres que ses employés, collaborateurs, filiales et sous- traitants\n" +
            "        ayant besoin d’en connaître pour l’exécution des CGU sans l’autorisation\n" +
            "        préalable et écrite de l’autre Partie. Les Parties s’engagent à prendre\n" +
            "        toutes les mesures nécessaires pour faire en sorte que leurs employés,\n" +
            "        collaborateurs, filiales et sous-traitants ayant accès aux Informations\n" +
            "        Confidentielles soient informés du caractère confidentiel des\n" +
            "        informations communiquées et respectent les obligations découlant de la\n" +
            "        présente clause.": "All information, data, deliverables and/or know-how, whether or not covered by intellectual\n" +
            "property laws, of whatever form and nature (commercial, industrial, technical, financial, etc.),\n" +
            "exchanged between the Parties or of which they become aware during the selection and\n" +
            "implementation phase of the FUZE Program will be considered confidential (hereinafter\n" +
            "the \"Confidential Information\").\n" +
            "\n" +
            "Each of the Parties undertakes to use the Confidential Information only in the context of the\n" +
            "execution of the FUZE Program, to protect the Confidential Information and not to disclose\n" +
            "it to third parties other than its employees, collaborators, subsidiaries and subcontractors who need to know it for the execution of the GCU without the prior written authorization of the other Party.\n" +
            "\n" +
            "The Parties undertake to take all necessary measures to ensure that their employees,\n" +
            "collaborators, subsidiaries and subcontractors having access to Confidential\n" +
            "Information are informed of the confidential nature of the information communicated and\n" +
            "respect the obligations arising from this clause.\n"}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Chacune des Parties sera dégagée de ses obligations de confidentialité\n" +
            "        en ce qui concerne toutes les informations :": "Each of the Parties shall be released from its confidentiality obligations with respect to\n" +
            "all information :\n"}


      </Typography>
      <ol>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "qui étaient en possession de cette Partie avant leur divulgation par\n" +
                "            l’autre Partie sans qu’une telle possession ne résulte directement\n" +
                "            ou indirectement de la divulgation non autorisée de ces informations\n" +
                "            par un tiers,": "which was in the possession of that Party prior to its disclosure by the other Party\n" +
                "without such possession resulting directly or indirectly from the unauthorized\n" +
                "disclosure of such information by a third party,"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "qui font partie du domaine public à la date d’acceptation du Contrat\n" +
                "            ou qui tomberaient dans le domaine public après cette date sans que\n" +
                "            la cause en soit imputable au non- respect par cette Partie de ses\n" +
                "            obligations de confidentialité au titre des CGU,": "which are part of the public domain at the date of acceptance of the Contract or which\n" +
                "would fall into the public domain after this date without the cause being\n" +
                "attributable to the non-observance by this Party of its confidentiality obligations\n" +
                "under the GCU,"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "qui ont été élaborées de façon indépendante par cette Partie,": "which have been developed independently by this Part,"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "dont la divulgation est exigée par la loi ou une autorité judiciaire\n" +
                "            ou administrative compétente ou est nécessaire à la défense des\n" +
                "            intérêts de l’une ou l’autre des Parties dans le cadre d’une action\n" +
                "            judiciaire.": "the disclosure of which is required by law or by a competent judicial or administrative\n" +
                "authority or is necessary to defend the interests of either Party in a legal proceeding."}


          </Typography>
        </li>
      </ol>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Les Parties s’engagent à respecter les obligations résultant du présent\n" +
            "        article pendant toute la durée du Programme Fuzé ainsi que pendant 1 an\n" +
            "        suivant sa cessation. En aucun cas, une copie des documents contenant\n" +
            "        des informations confidentielles ne pourra être conservée par une Partie\n" +
            "        sauf accord exceptionnel et écrit de l’autre Partie.": "The Parties undertake to respect the obligations resulting from the present article throughout the duration of the FUZE Program and for 1 year following its termination.\n" +
            "Under no circumstances may a copy of documents containing confidential information be kept by a Party without the exceptional written agreement of the other Party."}


      </Typography>

      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Base de données": "Database"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Les données transmises par l’Utilisateur pour examiner sa candidature au\n" +
            "        Programme Fuzé sont conservées en base active pendant toute la durée du\n" +
            "        parcours de l'utilisateur, jusqu’à la sélection finale. En cas de\n" +
            "        sélection, l’ensemble des données seront conservées pendant toute la\n" +
            "        durée du programme et pendant 6 mois à l’expiration des opérations du\n" +
            "        programme à titre probatoire. Elles seront ensuite hébergées dans une\n" +
            "        base de données, dont Digital Africa, est le producteur de base de\n" +
            "        données au sens de l’article L 341-1 du Code de la Propriété\n" +
            "        Intellectuelle (CPI). Toutes les données à caractère personnel seront\n" +
            "        supprimées. Les données protégées par un droit de propriété\n" +
            "        intellectuelle ne pourront pas être intégrées à la base, sauf si\n" +
            "        l’Utilisateur cède les droits d’exploitation à DIGITAL AFRICA.": "The data transmitted by the User to examine his/her application to the FUZE Program is\n" +
            "kept in an active database for the duration of the User's journey, until the final selection.\n" +
            "In the event of selection, all data will be kept for the duration of the program, and\n" +
            "for 6 months after the end of the program's operations, for probationary purposes.\n" +
            "They will then be hosted in a database, of which Digital Africa is the database producer within the meaning of article L 341-1 of the French Intellectual Property Code (CPI). All personal data will be deleted. Data protected by an intellectual property right cannot be integrated into the database, unless the User transfers the exploitation rights to DIGITAL AFRICA.\n"}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#000"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Elles pourront être traitées par DIGITAL AFRICA pour les finalités\n" +
              "          suivantes :": "It may be processed by DIGITAL AFRICA for the following purposes:"}


        </Typography>
      </Box>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "L’amélioration de la prédictibilité des programmes d’investissement\n" +
                "            soutenus par Digital Africa, notamment par l’utilisation d’outils\n" +
                "            d’intelligence artificielle ;": "Improving the predictability of investment programs supported by Digital Africa, in\n" +
                "particular through the use of artificial intelligence tools;"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "L’amélioration qualitative et quantitative de la définition des\n" +
                "            politiques publiques d’investissement dans le cadre de son périmètre\n" +
                "            :": "Improving the quality and quantity of public investment policies within its scope:"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "L’amélioration de la connaissance du marché et des besoins des\n" +
                "            acteurs.": "Improving knowledge of the market and players' needs."}


          </Typography>
        </li>
      </ul>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "En aucun cas l’Utilisateur ne pourra revendiquer un droit de propriété\n" +
            "        intellectuelle sur les documents de la base de données. Les candidatures\n" +
            "        refusées seront conservées pendant une durée de 2 ans afin d’affiner les\n" +
            "        critères de sélection du Programme Fuzé et d’optimiser la procédure de\n" +
            "        sélection des candidats à ce programme. A l’issue de cette période, les\n" +
            "        candidatures refusées seront anonymisées.": "Under no circumstances may the User claim any intellectual property rights over the documents\n" +
            "in the database.\n" +
            "Rejected applications will be kept for a period of 2 years in order to refine the selection\n" +
            "criteria for the FUZE program and optimize the selection procedure for applicants to\n" +
            "this program. At the end of this period, rejected applications will be anonymized.\n"}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Un droit de rectification peut être exercé en contactant DIGITAL AFRICA\n" +
            "        lorsque :": "A right of rectification can be exercised by contacting DIGITAL AFRICA when :"}


      </Typography>

      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? " une erreur ou une inexactitude est avérée,": "a proven error or inaccuracy,"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "une actualisation ou une complétion est nécessaire": "an update or completion is required"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "la présence de données dont la collecte, l’utilisation, la\n" +
                "            communication ou la conservation est jugée illicite": "the presence of data whose collection, use, communication or storage is deemed\n" +
                "unlawful"}


          </Typography>
        </li>
      </ul>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Convention de preuve": "Proof agreement"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "L’Utilisateur reconnaît que les fichiers électroniques (comprenant les\n" +
            "        informations fournies durant le parcours d’inscription) ainsi que les\n" +
            "        courriels seront considérés comme les preuves consenties des échanges et\n" +
            "        des communications entre l'Utilisateur et DIGITAL AFRICA. L’acceptation\n" +
            "        du traitement des informations communiquées, cadrées dans le temps et\n" +
            "        dans la limite de l’activité décrite par DIGITAL AFRICA est conditionnée\n" +
            "        à la validation de ces CGU. L’Utilisateur s’engage à ne pas contester\n" +
            "        leur force probante et reconnaît la même valeur probante aux écrits et\n" +
            "        documents électroniques transmis dans le cadre de l’implémentation des\n" +
            "        CGU qu’aux écrits et documents papiers. L’Utilisateur reconnaît et\n" +
            "        accepte, qu’après authentification, toute manifestation de volonté par\n" +
            "        le biais de l’utilisation des fonctionnalités proposées par la\n" +
            "        Plateforme DIGITAL AFRICA constitue une signature électronique et\n" +
            "        caractérise la preuve du consentement au sens des dispositions de\n" +
            "        l’article 1353 Code Civil.": "The User acknowledges that electronic files (including information provided during the\n" +
            "registration process) as well as emails will be considered as agreed proof of exchanges and\n" +
            "communications between the User and DIGITAL AFRICA.\n" +
            "Acceptance of the processing of the information communicated, framed in time and within the limits of the activity described by DIGITAL AFRICA, is conditional upon validation of these GCU.\n" +
            "The User undertakes not to contest their probative value and acknowledges that electronic\n" +
            "writings and documents transmitted within the framework of the implementation of the GCU\n" +
            "have the same probative value as paper writings and documents.\n" +
            "The User acknowledges and accepts that, after authentication, any expression of will through the use of the functionalities offered by the DIGITAL AFRICA Platform constitutes an electronic signature and characterizes proof of consent within the meaning of the provisions of Article 1353 of the French Civil Code.\n"}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Durée": "Duration"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Les présentes CGU s’appliquent pendant toute la durée du Programme Fuzé\n" +
            "        et tout au long de la dernière mise en oeuvre et jusqu’à sa\n" +
            "        finalisation.": "The present Terms and Conditions apply for the duration of the FUZE Program and throughout its final implementation and completion."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Modifications des CGU des opérations Fuzé": "Modifications to the Terms and Conditions of FUZE operations"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "DIGITAL AFRICA se réserve la faculté de modifier à tout moment les\n" +
            "        présentes conditions générales. L’Utilisateur sera informé des\n" +
            "        modifications des CGU par mail, au moins 15 jours avant leur entrée en\n" +
            "        vigueur. Les conditions générales ainsi modifiées s’appliqueront\n" +
            "        immédiatement à tout Utilisateur dès leur mise en ligne sur la\n" +
            "        Plateforme. Tout utilisateur qui a recours à la plateforme\n" +
            "        postérieurement à l’entrée en vigueur des conditions générales modifiées\n" +
            "        est réputé avoir accepté ces modifications qui lui seront pleinement\n" +
            "        applicables.": "DIGITAL AFRICA reserves the right to modify these terms and conditions at any time.\n" +
            "The User will be informed of modifications to the GCU by e-mail, at least 15 days before\n" +
            "they come into force. The modified terms and conditions will apply immediately to all Users\n" +
            "as soon as they are posted on the Platform.\n" +
            "Any user who uses the platform after the entry into force of the modified general terms and\n" +
            "conditions is deemed to have accepted these modifications, which will be fully applicable.\n"}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >

          {language==="fr" ? "Force majeure": "Force majeure"}

        </Typography>
      </Box>
      <ul>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >

            {language==="fr" ? "Événement de force majeure: Aucune des Parties ne pourra être tenue\n" +
                "            pour responsable d’un manquement quelconque à ses obligations\n" +
                "            contractuelles, si elle a été empêchée d’exécuter son obligation par\n" +
                "            un événement de force majeure tel que défini à l’article 1218 du\n" +
                "            Code civil.": "Force majeure event\n" +
                "Neither party may be held liable for any breach of its contractual\n" +
                "obligations if it has been prevented from fulfilling its obligation by an event of\n" +
                "force majeure as defined in article 1218 of the French Civil Code."}

          </Typography>
        </li>
        {language==="fr" ?
            <li>
              <Typography
                  fontFamily="Mont"
                  color="#000"
                  textAlign="start"
                  fontSize={width < 500 ? 12 : 16}
              >
                {language==="fr" ? "": ""}

                qui font partie du domaine public à la date d’acceptation du Contrat
                ou qui tomberaient dans le domaine public après cette date sans que
                la cause en soit imputable au non- respect par cette Partie de ses
                obligations de confidentialité au titre des CGU,
              </Typography>
            </li>: null}

        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "Notification: Dans ce cas, la Partie invoquant la force majeure\n" +
                "            notifiera à l’autre Partie, par lettre recommandée avec accusé de\n" +
                "            réception, dans les meilleurs délais, la survenance d’un tel\n" +
                "            événement et la nécessaire extension des dates limites d’exécution\n" +
                "            de ses obligations.": "Notification\n" +
                "In this case, the Party invoking force majeure will notify the other Party, by registered\n" +
                "letter with acknowledgement of receipt, as soon as possible, of the occurrence of\n" +
                "such an event and the necessary extension of the deadlines for performance of\n" +
                "its obligations.\n"}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "Empêchement temporaire: Si l’empêchement est temporaire, l’exécution\n" +
                "            de l’obligation s’en trouvera suspendue jusqu’à que la Partie\n" +
                "            invoquant la force majeure ne soit plus empêchée par l'événement de\n" +
                "            force majeure. La Partie invoquant la force majeure devra tenir\n" +
                "            l’autre Partie informée et s’engage à faire de son mieux pour\n" +
                "            limiter la durée de la suspension.": "Temporary impediment\n" +
                "If the impediment is temporary, performance of the obligation will be suspended until the\n" +
                "Party invoking force majeure is no longer prevented by the force majeure event. The Party\n" +
                "invoking force majeure shall keep the other Party informed and undertakes to do\n" +
                "its best to limit the duration of the suspension."}


          </Typography>
        </li>
        <li>
          <Typography
            fontFamily="Mont"
            color="#000"
            textAlign="start"
            fontSize={width < 500 ? 12 : 16}
          >
            {language==="fr" ? "Résiliation pour Force Majeure: Dans le cas où la suspension se\n" +
                "            poursuivrait au-delà d’un délai de trois (3) mois, chacune des\n" +
                "            Parties aura la possibilité de résilier les CGU sans indemnité en\n" +
                "            notifiant à l’autre Partie sa décision par lettre recommandée avec\n" +
                "            accusé de réception. Si l’empêchement est définitif, les CGU sont\n" +
                "            résiliées de plein droit et les parties sont libérées de leurs\n" +
                "            obligations dans les conditions prévues à l’article 1351-1 du Code\n" +
                "            civil.": "Termination for Force Majeure\n" +
                "Should the suspension continue beyond a period of three (3) months, each Party will\n" +
                "have the option of terminating the GCU without compensation by notifying the other Party\n" +
                "of its decision by registered letter with acknowledgement of receipt.\n" +
                "If the impediment is definitive, the GCU are terminated ipso jure and the parties are released\n" +
                "from their obligations under the conditions set out in article 1351-1 of the French Civil\n" +
                "Code."}


          </Typography>
        </li>
      </ul>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Renonciation": "Waiver"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Le fait pour l’une des Parties de ne pas se prévaloir de l’une\n" +
            "        quelconque des obligations visées aux CGU ne saurait être interprété\n" +
            "        ultérieurement comme une renonciation à l’obligation en cause.\n" +
            "        L’Utilisateur final renonce irrévocablement à toute demande,\n" +
            "        réclamation, droit ou action à l’encontre de DIGITAL AFRICA et qui\n" +
            "        serait formulée plus de douze (12) mois après le fait générateur.": "The fact that one of the Parties does not avail itself of any of the obligations referred to in\n" +
            "the GTU shall not be interpreted subsequently as a waiver of the obligation in question. The\n" +
            "End User irrevocably waives any request, claim, right or action against DIGITAL AFRICA that would be formulated more than twelve (12) months after the generating event."}


      </Typography>

      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Intégralité": "Complete"}

        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Les Conditions Générales d’Utilisation expriment l’intégralité des\n" +
            "        obligations des parties. En cas de difficultés d’interprétation entre\n" +
            "        l’un quelconque des titres figurant en tête des clauses, et l’une\n" +
            "        quelconque des clauses, les titres seront déclarés inexistants.": "The General Conditions of Use express the entirety of the obligations of the parties. In the event of difficulties of interpretation between any of the titles appearing at the head of the clauses, and any of the clauses, the titles will be declared non-existent."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Nullité": "Nullity"}

        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Si une ou plusieurs stipulations des CGU étaient tenues pour non valides\n" +
            "        ou déclarées comme telle en application d’une loi ou à la suite d’une\n" +
            "        décision définitive d’une juridiction compétente, les autres\n" +
            "        stipulations garderont leurs forces et portées.": "If one or more stipulations of the GCU are held to be invalid or declared as such in\n" +
            "application of a law or following a final decision by a competent court , the other stipulations will retain their force and effect."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Notification": "Notification"}


        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Toutes les notifications, pour être valides, devront avoir été\n" +
            "        effectuées à l’adresse de domiciliation par lettre recommandée avec\n" +
            "        accusé de réception.": "To be valid, all notifications must be sent to the registered address by registered\n" +
            "letter with acknowledgement of receipt."}


      </Typography>
      <Box width="100%" display="flex" justifyContent="flex-start">
        <Typography
          fontFamily="Mont"
          color="#EF4B12"
          textAlign="start"
          fontSize={width < 500 ? 12 : 16}
        >
          {language==="fr" ? "Loi et attribution de juridiction": "Law and jurisdiction"}
        </Typography>
      </Box>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Les Conditions Générales d’Utilisation sont régies par la loi française.\n" +
            "        En cas de litige et après tentative de procédure amiable, compétence\n" +
            "        expresse est attribuée au Tribunal du siège social de DIGITAL AFRICA\n" +
            "        nonobstant la pluralité de défendeur ou appel en garantie, y compris\n" +
            "        pour les procédures sur requête ou d’urgence.": "The General Conditions of Use are governed by French law.\n" +
            "In the event of a dispute and after an attempt at amicable settlement, jurisdiction is expressly\n" +
            "attributed to the Court of the registered office of DIGITAL AFRICA notwithstanding the\n" +
            "plurality of defendants or the introduction of third parties, including for proceedings\n" +
            "by petition or emergency."}


      </Typography>
      <Typography
        fontFamily="Mont"
        color="#000"
        textAlign="start"
        fontSize={width < 500 ? 12 : 16}
      >
        {language==="fr" ? "Copyright © 2022 Digital Africa. Tous droits réservés.": "Copyright © 2024 Digital Africa. All rights reserved."}


      </Typography>
    </>
  );
};

export default CGU;
