import {
  Box,
  BoxProps,
  Paper,
  PaperProps,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import theme from "../../../theme";

export const Container = styled(Box)<BoxProps>`
  display: "flex";
  flex-direction: "column";
  flex: 1;
  padding: ${theme.spacing(2, 10, 10, 10)};
  height: "100vh";
  @media screen and (max-width: 490px) {
    padding: ${theme.spacing(2, 2, 10, 2)};
  }
`;

export const FormContainer = styled(Paper)<PaperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: ${theme.spacing(6)};
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  @media screen and (max-width: 490px) {
    padding: ${theme.spacing(3)};
  }
`;
export const BottunsContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  position: "absolute",
  width: "90%",
  padding: theme.spacing(6),
  bottom: 0,
}));

export const ButtonText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: "#616161",
  marginTop: "-3px",
}));
