function ConfirmInput() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#EC6D3F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10z"
      ></path>
      <path
        stroke="#EC6D3F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.75 12l2.83 2.83 5.67-5.66"
      ></path>
    </svg>
  );
}

export default ConfirmInput;
