import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "../AuthContext";
//types
import {
  StepperContextProps,
  StepperProviderProps,
} from "./stpperContect.types";

let StepperContext = createContext<StepperContextProps>(null!);

const StepperProvider = (props: StepperProviderProps) => {
  const { children } = props;
  const auth = useAuth();
  //state
  const [steps, setSteps] = useState<Array<String>>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  useEffect(() => {
    if (!auth.token) {
      setCompleted({});
    }
  }, [auth]);

  //functions
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleUpdateSteps = (steps: Array<string>) => {
    setSteps(steps);
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    delete completed[activeStep - 1];
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleUpdateComplete = (completed: { [k: number]: boolean }) => {
    setCompleted(completed);
  };

  let value = {
    activeStep,
    completed,
    handleUpdateSteps,
    handleComplete,
    handleStep,
    handleBack,
    handleUpdateComplete,
  };
  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  );
};

const useStepperContext = () => {
  return useContext(StepperContext);
};

export { StepperProvider, useStepperContext };
