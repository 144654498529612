function ToFAQ() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 80 80"
    >
      <circle cx="40" cy="40" r="40" fill="#F9BC4A" opacity="0.1"></circle>
      <path
        stroke="#F9BC4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M54.583 33.75c0 3.02-.895 5.792-2.437 8.104-2.25 3.334-5.813 5.688-9.959 6.292-.708.125-1.437.187-2.187.187-.75 0-1.48-.062-2.188-.187-4.145-.604-7.708-2.959-9.958-6.292a14.517 14.517 0 01-2.437-8.104c0-8.063 6.52-14.583 14.583-14.583s14.583 6.52 14.583 14.583z"
      ></path>
      <path
        stroke="#F9BC4A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M59.27 53.48l-3.437.812c-.77.187-1.375.77-1.541 1.541l-.73 3.063c-.395 1.666-2.52 2.166-3.624.854L40 48.333l-9.938 11.438c-1.104 1.312-3.229.812-3.625-.854l-.729-3.063c-.187-.77-.791-1.375-1.541-1.541l-3.438-.813c-1.583-.375-2.146-2.354-1-3.5l8.125-8.125c2.25 3.333 5.813 5.688 9.959 6.292.708.125 1.437.187 2.187.187.75 0 1.48-.062 2.188-.187 4.145-.605 7.708-2.959 9.958-6.292L60.27 50c1.146 1.125.583 3.104-1 3.48zM41.208 27.458l1.23 2.459c.166.333.604.666 1 .729l2.229.375c1.416.229 1.75 1.27.729 2.291l-1.73 1.73c-.291.291-.458.854-.353 1.27l.5 2.146c.395 1.688-.5 2.355-2 1.459l-2.084-1.23c-.375-.229-1-.229-1.375 0l-2.083 1.23c-1.5.875-2.396.229-2-1.459l.5-2.145c.083-.396-.063-.98-.354-1.271l-1.73-1.73c-1.02-1.02-.687-2.041.73-2.291l2.229-.375c.375-.063.812-.396.979-.73l1.23-2.458c.603-1.333 1.687-1.333 2.353 0z"
      ></path>
    </svg>
  );
}

export default ToFAQ;
