import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
//ContextAPI
import { useFormHandlerContext } from "../../../contextAPI";
//Components
import { CustomTextField } from "../TextField";
//Types
import { InputProps } from "./input.types";

const Input: FC<InputProps> = (props) => {
  const {
    id,
    name,
    label,
    margin,
    fullWidth,
    multiline,
    placeholder,
    rows,
    type,
    endAdornment,
    rules,
    disabled,
    // autoComplete,
    required,
  } = props;
  const { control } = useFormContext();
  const dataForm = useFormHandlerContext();
  //render
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, value, ref, ...rest },
        fieldState: { error },
      }) => {
        return (
          <CustomTextField
            {...rest}
            inputRef={ref}
            size="small"
            required={required}
            id={id}
            fullWidth={typeof fullWidth === "boolean" ? fullWidth : true}
            label={label}
            disabled={disabled || dataForm?.loading}
            variant="outlined"
            style={{ margin }}
            multiline={multiline}
            placeholder={placeholder}
            minRows={rows}
            value={
              value == null || value === undefined
                ? ""
                : typeof value === "number"
                ? value.toString()
                : value
            }
            type={type}
            helperText={error?.message}
            error={error?.message !== undefined}
            InputProps={{ endAdornment }}
            onChange={onChange}
            autoComplete="off"
          />
        );
      }}
    />
  );
};
export { Input };
