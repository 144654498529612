function ArrowRightBotton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#F9BC4A"
        d="M15 2.5C8.113 2.5 2.5 8.113 2.5 15S8.113 27.5 15 27.5 27.5 21.887 27.5 15 21.887 2.5 15 2.5zm3.488 13.162l-4.413 4.413a.927.927 0 01-.662.275.927.927 0 01-.663-.275.943.943 0 010-1.325L16.5 15l-3.75-3.75a.943.943 0 010-1.325.943.943 0 011.325 0l4.413 4.412a.92.92 0 010 1.325z"
      ></path>
    </svg>
  );
}

export default ArrowRightBotton;
