import {Fragment, useContext, useEffect, useMemo, useState} from "react";
//Material UI
import { Badge, Box, IconButton, Menu, Typography } from "@mui/material";
import Link from "@mui/material/Link";
//Asstes
import { Svg } from "../../../assets";
//ContextAPI
import { useAuth } from "../../../contextAPI";
//Hooks
import { useWindowDimensions } from "../../../hooks";
//Components
import {Button, Select} from "../../Commons";
import { ModalConfirmation } from "../../Modals";
import CardNotification from "../../Cards/CardNotification";
//Styles
import { Container } from "./headerForm.styes";
//Types
import { HeaderFormProps, Notification } from "./headerForm.types";
import {Labeltext} from "../../../pages/Auth/SignUp/UserForm/userForm.styles";
import {gender} from "../../../helpers";
import SelectList from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {LanguageContext} from "../../../LanguageContext";

const HeaderForm = (props: HeaderFormProps) => {
  const auth = useAuth();
  const { buttonText, text, notificationsCount, data, buttonAction } = props;
  const { width } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Notification | null>(null);

  //memos
  const showText = useMemo(() => (width < 490 ? false : true), [width]);
  const ButtonWidth = useMemo(() => (width < 490 ? 120 : 190), [width]);

  const translations:any = {
    en: {
      langEN:"English",
      langFR:"French",
      lang:"Language",
    },
    fr: {
      langEN:"Anglais",
      langFR:"Français",
      lang:"Language",
    },
  };
  const { language, setLanguage } = useContext(LanguageContext);


  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  const handleLanguageChange = (e:any) => {
    setLanguage(e.target.value);
  };

  //functions
  const handleClickButton = () => {
    buttonAction();
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (data?.length === 0) return;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const handleClickNotification = (item: Notification) => {
    handleClose();
    setIsOpen(true);
    setSelectedItem(item);
  };

  //renders
  const renderNotificationItems = () => {
    return data?.map((notification) => {
      return (
        <Box
          key={notification.id}
          onClick={() => handleClickNotification(notification)}
        >
          <CardNotification
            title={notification.title}
            date_created={notification.date_created}
          />
        </Box>
      );
    });
  };

  const modalItemAccpeted = () => {
    return (
      <>
        <Typography
          fontFamily="Mont"
          fontSize={20}
          fontWeight={600}
          color="#000"
          textAlign="center"
        >
          {selectedItem?.title}
        </Typography>
        <Typography
          fontFamily="Mont"
          fontSize={16}
          fontWeight={400}
          textAlign="center"
          color="#000"
        >
          <div
            dangerouslySetInnerHTML={{
              __html: selectedItem?.notification || "",
            }}
          ></div>
        </Typography>
      </>
    );
  };
  return (
    <Container>
      <ModalConfirmation
        isVisible={isOpen}
        rightButtonText="Fermer"
        rightButtonAction={handleCloseModal}
        children={modalItemAccpeted()}
        onClickClose={handleCloseModal}
      />
      <Link href={`${process.env.REACT_APP_LANDING_PAGE}`}>
        <Svg.LogoSvg />
      </Link>
      <Box display="flex" alignItems="center" gap={1}>
        <SelectList
            className="select-lang"
            value={language}

            labelId={`select-language`}
            onChange={handleLanguageChange}
        >
          <MenuItem key={0} value={'en'}>
            {translations[language].langEN}
          </MenuItem>
          <MenuItem key={1} value={'fr'}>
            {translations[language].langFR}
          </MenuItem>
        </SelectList>

        {/*<select value={language} onChange={handleLanguageChange}>
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>*/}
        {auth.token && (
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Badge
              badgeContent={notificationsCount ? notificationsCount : 0}
              color="primary"
            >
              <Svg.Notification />
            </Badge>
          </IconButton>
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            {renderNotificationItems()}
          </Box>
        </Menu>
        {showText && text && (
          <Typography
            fontWeight={600}
            fontFamily="Mont"
            fontSize={16}
            color="#999999"
          >
            {text}
          </Typography>
        )}
        <Button
          variant="outlined"
          color="primary"
          boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
          width={ButtonWidth}
          onClick={handleClickButton}
        >
          {buttonText}
        </Button>
      </Box>
    </Container>
  );
};

export { HeaderForm };
