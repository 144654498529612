import axios from "axios";

const getFields = async (colletion: string, token: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_DIRECTUS_URL}/fields/${colletion}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export { getFields };
