import { Box, BoxProps, Typography } from "@mui/material";
import { styled } from "@mui/system";
//Types
import { TagProps } from "./tag.types";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  width: 30,
  height: 30,
  borderRadius: 15,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#F5EFF1",
}));

export const LabelTag = styled(Typography)<TagProps>(
  ({ theme, color, fontSize, fontWeight }) => ({
    margin: theme.spacing(0, 2),
    color: color,
    fontSize: fontSize,
    fontWeight: fontWeight,
  })
);
