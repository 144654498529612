import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const Message = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 18,
  fontWeight: 400,
  color: "#969899",
}));
