import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2, 4),
  backgroundColor: "transparent",
  position: "sticky",
  minHeight: "50px",
}));
