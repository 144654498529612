import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
//Types
import { ButtonProps } from "./button.types";

export const CustomButton = styled(LoadingButton)<ButtonProps>(
  ({
    theme,
    minwidth,
    width,
    margin,
    align,
    textdecorationline,
    height,
    fontSize,
    fontWeight,
    boxshadow,
    color,
  }) => ({
    textTransform: "none",
    width: width,
    minWidth: minwidth,
    alignSelf: align,
    textDecorationLine: textdecorationline,
    height: height,
    fontSize: fontSize,
    fontWeight: fontWeight,
    margin,
    borderRadius: "25px",
    boxShadow: "none",
    ":hover": {
      boxShadow: boxshadow,
      color: color,
    },
  })
);
