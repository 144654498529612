import {useContext, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import Validator from "validator";
//Material UI
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
//assets
import { Images, Svg } from "../../../assets";
//helpers
import { SnackbarHolder } from "../../../helpers";
//hooks
import { useWindowDimensions } from "../../../hooks";
//services
import { forgetPassword } from "../../../services";
//Components
import { Form, Image, Button, Input } from "../../../components/Commons";
//Styles
import {
  BottunsContainer,
  ButtonText,
  Container,
  FormContainer,
} from "./forgetPassword.styles";
//Types
import { ForgetPasswordState } from "./forgetPassword.types";
import {LanguageContext} from "../../../LanguageContext";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const { language } = useContext(LanguageContext);

  //states
  const methods = useForm<ForgetPasswordState>({
    defaultValues: {
      email: "",
    },
  });
  //queries & mutations
  const forget = useMutation(forgetPassword);
  //memos
  const fontSize = useMemo(() => (width < 490 ? 12 : 16), [width]);
  const boxWidth = useMemo(() => (width < 500 ? 280 : 400), [width]);
  //functions
  const handleBack = () => {
    navigate("/sign-in", { replace: true });
  };
  const onSubmit: SubmitHandler<ForgetPasswordState> = async (data) => {
    try {
      const res = await forget.mutateAsync({
        email: data.email,
        reset_url: process.env.REACT_APP_RESET_URL
      });
      if (res?.status === 204) {
        SnackbarHolder.alert("success", language === "fr" ? "Demande envoyée.":"Request sent.");
        navigate("/sign-in", { replace: true });
      }
    } catch (error: any) {
      SnackbarHolder.alert("error", language === "fr" ? "Une erreur s'est produite":"An error has occurred");
    }
  };

  //render
  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container>
          <FormContainer
            elevation={0}
            sx={{ maxHeight: height - 250, minHeight: height - 260 }}
          >
            {width < 500 ? (
              <></>
            ) : (
              <Image src={Images.ForgetPassword} width={100} />
            )}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={boxWidth}
              gap={2}
              mt={4}
            >
              <Typography
                fontFamily="Mont"
                fontSize={fontSize}
                fontWeight={600}
                color="#616161"
                textAlign="center"
              >
                {language === "fr" ?"Vous avez oublié votre mot de passe! Pas de soucis!":"Forgot your password! No worries!"}

              </Typography>
              <Input
                name="email"
                minWidth={360}
                label="Email"
                rules={{
                  required: language === "fr" ?"Ce champ est requis.":"This field is required.",
                  validate: (value: string) =>
                    Validator.isEmail(value) || (language === "fr" ?"Adresse e-mail invalide.":"Invalid email address."),
                }}
              />
            </Box>

            <BottunsContainer>
              <Button
                variant="text"
                startIcon={<Svg.ArrowLeft />}
                onClick={handleBack}
              >
                <ButtonText>{language === "fr" ?"Précédent":"Previous"}</ButtonText>
              </Button>
              <Button
                variant="contained"
                color="primary"
                boxshadow="0px 5px 5px rgba(239, 75, 18, 0.2)"
                width={190}
                onClick={methods.handleSubmit(onSubmit)}
              >
                {language === "fr" ?"Envoyer":"Send"}
              </Button>
            </BottunsContainer>
          </FormContainer>
        </Container>
      </Form>
    </FormProvider>
  );
};
export default ForgetPassword;
