import React, { FC, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useFormContext, useWatch } from "react-hook-form";
//Material UI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//ContextAPI
import { useFormHandlerContext } from "../../../contextAPI";
//Assets
import { Svg } from "../../../assets";
//components
import { Image } from "../Image";
import { ProgressBar } from "../ProgressBar";
//Styles
import {
  Container,
  ContainerLoading,
  FormHelper,
  Input,
  Text,
} from "./dropzone.styles";
//Types
import { DropzoneProps, FileProps } from "./dropzone.types";

const maxSize = 5242880;

const DropzoneAvatar: FC<DropzoneProps> = (props) => {
  const { name, rules, progress, height, width, onAddPicture } = props;
  const { control, setValue } = useFormContext();
  const dataForm = useFormHandlerContext();
  const dataValue = useWatch({ control, name });
  //style
  const style = useMemo(
    () => (dataValue ? { border: "none" } : {}),
    [dataValue]
  );
  //functions
  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    if (acceptedFiles.length > 0) {
      const newFile: FileProps = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
      setValue(name, newFile);
      if (onAddPicture) {
        onAddPicture(newFile);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //render
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: {
        "image/*": [".jpeg", ".png"],
      },
      disabled: dataForm?.loading,
      maxSize: maxSize,
    });
  const borderColor = useMemo(
    () => (dataForm?.loading ? "#ccc" : "#EC6D3F"),
    [dataForm?.loading]
  );

  const handleError = () => {
    if (fileRejections.length > 0) {
      return fileRejections.map(({ file, errors }) => {
        return errors.map((e) => (
          <Typography color="red" fontSize={10}>
            L'images doit être de type jpeg ou png et de taille de 5 Mb maximum
          </Typography>
        ));
      });
    }
  };
  const renderContent = (value: FileProps) => {
    if (typeof value === "string") {
      return (
        <Box position="relative" width="100%" height="100%">
          <Image
            src={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${value}?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`}
            height="100%"
            width="100%"
            objectFit="cover"
            borderRadius={50}
          />
        </Box>
      );
    } else if (value) {
      return (
        <Box position="relative" width="100%" height="100%">
          {typeof progress === "number" && (
            <ContainerLoading>
              <ProgressBar
                value={progress}
                color="secondary"
                textColor="#fff"
              />
            </ContainerLoading>
          )}
          <Image
            src={value.preview}
            height="100%"
            width="100%"
            objectFit="cover"
            borderRadius={50}
          />
        </Box>
      );
    }
    if (isDragActive) {
      return <Text>Drop the files here ...</Text>;
    }
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Svg.DorpZoneAvatar />
      </Box>
    );
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value }, fieldState: { error } }) => (
        <Box display="flex" flexDirection="column">
          <Container
            {...getRootProps()}
            bordercolor={borderColor}
            style={{
              ...style,
              height: height || 270,
              width,
            }}
          >
            <Input {...getInputProps()} />
            {renderContent(value)}
          </Container>
          {handleError()}
          <FormHelper error>{error?.message}</FormHelper>
        </Box>
      )}
    />
  );
};

export { DropzoneAvatar };
