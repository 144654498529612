import { FC } from "react";
//Components
import { Image } from "../Image";
import { Button } from "../Button";
//Styles
import { Container, Message } from "./empty.styles";
//Types
import { EmptyProps } from "./empty.types";

const Empty: FC<EmptyProps> = (props) => {
  const { image, sizeImage, message, buttonText, onClickButton } = props;
  return (
    <Container>
      <Image src={image} width={sizeImage} height={sizeImage} />
      <Message variant="body1">{message}</Message>
      {buttonText && <Button onClick={onClickButton}>{buttonText}</Button>}
    </Container>
  );
};
export { Empty };
