import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
//ContextAPI
import { AuthProvider, StepperProvider } from "./contextAPI";
//Helpers
import { queryClient, SnackbarHolder } from "./helpers";
//Theme
import Theme from "./theme";
//Components
import { Snackbar } from "./components/Commons";
//Routes
import AppRoute from "./routes";
import { LanguageProvider } from "./LanguageContext"; // Import the LanguageProvider

function App() {
  return (
      <LanguageProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ThemeProvider theme={Theme}>
                <AuthProvider>
                  <StepperProvider>
                    <Routes>
                      <Route path="/*" element={<AppRoute />} />
                    </Routes>
                  </StepperProvider>
                  <Snackbar ref={(ref) => SnackbarHolder.setSnackbar(ref)} />
                </AuthProvider>
              </ThemeProvider>
            </LocalizationProvider>
          </BrowserRouter>
          {process.env.REACT_APP_FUZE_ENV === "development" && (
              <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </LanguageProvider>

  );
}

export default App;
